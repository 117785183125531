/* eslint-disable react/prop-types */
import React from 'react';

import { Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import { Update } from '../../views/settings/inventory/brands/manage';
import Confirm from '../confirm';

import { ModalConsumer } from '../../context/modal';

import { allBrands } from '../../graphql/queries/allBrands.gql';
import { removeBrand as removeBrandMutation } from '../../graphql/mutations/removeBrand.gql';

const Brand = ({ id, name, toggleModal, removeBrand }) => {
  const onClickUpdate = async () => {
    toggleModal({ options: { title: 'Update Brand' }, props: { id, name } })(
      Update,
    );
  };

  const onClickRemove = async () => {
    const args = {
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allBrands,
        },
      ],
      update: (
        store,
        // {
        //   data: {
        //     removeBrand: {
        //       ok,
        //       errors
        //     }
        //   },
        // },
      ) => {
        const data = store.readQuery({ query: allBrands });
        const newData = {
          allBrands: data.allBrands.filter(
            ({ id: filterId }) => filterId !== id,
          ),
        };
        store.writeQuery({ query: allBrands, data: newData });
      },
    };
    // eslint-disable-next-line no-shadow
    const remove = (args) => {
      removeBrand(args);
      toggleModal();
    };
    toggleModal({
      options: { title: 'Confirm' },
      actions: { action: remove },
      props: { args, text: `remove brand: ${name}` },
    })(Confirm);
  };
  return (
    <tr key={id}>
      <td className="border px-4 py-2">{id}</td>
      <td className="border px-4 py-2">{name}</td>
      <td className="border px-4 py-2">
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-gray-100 focus:outline-none border mr-2"
          onClick={onClickUpdate}
          size="sm"
        >
          Update
        </button>
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
          onClick={onClickRemove}
          size="sm"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default graphql(removeBrandMutation, { name: 'removeBrand' })(
  ModalConsumer(Brand),
);
