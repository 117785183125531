import React from 'react';

import Material from '../../../components/material';
import EmptyState from '../../../atoms/EmptyState';
import Loading from '../../../components/loading';

const Materials = ({ data: { loading, allMaterials = { nodes: [], totalCount: 0 } } }) => {
  if (allMaterials.nodes.length > 0) {
    return allMaterials.nodes.map(values => (
      <Material key={values.id} {...values} />
    ));
  }
  if (!loading) {
    return (
      <EmptyState
        title="No Materials Found"
        description="Start by adding one below."
      />
    );
  }
  return <Loading />;
};

export default Materials;
