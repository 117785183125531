import React from 'react';
import styled, { keyframes } from 'styled-components';

const bouncedelay = keyframes`
0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 3em 1em;
  div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`;

const Loading = () => (
  <LoadingContainer>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </LoadingContainer>
);

export default Loading;
