import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from 'axios';
import authMiddleAndAfterWare from './authMiddleAndAfterware';

const { REACT_APP_PROTOCOL, REACT_APP_SERVER_ADDRESS, REACT_APP_SERVER_PORT } = process.env;
const uri = (REACT_APP_SERVER_ADDRESS || REACT_APP_SERVER_PORT) ? `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_ADDRESS}:${REACT_APP_SERVER_PORT}` : null;

const cache = new InMemoryCache();

export default new ApolloClient({
  link: ApolloLink.from([
    authMiddleAndAfterWare,
    // eslint-disable-next-line new-cap
    new createUploadLink({
      uri: uri || 'https://gqls.materialtrackr.com',
      // credentials: 'include'
      fetch: buildAxiosFetch(axios, (config, input, init) => ({
        ...config,
        onUploadProgress: init.onUploadProgress,
      })),
    }),
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});
