import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import LogRocket from 'logrocket';


import Routes from './routes';
import './fonts/fonts.css';
import './styles/output.css';

LogRocket.init('material-tracker/material-tracker');

const App = (
  <Routes />
);

ReactDOM.render(App, document.getElementById('root'));
