import React from 'react';

import { Formik } from 'formik';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import Select from 'react-select';

import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { allUsers } from '../../../../../graphql/queries/allUsers.gql';
import { addUser as addUserMutation } from '../../../../../graphql/mutations/addUser.gql';
import { allRoles as allRolesQuery } from '../../../../../graphql/queries/allRoles.gql';

const AddUser = ({
  actions: { toggleModal },
  data: { allRoles = [] },
  addUser,
}) => {
  const validate = ({ name, username, email, password, roleId }) => {
    const errors = {};
    if (!name) {
      errors.name = 'Required';
    }
    if (!username) {
      errors.username = 'Required';
    }
    if (!email) {
      errors.email = 'Required';
    }
    // eslint-disable-next-line no-use-before-define
    if (!emailRegExp.test(email)) {
      errors.email = 'Email is invalid';
    }
    // if (!password) {
    //   errors.password = 'Required';
    // }
    if (!roleId) {
      errors.roleId = 'Required';
    }
    return errors;
  };
  const onSubmit = async (
    { name, username, email, password, roleId },
    { setSubmitting, setErrors },
  ) => {
    await addUser({
      variables: {
        name,
        username,
        email,
        password,
        roleId,
      },
      refetchQueries: [
        {
          query: allUsers,
        },
      ],
      update: (
        store,
        {
          data: {
            createUser: { ok, errors, payload },
          },
        },
      ) => {
        if (ok) {
          const data = store.readQuery({ query: addUserMutation });
          data.allUsers.push(payload);

          toggleModal();
        } else {
          const e = {};
          // eslint-disable-next-line array-callback-return
          errors.map(({ path, message }) => {
            e[path] = message;
          });
          setErrors(e);
        }
      },
    });
    setSubmitting(false);
  };
  const initialValues = {
    name: '',
    username: '',
    email: '',
    password: '',
    roleId: 0,
  };
  const rolesSelect = allRoles.map(({ id: value, name: label }) => ({
    value,
    label,
  }));
  return (
    <Formik
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        values: { name, username, email, password },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-section">
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-900 leading-tight"
              >
                Name
              </label>
              <input
                className={
                  errors.name && touched.name
                    ? 'is-invalid mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                    : 'mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                }
                type="text"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                name="name"
              />
              {touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-900 leading-tight"
              >
                Username
              </label>
              <input
                className={
                  errors.username && touched.name
                    ? 'is-invalid mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                    : 'mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                }
                type="text"
                id="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={username}
                name="username"
              />
              {touched.username && (
                <div className="invalid-feedback">{errors.username}</div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-900 leading-tight"
              >
                Email
              </label>
              <input
                className={
                  errors.email && touched.email
                    ? 'is-invalid mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                    : 'mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400'
                }
                type="email"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={email}
                name="email"
              />
              {touched.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            {/* <FormGroup>
              <Label for="password">Password</Label>
              <Input
                className={
                  errors.password && touched.password ? 'is-invalid' : ''
                }
                type="password"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={password}
                name="password"
              />
              <div className="invalid-feedback">{errors.password}</div>
            </FormGroup> */}
            <div className="mb-4">
              <label
                htmlFor="role"
                className="block text-gray-900 leading-tight"
              >
                Role
              </label>
              <Select
                className={errors.roleId && touched.roleId ? 'is-invalid' : ''}
                options={rolesSelect}
                type="text"
                id="role"
                onChange={({ value }) => setFieldValue('roleId', value)}
                onBlur={() => setFieldTouched('roleId', true)}
                name="roleId"
              />
              {touched.roleId && (
                <div className="invalid-feedback">{errors.roleId}</div>
              )}
            </div>
          </div>
          <span className="block text-gray-700 text-base text-center py-4">
            A generated password will be emailed to the new user.
          </span>
          <button
            className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none w-full block"
            type="submit"
            disabled={isSubmitting}
          >
            Add User
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default compose(
  graphql(addUserMutation, { name: 'addUser' }),
  graphql(allRolesQuery),
)(AddUser);

// eslint-disable-next-line no-control-regex
const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
