import React from 'react';
import { graphql } from 'react-apollo';

import { Button } from 'reactstrap';
import { ModalConsumer } from '../../context/modal';

import { Update } from '../../views/settings/inventory/types/manage';
import { allTypes } from '../../graphql/queries/allTypes.gql';
import { removeType as removeTypeMutation } from '../../graphql/mutations/removeType.gql';

const Type = ({ id, name, toggleModal, removeType }) => {
  const onUpdateClick = async () => {
    toggleModal({ options: { title: 'Update Type' }, props: { id, name } })(
      Update,
    );
  };
  const onRemoveClick = async () => {
    removeType({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allTypes,
        },
      ],
      update: (
        store,
        {
          data: {
            removeType: { ok },
          },
        },
      ) => {
        if (!ok) return false;
        try {
          const data = store.readQuery({ query: allTypes });
          const newData = {
            allTypes: data.allTypes.filter(({ id: typeId }) => typeId !== id),
          };
          store.writeQuery({ query: allTypes, data: newData });
          return {
            data: newData,
          };
        } catch (e) {
          console.log(e, 'eee');
          return false;
        }
      },
    });
  };
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>
        <Button color="primary" onClick={onUpdateClick} size="sm">
          Update
        </Button>
      </td>
      <td>
        <Button color="danger" onClick={onRemoveClick} size="sm">
          Delete
        </Button>
      </td>
    </tr>
  );
};
export default graphql(removeTypeMutation, { name: 'removeType' })(
  ModalConsumer(Type),
);
