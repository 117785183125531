/* eslint-disable no-shadow */
import React from 'react';

import { Formik } from 'formik';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import Select from 'react-select';
import Alert from '../../../../../atoms/Alert';

import styled from 'styled-components';

import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { updateUser as updateUserMutation } from '../../../../../graphql/mutations/updateUser.gql';
import { getUser } from '../../../../../graphql/queries/getUser.gql';
import { allRoles as allRolesQuery } from '../../../../../graphql/queries/allRoles.gql';

const SectionLabel = styled.h3`
  font-size: 1em;
  font-weight: bold;
  border-top: 1px solid #f1f1f1;
  padding-top: 0.5em;
  border-bottom: none;
`;

const validate = ({
  name,
  username,
  email,
  oldPassword,
  newPassword,
  confirmPassword,
  roleId,
}) => {
  const errors = {};
  if (!name) {
    errors.name = 'Required';
  }
  if (!username) {
    errors.username = 'Required';
  }
  if (!email) {
    errors.email = 'Required';
  }
  // eslint-disable-next-line no-use-before-define
  if (!emailRegExp.test(email)) {
    errors.email = 'Email is invalid';
  }

  if (oldPassword) {
    if (!newPassword) {
      errors.newPassword = 'Required';
    }
    if (newPassword.length < 8) {
      errors.newPassword = 'New password must at least be 8 characters';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Required';
    }
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    if (newPassword && !confirmPassword) {
      errors.confirmPassword = 'Required';
    }
  }

  if (!roleId) {
    errors.roleId = 'Required';
  }

  return errors;
};

const UpdateUser = ({
  id,
  name,
  username,
  email,
  role,
  updateUser,
  data: { allRoles = [] },
  closeModal,
  actions: { toggleModal },
}) => {
  const onSubmit = async (
    {
      name: newName,
      username: newUsername,
      email: newEmail,
      roleId,
      oldPassword,
      newPassword,
    },
    { setSubmitting, setErrors },
  ) => {
    const variables = {
      id,
      name: newName,
      username: newUsername,
      email: newEmail,
      roleId,
      oldPassword,
      newPassword,
    };

    const {
      data: { updateUser: { ok, errors } = {} },
    } = await updateUser({
      variables,
      refetchQueries: [
        {
          query: getUser,
          variables: {
            id,
          },
        },
      ],
    });
    if (ok) {
      if (typeof toggleModal == 'function') {
        toggleModal();
      }
      if (typeof closeModal == 'function') {
        closeModal();
      }
    } else {
      const e = {};
      // eslint-disable-next-line no-return-assign
      errors.forEach(({ path, message }) => (e[path] = message));
      setErrors(e);
    }

    setSubmitting(false);
  };
  const initialValues = {
    name,
    username,
    email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    roleId: role.id,
  };
  const rolesSelect = allRoles.map(({ id: value, name: label }) => ({
    value,
    label,
  }));
  return (
    <Formik
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        values: {
          name,
          username,
          email,
          oldPassword,
          newPassword,
          confirmPassword,
          roleId,
        },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-section">
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                className={errors.name && touched.name ? 'is-invalid' : ''}
                type="text"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                name="name"
              />
              <div className="invalid-feedback">{errors.name}</div>
            </FormGroup>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                className={errors.username && touched.name ? 'is-invalid' : ''}
                type="text"
                id="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={username}
                name="username"
              />
              <div className="invalid-feedback">{errors.username}</div>
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                className={errors.email && touched.email ? 'is-invalid' : ''}
                type="email"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={email}
                name="email"
              />
              <div className="invalid-feedback">{errors.email}</div>
            </FormGroup>
            <FormGroup>
              <Label for="role">Role</Label>
              <Select
                className={errors.roleId && touched.roleId ? 'is-invalid' : ''}
                options={rolesSelect}
                type="text"
                id="role"
                onChange={({ value }) => setFieldValue('roleId', value)}
                onBlur={() => setFieldTouched('roleId', true)}
                name="roleId"
                defaultValue={{ value: roleId, label: role.name }}
                menuShouldScrollIntoView
              />
              <div className="invalid-feedback">{errors.roleId}</div>
            </FormGroup>
            <SectionLabel>Change Password</SectionLabel>
            <FormGroup>
              <Label for="old_password">Old Password</Label>
              <Input
                className={
                  errors.oldPassword && touched.oldPassword ? 'is-invalid' : ''
                }
                type="password"
                id="old_password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={oldPassword}
                name="oldPassword"
              />

              <div className="invalid-feedback">{errors.oldPassword}</div>
            </FormGroup>
            <FormGroup>
              <Label for="new_password">New Password</Label>
              <Input
                className={
                  errors.newPassword && touched.newPassword ? 'is-invalid' : ''
                }
                type="password"
                id="new_password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={newPassword}
                name="newPassword"
              />
              {console.log('newPassword', errors.newPassword)}
              {console.log('touched.newPassword', touched.newPassword)}
              <div className="invalid-feedback">
                {errors.newPassword && touched.newPassword ? (
                  <Alert
                    type="error"
                    isOpen={errors.newPassword && touched.newPassword}
                  >
                    {errors.newPassword &&
                      touched.newPassword &&
                      errors.newPassword}
                  </Alert>
                ) : (
                  ''
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="confirm_password">Confirm Password</Label>
              <Input
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? 'is-invalid'
                    : ''
                }
                type="password"
                id="confirm_password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={confirmPassword}
                name="confirmPassword"
              />
              <div className="invalid-feedback">{errors.confirmPassword}</div>
            </FormGroup>
          </div>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default compose(
  graphql(updateUserMutation, { name: 'updateUser' }),
  graphql(allRolesQuery),
)(UpdateUser);

// eslint-disable-next-line no-control-regex
const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
