import React from 'react';
import { Button } from 'reactstrap';

import { graphql } from 'react-apollo';
import { Update } from '../../views/settings/inventory/status/manage';
import { ModalConsumer } from '../../context/modal';

import { allStatuses } from '../../graphql/queries/allStatuses.gql';
import { removeStatus as removeStatusMutation } from '../../graphql/mutations/removeStatus.gql';

const Status = ({
  id, name, toggleModal, removeStatus,
}) => {
  const onUpdateClick = async () => {
    toggleModal({ options: { title: 'Update Type' }, props: { id, name } })(
      Update,
    );
  };
  const onRemoveClick = async () => {
    removeStatus({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allStatuses,
        },
      ],
      update: (
        store,
        {
          data: {
            removeStatus: { ok },
          },
        },
      ) => {
        if (!ok) return false;
        try {
          const data = store.readQuery({ query: allStatuses });
          const newData = {
            allStatuses: data.allStatuses.filter(
              ({ id: typeId }) => typeId !== id,
            ),
          };
          store.writeQuery({ query: allStatuses, data: newData });
          return newData;
        } catch (e) {
          return false;
        }
      },
    });
  };
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>
        <Button color="primary" onClick={onUpdateClick} size="sm">
          Update
        </Button>
      </td>
      <td>
        <Button color="danger" onClick={onRemoveClick} size="sm">
          Remove
        </Button>
      </td>
    </tr>
  );
};

export default graphql(removeStatusMutation, { name: 'removeStatus' })(
  ModalConsumer(Status),
);
