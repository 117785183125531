/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import { Button } from 'reactstrap';
import { toggleUserLock as toggleUserLockMutation } from '../../../../../graphql/mutations/toggleUserLock.gql';
import { allUsers } from '../../../../../graphql/queries/allUsers.gql';

import Alert from '../../../../../atoms/Alert';

const ToggleUserLockComponent = (props) => {
  const {
    id,
    locked,
    name,
    closeModal,
    toggleUserLock,
    actions: { toggleModal },
  } = props;
  const [errors, setErrors] = useState([]);
  const handleUserLock = async () => {
    const {
      data: {
        toggleUserLock: { ok, errors },
      },
    } = await toggleUserLock({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: allUsers,
        },
      ],
    });
    if (ok) {
      toggleModal();
    } else {
      if (errors && errors.length > 0) {
        setErrors(errors);
        console.log('errors', errors);
      } else {
        setErrors([
          {
            path: 'toggleUserLock',
            message: 'An unknown error occurred please try again.',
          },
        ]);
        setTimeout(() => setErrors([]), 5000);
      }
    }
  };
  const lockStatus = locked ? 'unlock' : 'lock';
  return (
    <>
      {errors && errors.length > 0 && (
        <Alert type={'error'}>
          {errors.map(({ path, message }) => message)}
        </Alert>
      )}
      <Alert
        type={'warning'}
      >{`Are you sure you want to ${lockStatus} ${name}'s account?`}</Alert>
      <Button color="danger" onClick={handleUserLock}>
        {lockStatus.charAt(0).toUpperCase() + lockStatus.slice(1)}
      </Button>
      <Button outline color="secondary" onClick={closeModal}>
        Cancel
      </Button>
    </>
  );
};

export default graphql(toggleUserLockMutation, { name: 'toggleUserLock' })(
  ToggleUserLockComponent,
);
