import React from 'react';
import { graphql } from 'react-apollo';
import { Card } from '../../../../atoms/Card';
import { Add as AddUser } from './manage';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import User from '../../../../components/settings/user';
import { ModalConsumer } from '../../../../context/modal';
import Protected from '../../../../components/protected';

import { allUsers as allUsersQuery } from '../../../../graphql/queries/allUsers.gql';

const ManageUsers = ({ toggleModal, data: { allUsers = [] } }) => {
  const addUser = async () => {
    toggleModal({ options: { title: 'Add User' }, props: this })(AddUser);
  };
  return (
    <Card>
      <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Manage Users
        </h2>
        <div>
          <Protected requiredAction={2}>
            <button
              type="button"
              onClick={addUser}
              className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm"
            >
              Add User
            </button>
          </Protected>
        </div>
      </div>
      <table className="table-auto w-full text-left  py-2 text-sm md:text-base text-left">
        <thead>
          <tr>
            <th className="py-2">Name</th>
            <th className="py-2">Email</th>
            <th className="py-2">Role</th>
            <th className="py-2">Lock Status</th>
            <th className="py-2"></th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((userDetails) => (
            <User key={userDetails.id} {...userDetails} />
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allUsersQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(ManageUsers)),
    'Manage Users',
  ),
);
