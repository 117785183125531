import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

const PosedImage = posed.img({
  init: {
    position: 'static',
    width: 'auto',
    height: 'auto',
    flip: true,
  },
});

const Image = styled(PosedImage)`
  cursor: 'zoom-in';
  display: block;
  max-width: 100%;
  margin: auto;
  z-index: 5;
  border-radius: 0.15em;
  box-shadow: 1px 2px 4px 1px hsla(0, 0%, 0%, 0.1);
`;

class ZoomImg extends React.Component {
  state = { isOpened: false };

  render() {
    const { isOpened } = this.state;
    const { imageWidth, imageHeight, ...props } = this.props;
    const poseState = 'init';

    const toggleState = () => this.setState({ isOpened: !isOpened });
    return (
      <div style={{ width: imageWidth, height: imageHeight }}>
        <Image pose={poseState} {...props} onClick={toggleState} />
        {isOpened && (
          <Lightbox mainSrc={props.largerSrc} onCloseRequest={toggleState} />
        )}
      </div>
    );
  }
}

export default ZoomImg;
