import React from 'react';
import { graphql } from 'react-apollo';
import {
  Form, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Formik } from 'formik';
import { allTypes } from '../../../../../graphql/queries/allTypes.gql';
import { addType as addTypeMutation } from '../../../../../graphql/mutations/addType.gql';

const AddTypes = ({ actions: { toggleModal }, addType }) => {
  const onSubmit = async ({ name }, { setSubmitting, setErrors }) => {
    await addType({
      variables: { name },
      refreshQueries: [
        {
          query: allTypes,
        },
      ],
      update: (
        store,
        {
          data: {
            createType: { ok, errors, payload },
          },
        },
      ) => {
        if (ok) {
          const data = store.readQuery({ query: allTypes });
          data.allTypes.push(payload);
          store.writeQuery({ query: allTypes, data });

          toggleModal();
        } else {
          const err = {};
          errors.forEach(({ path, message }) => {
            console.group();
            console.log('path', path);
            console.log('message', message);
            console.groupEnd();
            err[`${path}Error`] = message;
          });
          setErrors(err);
        }
      },
    });
    setSubmitting(false);
  };
  const initialValues = {
    name: '',
  };
  const validate = ({ name }) => {
    const errors = {};
    if (!name) {
      errors.name = 'Required';
    }
    return errors;
  };
  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        values: { name },
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {/* <Alert color="danger" isOpen={errors.length > 0}>
                        { errors.map((error) => error) }
                    </Alert> */}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                id="name"
                name="name"
              />
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              value="Create"
              disabled={isSubmitting}
              block
            >
              Add
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default graphql(addTypeMutation, { name: 'addType' })(AddTypes);
