import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const SnackBar = ({ children, className, ...rest }) => (
  <div
    {...rest}
    className={[
      'fixed mx-auto bg-white rounded-full bottom-0 mb-20 py-3 px-6 shadow-lg',
      className,
    ].join(' ')}
    style={{ left: 'calc(50% - 8rem)' }}
  >
    {children}
  </div>
);
