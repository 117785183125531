import React from 'react';
import styled from 'styled-components';

import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import MainLayout from '../../layout/main';

const Heading = styled.h1`
    font-size: 5em;
    font-weight:800;
`;
const SubHeading = styled.p`
    font-size: 2em;
    color:#777;
`;

const Forbidden = () => (
  <Container>
    <Row>
      <Col>
        <Heading>Access Denied</Heading>
        <SubHeading>Insufficient Permissions.</SubHeading>
        <p>Please contact your Administrator to elevate your permissions.</p>
        <Link to="/">Go back to the home page.</Link>
      </Col>
    </Row>
  </Container>
);

export default MainLayout(Forbidden);
