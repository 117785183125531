import React from 'react';

import { Lock, Unlock } from 'react-feather';
import { Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import Confirm from '../../../components/confirm'
import {
  Update,
  ToggleUserLock,
} from '../../../views/settings/general/users/manage';
import { getToken } from '../../../auth';

import UserConsumer from '../../../context/token';
import { ModalConsumer } from '../../../context/modal';

import { removeUser } from '../../../graphql/mutations/removeUser.gql';
import { allUsers } from '../../../graphql/queries/allUsers.gql';

const User = ({
  id,
  name,
  username,
  email,
  role,
  locked,
  user,
  toggleModal,
  removeUser
}) => {
  const { id: authenticatedUserId } = getToken();

  const firstName = name.split(/\s/)[0];
  let lastName = name.split(/\s/);
  lastName = lastName[lastName.length - 1];

  const onClickUpdate = async () => {
    toggleModal({
      options: { title: 'Update User' },
      props: {
        id,
        name,
        username,
        email,
        role,
        locked,
      },
    })(Update);
  };
  const removeUserWrapper = () => {
    const args = {
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allUsers,
        },
      ]
    };
    removeUser(args);
    toggleModal();
  } 

  const onClickDelete = async () => {
    toggleModal({
      options: { title: `Are you sure you want to delete ${name}'s account?` },
      actions: {
        action: removeUserWrapper
      },
      props: {
        text: `remove user ${name}`
      }
    })(Confirm);
  };
  const onClickToggleUserLock = async () => {
    toggleModal({
      options: { title: locked ? 'Unlock' : 'Lock' },
      props: { id, locked, name },
    })(ToggleUserLock);
  };
  return (
    <tr key={id}>
      <td className="border px-4 py-2">
        <span>
          {firstName} {lastName} {user.id === id ? '(Me)' : ''}
        </span>
        <span className="text-gray-700 text-sm block">{username}</span>
      </td>
      <td className="border px-4 py-2">{email}</td>
      <td className="border px-4 py-2">{role.name}</td>

      <td className="border px-4 py-2">
        {locked ? (
          <span className="text-red-600 mr-2 text-sm font-semibold">
            Account Locked
          </span>
        ) : null}

        {id !== user.id && (
          <button
            type="button"
            className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
            onClick={onClickToggleUserLock}
            size="sm"
          >
            {!locked ? <>Lock Account</> : 'Unlock Account'}
          </button>
        )}
      </td>
      <td className="border px-4 py-2">
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-gray-100 focus:outline-none border mr-2"
          onClick={onClickUpdate}
          size="sm"
        >
          Update
        </button>
        {authenticatedUserId === id ? null : (
          <button
            type="button"
            className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
            onClick={onClickDelete}
            size="sm"
          >
            Delete
          </button>
        )}
      </td>
    </tr>
  );
};

export default graphql(removeUser, {
  name: 'removeUser',
  options: { notifyOnNetworkStatusChange: true },
})(ModalConsumer(UserConsumer(User)));
