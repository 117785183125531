import React from 'react';

import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import { search } from '../../graphql/queries/search.gql';
import Material from '../../components/material';
import MainLayout from '../../layout/main';

const ResultType = styled.div`
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em;
`;

const Search = (props) => {
  const {
    location,
    data: { search: { materials = [], reservations = [] } = {} },
  } = props;
  const query = new URLSearchParams(location.search).get('query');
  console.log(
    'the same',
    query === new URLSearchParams(location.search).get('query'),
  );
  return (
    <Container>
      {query && materials.length > 0 && <ResultType>Materials</ResultType>}
      {materials.map(material => <Material {...material} />) || (
        <p>No materials found.</p>
      )}
      {query && reservations.length > 0 && (
        <ResultType>Reservations</ResultType>
      )}
      {reservations.map(reservation => (
        <Material {...reservation.material} />
      )) || <p>No reservations found.</p>}
      {!query && <p>Enter a search query</p>}
      {query && materials.length === 0 && reservations.length === 0 && (
        <p>No results.</p>
      )}
    </Container>
  );
};

export default MainLayout(
  withRouter(
    graphql(search, {
      options: ({ location }) => {
        let query = new URLSearchParams(location.search).get('query');
        if (!query) query = '';
        return {
          variables: {
            query,
          },
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true,
        };
      },
      alias: 'search',
    })(Search),
  ),
);
