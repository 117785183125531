/* eslint-disable no-shadow */
import React from 'react';

import { Formik } from 'formik';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import Select from 'react-select';

import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { updateRole } from '../../../../../graphql/queries/updateRole.gql';
import { allRoles } from '../../../../../graphql/queries/allRoles.gql';
import { updateRole as updateRoleMutation } from '../../../../../graphql/mutations/updateRole.gql';

import { filterArrayForSelectedValues } from '../../../../../sharedFunctions';

const Update = ({
  closeModal,
  data: {
    allActions = [],
    getRole: { id, name, actions } = { id: '', name: '', actions: [] },
  },
  updateRole,
}) => {
  const validate = ({ name, actions }) => {
    const errors = {};
    if (!name) {
      errors.name = 'Required';
    }
    if (actions.length < 1) {
      errors.actions = 'Required';
    }
    return errors;
  };
  const onSubmit = ({ name, actions }, { setSubmitting, setErrors }) => {
    updateRole({
      variables: {
        id,
        name,
        actions,
      },
      refetchQueries: [
        {
          query: allRoles,
        },
      ],
      update: (
        store,
        {
          data: {
            updateRole: { ok, errors, payload },
          },
        },
      ) => {
        if (ok) {
          // const data = store.readQuery({ query: allRoles });
          // let role = data.allRoles.filter(
          //   ({ id: idInList }) => idInList === id,
          // );
          // role = { ...role, payload };
          // const newData = {
          //   allRoles: {
          //     ...data,
          //     ...role,
          //   },
          // };
          // store.writeQuery({ query: allRoles }, newData);
          closeModal();
        } else {
          const e = {};
          // eslint-disable-next-line no-return-assign
          errors.forEach(({ path, message }) => (e[path] = message));
          setErrors(e);
        }
      },
    });
    setSubmitting(false);
  };

  const currentActions = actions;

  const initialValues = {
    name,
    checkedActions: actions.map((x) => x),
    actions: {
      add: [],
      remove: [],
    },
  };

  return (
    <Formik
      enableReinitialize
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        values: { name, checkedActions, actions },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-section">
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                className={errors.name && touched.name ? 'is-invalid' : ''}
                type="text"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                name="name"
              />
              <div className="invalid-feedback">{errors.name}</div>
            </FormGroup>
            <FormGroup>
              <Label for="role">Actions</Label>
              {allActions.map((action) => (
                <FormGroup
                  className="custom-control custom-checkbox"
                  key={action.id}
                  check
                >
                  <Input
                    id={`action_${action.id}`}
                    type="checkbox"
                    name="actions"
                    className="custom-control-input"
                    onChange={({ target: { checked } }) => {
                      const selectedValues = filterArrayForSelectedValues(
                        checkedActions,
                        action,
                        checked,
                      );
                      const newActions = {
                        ...actions,
                      };
                      const buildActions = () => {
                        const isInList = currentActions
                          .slice(0)
                          .filter(({ id }) => id === action.id);
                        // previous values
                        if (isInList.length > 0) {
                          // unchecked push item to list
                          if (!checked) {
                            newActions.remove.push(action.id);
                            // else remove it from the list
                          } else {
                            newActions.remove = newActions.remove.filter(
                              (id) => id !== action.id,
                            );
                          }
                        } else {
                          // eslint-disable-next-line no-lonely-if
                          if (checked) {
                            newActions.add.push(action.id);
                          } else {
                            newActions.add = newActions.add.filter(
                              (id) => id !== action.id,
                            );
                          }
                        }
                        return newActions;
                      };
                      setFieldValue('actions', buildActions());
                      setFieldValue('checkedActions', selectedValues);
                    }}
                    onBlur={handleBlur}
                    checked={
                      checkedActions.filter(
                        ({ id: hayStack }) => hayStack === action.id,
                      ).length > 0
                    }
                  />
                  <Label
                    className="custom-control-label"
                    for={`action_${action.id}`}
                  >
                    {action.name}
                  </Label>
                </FormGroup>
              ))}
              {/* <Select
                className={
                  errors.actions && touched.actions ? 'is-invalid' : ''
                }
                value={actions}
                options={actionsSelect}
                type="text"
                id="actions"
                onChange={(values) => {
                  setFieldValue('actions', values);
                }}
                onBlur={() => setFieldTouched('actions', true)}
                name="actions"
                isMulti
                closeMenuOnSelect={false}
              /> */}
              <div className="invalid-feedback">{errors.actions}</div>
            </FormGroup>
          </div>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default compose(
  graphql(updateRoleMutation, { name: 'updateRole' }),
  graphql(updateRole, {
    options: ({ id }) => ({
      variables: { roleId: id },
      fetchPolicy: 'network-only',
    }),
  }),
)(Update);
