import React from 'react';
import { Button } from 'reactstrap';

export default ({
  actionWrapper,
  actions,
  action,
  args,
  text,
  buttonLabel,
  closeModal,
}) => (
  <>
    <p>{`Are you sure you want to ${text}?`}</p>
    <Button
      color="danger"
      onClick={
        actionWrapper
          ? () => actionWrapper(action || actions.action)
          : () => (action ? action(args) : actions.action(args))
      }
    >
      {buttonLabel || 'Delete'}
    </Button>
    <Button outline color="secondary" onClick={closeModal}>
      Cancel
    </Button>
  </>
);
