import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { Card } from '../../atoms/Card';
import Update from '../../views/settings/general/roles/manage/update';
import Remove from '../../views/settings/general/roles/manage/remove';

export default ({ id, name, actions, toggleModal }) => {
  const updateBtnHandler = () => {
    toggleModal({
      options: { title: `Editing Role: ${name}` },
      props: { id, name, actions },
    })(Update);
  };
  const removeBtnHandler = () => {
    toggleModal({ options: { title: 'Confirm' }, props: { id, name } })(Remove);
  };
  const CheckIcon = () => (
    <svg
      className="h-5 w-5 block"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#ebfaee"></circle>
        <polyline
          stroke="#30B34E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          points="6 10 8.667 12.667 14 7.333"
        ></polyline>
      </g>
    </svg>
  );
  return (
    <div className="border p-4 px-6 rounded-lg w-full md:w-1/2 my-2">
      <div className="flex justify-between items-center pb-2">
        <h2 className="font-semibold text-xl md:text-xl text-center">{name}</h2>
        <div>
          <button
            type="button"
            onClick={updateBtnHandler}
            className="mr-1 rounded px-3 py-2 hover:text-gray-700 text-gray-800  focus:outline-none text-sm"
          >
            Update
          </button>

          <button
            type="button"
            onClick={removeBtnHandler}
            className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
          >
            Delete
          </button>
        </div>
      </div>
      <span className="mb-1 block uppercase text-gray-600 font-semibold tracking-wider text-xs">
        Role Actions
      </span>
      <ul>
        {actions.map(({ id: actionId, name: actionName }) => (
          <li className="py-1 flex items-center text-gray-800" key={actionId}>
            <span className="mr-2">
              <CheckIcon />
            </span>
            {actionName}
          </li>
        ))}
      </ul>
    </div>
  );
};
