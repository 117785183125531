/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { X } from 'react-feather';
import {
  sortOptions,
  buildParams,
  MIN_WIDTH,
  MIN_HEIGHT,
  THICKNESS_ID,
  BRAND_ID,
  COLOR_ID,
  STATUS_ID,
} from '../consts';
import useAdvancedFilterValues from '../../../hooks/useAdvancedFilterValues';

const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FilterOption = styled.span.attrs({
  className:
    'bg-white rounded-full px-4 py-2 text-base text-gray-800 mr-2 items-center flex justify-center shadow-soft',
})`
  ${({ selectList }) => {
    if (selectList) {
      return `
      display: inline-block!important;
      width: 100%;
      border-radius:0!important;
      margin-right: 0!important;
      background:transparent!important;
      padding:0!important;
      box-shadow:none!important;
      margin-bottom: 1em!important;
      .css-1pcexqc-container{
        max-width: 260px;
        margin-left: auto;
      }    
    `;
    }
    return null;
  }}
`;

export default (props) => {
  const { filters, location, data } = props;
  const {
    filters: {
      minWidth = '',
      minHeight = '',
      thicknessId = [],
      brandId = [],
      colorId = [],
      statusId = [],
    },
    sortOption = [],
  } = useAdvancedFilterValues({ location, data });
  const { history } = props;

  const removeFilterAndRedirect = (value, key, needle) => {
    if (typeof value === 'string' || typeof value === 'number') {
      delete filters[key];
    } else {
      filters[key] = value.filter(({ value: hayStack }) => needle !== hayStack);
      filters.colorId = filters.colorId.filter(
        ({ brandId }) => brandId !== needle,
      );
    }
    history.push({
      pathname: props.location.pathname,
      search: buildParams({
        url: props.match.url,
        values: filters,
        sort: sortOption,
      }),
    });
  };

  const sortChangeHandler = (newSortValue) => {
    history.push({
      pathname: props.location.pathname,
      search: buildParams({
        url: props.match.url,
        values: filters,
        sort: newSortValue,
      }),
    });
  };

  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="w-6 ml-2 icon-close-circle cursor-pointer"
    >
      <circle cx="12" cy="12" r="10" fill="#eeeeee"></circle>
      <path
        class="secondary"
        d="M13.41 12l2.83 2.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 1 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12z"
      ></path>
    </svg>
  );
  return (
    <FilterOptions>
      {minWidth && (
        <FilterOption>
          <span className="flex items-center">
            Min. Width {minWidth}
            <span onClick={() => removeFilterAndRedirect(minWidth, MIN_WIDTH)}>
              {closeIcon}
            </span>
          </span>
        </FilterOption>
      )}
      {minHeight && (
        <FilterOption>
          <span className="flex items-center">
            Min. Height: {minHeight}
            <span
              onClick={() => removeFilterAndRedirect(minHeight, MIN_HEIGHT)}
            >
              {closeIcon}
            </span>
          </span>
        </FilterOption>
      )}
      {thicknessId.length > 0 && (
        <FilterOption>
          <span className="flex items-center">
            Thickness:
            {thicknessId.map(({ label, value }) => (
              <span key={value} className="flex items-center pl-2">
                {label}
                <span
                  className="flex items-center"
                  onClick={() =>
                    removeFilterAndRedirect(thicknessId, THICKNESS_ID, value)
                  }
                >
                  {closeIcon}
                </span>
              </span>
            ))}
          </span>
        </FilterOption>
      )}
      {/* @TODO: deselect associated colors */}
      {brandId.length > 0 && (
        <FilterOption>
          <span className="flex items-center">
            Brand:{' '}
            {brandId.map(({ label, value }) => (
              <span key={value} className="flex items-center">
                {label}{' '}
                <span
                  className="flex items-center"
                  onClick={() =>
                    removeFilterAndRedirect(brandId, BRAND_ID, value)
                  }
                >
                  {closeIcon}
                </span>
              </span>
            ))}
          </span>
        </FilterOption>
      )}
      {colorId.length > 0 && (
        <FilterOption>
          <span className="flex items-center">
            Color:{' '}
            {colorId.map(({ label, value }) => (
              <FilterOption key={value}>
                {label}{' '}
                <span
                  className="flex items-center"
                  onClick={() =>
                    removeFilterAndRedirect(colorId, COLOR_ID, value)
                  }
                >
                  {closeIcon}
                </span>
              </FilterOption>
            ))}
          </span>
        </FilterOption>
      )}
      {statusId.length > 0 && (
        <FilterOption>
          <span className="flex items-center">
            Status:{' '}
            {statusId.map(({ label, value }) => (
              <FilterOption key={value}>
                {label}{' '}
                <span
                  className="flex items-center"
                  onClick={() =>
                    removeFilterAndRedirect(statusId, STATUS_ID, value)
                  }
                >
                  {closeIcon}
                </span>
              </FilterOption>
            ))}
          </span>
        </FilterOption>
      )}
      <FilterOption selectList>
        <Select
          classNamePrefix="styledInputDreamTrackr"
          options={sortOptions}
          value={sortOptions.filter(({ id }) => id === sortOption.id)[0]}
          onChange={sortChangeHandler}
        />
      </FilterOption>
    </FilterOptions>
  );
};
