import React from 'react';
import { graphql } from 'react-apollo';

import { dashboardInfo } from '../../graphql/queries/dashboardInfo.gql';
import { hideIntroModal as hideIntroModalMutation } from '../../graphql/mutations/hideIntroModal.gql';

const Intro = ({ hideIntroModal }) => {
  hideIntroModal({
    refetchQueries: [
      {
        query: dashboardInfo,
      },
    ],
  });
  return <p>Hello!</p>;
};
export default graphql(hideIntroModalMutation, {
  name: 'hideIntroModal',
  options: { notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' },
})(Intro);
