import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import LogRocket from 'logrocket';
import { getUser, getToken, deAuthenticate } from '../auth';
import { getUser as getUserQuery } from '../graphql/queries/getUser.gql';
import { graphql } from 'react-apollo';

function UserHOC(UserConsumer) {
  class UserWrapper extends Component {
    constructor() {
      super();
      this.logout = this.logout.bind(this);
      // eslint-disable-next-line react/destructuring-assignment
      LogRocket.identify(this.state.token, {
        name: this.state.user.id || 'guest',
      });
    }

    state = {
      user: { ...getToken(), ...getUser() },
    };

    logout() {
      const {
        props: {
          // eslint-disable-next-line react/prop-types
          history,
          // eslint-disable-next-line react/prop-types
          client: { resetStore },
        },
      } = this;
      deAuthenticate();
      resetStore();
      this.setState({
        token: getToken(),
        user: getUser(),
      });
      history.push('/login');
    }

    render() {
      // console.log('this.props.client', this.props.client);
      const { state, props, logout } = this;
      const { role: { actions } = { actions: [] } } =
        JSON.parse(localStorage.getItem('user')) || {};
      return (
        <UserConsumer {...props} actions={actions} logout={logout} {...state} />
      );
    }
  }
  return withApollo(
    withRouter(
      graphql(getUserQuery, {
        options: _ => {
          const user = getUser() || { id: 0 };
          return {
            variables: {
              id: user.id,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
          };
        },
      })(UserWrapper),
    ),
  );
}

export { UserHOC as default, getToken };
