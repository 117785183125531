/* eslint-disable react/prop-types */
import React from 'react';
import {
  Alert, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';

import { allPriceGroups } from '../../../../../graphql/queries/allPriceGroups.gql';
import { updatePriceGroup as updatePriceGroupMutation } from '../../../../../graphql/mutations/updatePriceGroup.gql';

const Update = ({
  actions: { toggleModal },
  id,
  name,
  pricepersqfoot,
  update,
}) => {
  const initialValues = {
    name,
    pricepersqfoot,
  };

  const onSubmit = async (
    // eslint-disable-next-line no-shadow
    { name, pricepersqfoot },
    { setSubmitting, setErrors },
  ) => {
    await update({
      variables: {
        id,
        name,
        pricepersqfoot: parseFloat(pricepersqfoot),
      },
      refetchQueries: [
        {
          query: allPriceGroups,
        },
      ],
      update: (
        store,
        {
          data: {
            updatePriceGroup: { payload },
          },
        },
      ) => {
        const data = store.readQuery({ query: allPriceGroups });
        const newData = {
          allPriceGroups: data.allPriceGroups.map(
            // eslint-disable-next-line no-underscore-dangle
            item => (item.id === id ? { ...payload, __typename: item.__typename } : item),
          ),
        };
        store.writeQuery({ query: allPriceGroups, data: newData });
      },
    })
      .then(({ data: { updatePriceGroup: { ok, errors } } }) => {
        if (!ok) {
          const errs = {};
          // eslint-disable-next-line no-return-assign
          errors.map(({ path, message }) => (errs[path] = message));
          setErrors(errs);
        } else {
          toggleModal();
        }
      })
      .catch(e => console.log(e));
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    } else if (!/^[A-Z\s]+$/i.test(values.name)) {
      errors.name = 'Invalid name. Letters allowed only.';
    }
    if (!values.pricepersqfoot) {
      errors.pricepersqfoot = 'Required';
    } else if (!/^[0-9]+$/i.test(values.pricepersqfoot)) {
      errors.pricepersqfoot = 'Invalid pricepersqfoot. Numbers allowed only.';
    } else if (values.pricepersqfoot === pricepersqfoot) {
      errors.pricepersqfoot = 'Enter a new pricepersqfoot';
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Alert color="danger" isOpen={!!errors.name}>
              {errors.name && touched.name && errors.name}
            </Alert>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="pricepersqfoot">PricePerSqFoot</Label>
            <Input
              id="pricepersqfoot"
              placeholder="PricePerSqFoot"
              type="text"
              name="pricepersqfoot"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pricepersqfoot}
            />
            <Alert color="danger" isOpen={!!errors.pricepersqfoot}>
              {errors.pricepersqfoot
                && touched.pricepersqfoot
                && errors.pricepersqfoot}
            </Alert>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
            Update
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(updatePriceGroupMutation, { name: 'update' })(Update);
