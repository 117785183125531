import React from 'react';
import { graphql } from 'react-apollo';

import { Table, Button } from 'reactstrap';
import { Add } from './manage';
import { Card } from '../../../../atoms/Card';
import Type from '../../../../components/type';

import { ModalConsumer } from '../../../../context/modal';
import SideBar from '../../../../layout/settings/sidebar';

import { allTypes as allTypesQuery } from '../../../../graphql/queries/allTypes.gql';
import MainLayout from '../../../../layout/main';

const Types = ({ data: { allTypes = [] }, toggleModal }) => (
  <Card title="Types">
    <Button
      onClick={() => toggleModal({ options: { title: 'Add Type' } })(Add)}
    >
      Add
    </Button>
    <Table hover responsive>
      <thead>
        <tr>
          <th scope="row">Id</th>
          <th>Name</th>
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {allTypes.map(type => (
          <Type key={type.id} {...type} />
        ))}
      </tbody>
    </Table>
  </Card>
);

export default MainLayout(
  SideBar(
    graphql(allTypesQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(Types)),
  ),
  'Inventory / Manage Types',
);
