import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import {
  AlertCircle, Search, Tablet, Tag,
} from 'react-feather';
import Logo from '../../components/logo';
import UserHOC from '../../context/token';

const MainContainer = styled.div`
  max-width: 1080px;
  background: white;
  margin: 0 auto;
  margin-top: 10%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 5px 0px;
  border-radius: 0.35em;
  padding: 3em;
  h2 {
    font-size: 1.5em;
    font-weight: 700;
  }
  text-align: left;
`;
const ItemsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 30px;
`;
const GridItem = styled.div``;

const Welcome = ({ user: { name }, history }) => (
  <>
    <MainContainer>
      <div style={{ width: '280px', margin: '0 auto', marginBottom: '2em' }}>
        <Logo />
      </div>

      <h2>{`Welcome to Material Tracker, ${name}`}</h2>

      <ItemsGridContainer>
        <GridItem>
          <AlertCircle />
          <h4>Quickly Reserve Items</h4>
          <p>
              Browse available inventory based on your search criteria and
              reserve items, with PO and comments.
          </p>
        </GridItem>
        <GridItem>
          <Search />
          <h4>Advanced Search</h4>
          <p>
              Find specific inventory with advanced search and filtering based
              on brand, price, thickness, etc.
          </p>
        </GridItem>
        <GridItem>
          <Tablet />
          <h4>On The Go</h4>
          <p>
              Add new inventory, reserve &amp; manage on the go from your mobile
              phone or tablet. Ready when you are.
          </p>
        </GridItem>
        <GridItem>
          <Tag />
          <h4>QR Code</h4>
          <p>
              Quickly find &amp; reserve items in the warehouse by scanning the
              QR code or looking up material ID.
          </p>
        </GridItem>
      </ItemsGridContainer>

      <Button role="link" onClick={() => history.push('/')}>Get Started</Button>
    </MainContainer>
  </>
);

export default UserHOC(Welcome);
