/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';
import Alert from '../../atoms/Alert';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

import { materialDetails } from '../../graphql/queries/materialDetails.gql';
import { reserveMaterial as reserveMaterialMutation } from '../../graphql/mutations/reserveMaterial.gql';

const Reserve = ({
  actions: { toggleModal },
  closeModal,
  materialId,
  reserveMaterial,
  user,
}) => {
  const FORMAT = 'MM/dd/yyyy';

  const initialValues = {
    ponumber: '',
    comment: '',
    expiresOn: dateFnsFormat(new Date(Date.now() + 12096e5), FORMAT),
  };
  const validate = ({ ponumber }) => {
    const errors = {};
    if (!ponumber) {
      errors.ponumber = 'PO is required';
    } else if (/[^a-zA-z0-9]+/.test(ponumber)) {
      errors.ponumber =
        'Only alphanumeric(numbers and letters) characters are allowed';
    }
    return errors;
  };
  const onSubmit = async (
    { ponumber, comment, expiresOn },
    { setSubmitting, setErrors },
  ) => {
    await reserveMaterial({
      variables: {
        materialId,
        ponumber,
        comment,
        expiresOn,
      },
      refetchQueries: [
        {
          query: materialDetails,
          variables: {
            id: materialId,
          },
        },
      ],
    })
      .then(
        ({
          data: {
            reserveMaterial: { errors },
          },
        }) => {
          const errs = {};
          // eslint-disable-next-line no-return-assign
          if (errors) errors.map(({ path, message }) => (errs[path] = message));
          setErrors(errs);
        },
      )
      .catch((e) => console.log('reserveMaterial errors:', e));
    setSubmitting(false);
    closeModal();
  };
  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }
  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values: { ponumber, comment, expiresOn },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {errors.ponumber && touched.ponumber ? (
            <Alert type="error" isOpen={!!errors.ponumber}>
              {errors.ponumber && touched.ponumber && errors.ponumber}
            </Alert>
          ) : (
            ''
          )}

          <div className="block mb-3">
            <label htmlFor="ponumber" className="mb-2 block">
              Purchase Order
            </label>
            <input
              id="ponumber"
              type="text"
              name="ponumber"
              className="block appearance-none block w-full text-gray-900 border-2 border-white rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              onChange={handleChange}
              onBlur={handleBlur}
              value={ponumber}
            />
          </div>
          <div className="block mb-3">
            <label htmlFor="expires_on" className="mb-2 block">
              Expires On
            </label>
            <DayPickerInput
              onDayChange={(_, __, { state: { value } }) =>
                setFieldValue('expiresOn', value)
              }
              format={FORMAT}
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
              showOutsideDays
              fromMonth={new Date()}
              value={expiresOn}
              keepFocus
              component={(props) => (
                <input
                  // value={expiresOn}
                  className="block appearance-none block w-full text-gray-900 border-2 border-white rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  {...props}
                />
              )}
            />
          </div>
          <div className="block mb-3">
            <label htmlFor="comment" className="mb-2 block">
              Comments
            </label>
            <textarea
              rows="10"
              className="form-control"
              id="comment"
              type="text"
              name="comment"
              className="block appearance-none block w-full text-gray-900 border-2 border-white rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              onChange={handleChange}
              onBlur={handleBlur}
              value={comment}
            />
          </div>
          <div className="flex items-center justify-end -m-6 bg-white py-4 px-6 mt-8">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 focus:outline-none appearance-none bg-white hover:bg-gray-100 text-blue-500 font-medium text-sm py-2 px-4 rounded"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="block focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm py-2 px-4 rounded"
            >
              {isSubmitting ? 'Reserving...' : 'Reserve'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default graphql(reserveMaterialMutation, {
  name: 'reserveMaterial',
  options: { notifyOnNetworkStatusChange: true },
})(Reserve);
