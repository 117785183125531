import React, { useState } from 'react';
// eslint-disable-next-line import/named
import Modal from '../../components/modal';

export default (ModalConsumer) => (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({
    title: '',
    body: false,
    footer: false,
  });
  const toggleModal = (options) => {
    setIsOpen(false);
    return (Body, footer, header) => {
      setOptions({
        ...options,
        body: (
          <Body
            actions={{ ...options.actions, toggleModal }}
            closeModal={toggleModal}
            {...options.props}
            {...props}
          />
        ),
        footer,
        header,
      });
      setIsOpen(true);
    };
  };

  return (
    <>
      <ModalConsumer toggleModal={toggleModal} {...props} />
      <Modal isOpen={isOpen} toggleModal={toggleModal} {...options} />
    </>
  );
};
