import { useEffect, useState } from 'react';
import { buildSelectedOptions, sortOptions } from '../views/home/consts';

function useAdvancedFilterValues({ location, data }) {
  const [values, setValues] = useState({
    filters: {
      minWidth: '',
      minHeight: '',
      thicknessId: [],
      brandId: [],
      colorId: [],
      statusId: [],
      colorSelect: [],
    },
    sortOption: sortOptions[0],
    page: 1,
  });

  useEffect(() => {
    setValues(buildSelectedOptions({ location, data }));
  }, [location, data]);

  return values;
}

export default useAdvancedFilterValues;
