import React from 'react';
import { graphql } from 'react-apollo';

import { Table, Button } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import Brand from '../../../../components/brand';
import { Card } from '../../../../atoms/Card';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { ModalConsumer } from '../../../../context/modal';

import { Add } from './manage';
import { allBrands as allBrandsQuery } from '../../../../graphql/queries/allBrands.gql';

const Brands = ({ data: { allBrands = [] }, toggleModal }) => {
  const handleClickAdd = async () => {
    toggleModal({ options: { title: 'Add Brand' }, actions: { toggleModal } })(
      Add,
    );
  };
  return (
    <Card>
      <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Manage Brands
        </h2>
        <div>
          <button
            type="button"
            onClick={handleClickAdd}
            className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm"
          >
            Add Brand
          </button>
        </div>
      </div>

      <table className="table-auto w-full text-left  py-2 text-sm md:text-base text-left">
        <thead>
          <tr>
            <th className="py-2 w-4">#</th>
            <th className="py-2">Name</th>
            <th className="py-2"></th>
          </tr>
        </thead>
        <tbody>
          {allBrands.map((brand) => (
            <Brand {...brand} key={brand.id} />
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allBrandsQuery, {
      options: { fetchPolicy: 'network-only' },
    })(ModalConsumer(withRouter(Brands))),
    'Inventory Brands',
  ),
);
