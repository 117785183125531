import React, { useState, useEffect } from 'react';
import { AlertCircle, X } from 'react-feather';
import { useApolloNetworkStatus } from 'react-apollo-network-status';

import { withRouter } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";
import { deAuthenticate as deAuthenticateUser } from '../../auth';

import { SnackBar } from '../../atoms/Snackbar';

const NetworkStatusSnackBar = ({ code }) => {
  return (
    <Online>
      <SnackBar>
        <span class={`${(code === "FORBIDDEN" || code === "UNAUTHENTICATED") ? "text-orange-500" : "text-red-500"} flex items-center justify-center`}>
          <AlertCircle className="mr-2" />
          <span className="block font-semibold leading-tight text-sm">{code === "FORBIDDEN" && "Access Denied"}{code === "UNAUTHENTICATED" && "Access Denied"}{!code && "Unknown Error"}
            <span className="block font-normal text-sm text-gray-700">{code === "FORBIDDEN" && "Contact your supervisor to elevate your priveleges"}{code === "UNAUTHENTICATED" && "Please login and retry"}{!code && "Please try again at a later time"}</span>
          </span>
          <span className="block font-semibold leading-tight text-sm"></span>
        </span>
      </SnackBar>
    </Online>
  );
}

export default withRouter(({ history, location }) => {
  const status = useApolloNetworkStatus();
  const { queryError: { graphQLErrors: gqlE = [], networkError: qNE } = {}, mutationError: { graphQLErrors: mgqlE = [], networkError: mNE } = {} } = status;
  const graphqlErrors = [...gqlE, ...mgqlE];
  const { networkError } = {
    networkError: {
      ...qNE,
      ...mNE
    }
  }
  // useEffect(() => {
  for (let i = 0; i < graphqlErrors.length; i++) {
    const { code } = graphqlErrors[i];
    if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
      return <NetworkStatusSnackBar code={code}/>
    }
    if (code === 'INTERNAL_SERVER_ERROR') { }//do something in the future
  }
  // }, deAuthenticate)
  if (networkError) {

  }
  return <Offline>
    <SnackBar>
      <span class="text-red-500 flex items-center justify-center">
        <AlertCircle className="mr-2" />
        <span className="block font-semibold leading-tight text-sm">Error Connecting
          <span className="block font-normal text-sm text-gray-700">{"Please connect to a network & refresh this page"}</span>
        </span>
        <span className="block font-semibold leading-tight text-sm"></span>
      </span>
    </SnackBar>
  </Offline>
})
