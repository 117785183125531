import React from 'react';

import { CheckCircle } from 'react-feather';
import { NavLink, Redirect } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Container, Button, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import MainLayout from '../../layout/main';

import { PageLayout } from '../../components/PageLayout';

const Success = ({
  history: {
    location: { state: { id } = {} },
  },
}) =>
  (id && (
    <PageLayout>
      <SuccessWrapper>
        <Title>Material #{id.padStart(5, 0)} Deleted Successfully</Title>
        <Container>
          <AddNewFormContainer>
            <Row>
              <Col>
                <IdLabel>Material ID</IdLabel>
                <Id>{id.padStart(5, 0)}</Id>
              </Col>
              <Col xs={3} lg={2} className="align-self-center">
                <CheckCircle size={48} color="#32B114" />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Material #{id.padStart(5, 0)} Deleted Successfully</p>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-center">
                <Button to={{ pathname: '/dashboard' }} tag={NavLink}>
                  Go to Dashboard
                </Button>
              </Col>
            </Row>
          </AddNewFormContainer>
        </Container>
      </SuccessWrapper>
    </PageLayout>
  )) || <Redirect push to={{ pathname: '/' }} />;

const AddNewFormContainer = styled.div`
  margin: 0 auto;
  padding: 1.5em;
  background: #fff;
  border-radius: 0.35em;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
`;

const SuccessWrapper = styled.div``;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0.5em 0;
`;
const IdLabel = styled.h3`
  color: #6f6f6f;
  font-size: 0.9375em;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const Id = styled.h2`
  color: #222222;
  font-size: 2.5em;
  font-weight: bold;
`;

const QrDetails = styled.p`
  color: #6f6f6f;
`;

export default MainLayout(Success);
