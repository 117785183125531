/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { Container } from 'reactstrap';

import MainLayout from '../../layout/main';
import { HeaderBar, AddNewFormContainer } from '../../atoms';
import Form from './manage/Form';

import { editMaterial } from '../../graphql/queries/editMaterial.gql';
import { updateMaterial as updateMaterialQuery } from '../../graphql/mutations/updateMaterial.gql';
import { uploadMaterialImage as uploadMaterialImageQuery } from '../../graphql/mutations/uploadMaterialImage.gql';
import { PageLayout } from '../../components/PageLayout';

const Material = (props) => {
  const {
    location,
    history,
    data: {
      error,
      loading,
      getMaterial: {
        id = 0,
        images: [{ filepath, filename } = {}] = [],
        irregular: irregularInitValue,
        width: widthInitValue,
        height: heightInitValue,
        price: priceInitValue,
        color = {},
        location: initLocation,
        type = {},
        thickness = {},
        status,
        user = {},
        createdAt,
      } = {},
      allTypes = [],
      allBrands = [],
      allThicknesses = [],
      allLocations = [],
    },
    updateMaterial,
    uploadMaterialImage,
  } = props;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Unknown error occured</p>;

  const { brand } = color;

  const [uploadState, setUploadState] = useState({
    uploading: false,
    uploadProgress: 0,
  });
  const { uploading, uploadProgress } = uploadState;
  const uploadPercentage = uploadProgress * 100;

  const validate = ({
    typeId,
    irregular,
    brandId,
    colorId,
    height,
    width,
    thicknessId,
    price,
    locationId,
    photo,
  }) => {
    const errors = {};
    if (!typeId) {
      errors.typeId = 'Select material type';
    }
    if (!brandId) {
      errors.brandId = 'Required';
    }
    if (!colorId) {
      errors.colorId = 'Required';
    }
    if (!height) {
      errors.height = 'Required';
    }
    if (!width) {
      errors.width = 'Required';
    }
    if (!thicknessId) {
      errors.thicknessId = 'Required';
    }
    if (!price) {
      errors.price = 'Required';
    }
    if (!locationId) {
      errors.locationId = 'Required';
    }
    if (!photo) {
      errors.photo = 'Required';
    }
    return errors;
  };

  const onSubmit = async (
    {
      typeId,
      irregular,
      brandId,
      colorId,
      height,
      width,
      thicknessId,
      price,
      locationId,
      photo,
    },
    { setSubmitting, setErrors },
  ) => {
    try {
      const variables = { id };

      if (typeId !== type.id) {
        variables.typeId = typeId;
      }
      if (brandId !== brand.id) {
        variables.brandId = brandId;
      }
      if (colorId !== color.id) {
        variables.colorId = colorId;
      }
      if (thicknessId !== thickness.id) {
        variables.thicknessId = thicknessId;
      }
      if (locationId !== location.id) {
        variables.locationId = locationId;
      }
      if (irregular !== irregularInitValue) {
        variables.irregular = irregular;
      }
      if (width !== widthInitValue) {
        variables.width = width;
      }
      if (height !== heightInitValue) {
        variables.height = height;
      }

      if (price !== priceInitValue) {
        variables.price = price;
      }

      variables.images = photo.map(({ filepath, filename }) => ({
        filepath,
        filename,
      }));

      const {
        data: {
          updateMaterial: { ok, errors, payload },
        },
      } = await updateMaterial({
        variables,
        update: (
          store,
          {
            data: {
              updateMaterial: { ok, errors, payload },
            },
          },
        ) => {
          const newData = {
            materialDetails: payload,
          };
          // store.writeQuery({ query: materialDetails, data: newData });
        },
      }).catch((e) => console.log('error here', e));
      if (ok) {
        const paths = location.pathname.split('/');
        paths.pop();
        const newRoute = paths.join('/');
        history.push(newRoute);
      }
    } catch (e) {
      console.log('submission error', e);
    }
    setSubmitting(false);
  };

  const initialValues = {
    typeId: type.id || 0,
    irregular: irregularInitValue,
    brandId: brand.id || 0,
    colorId: color.id || 0,
    height: heightInitValue,
    width: widthInitValue,
    thicknessId: thickness.id || 0,
    price: priceInitValue,
    locationId: initLocation.id || 0,
    photo: [{ filename, filepath }],
    colorSelect: allBrands
      .filter(({ value }) => value === brand.id)
      .map(({ colors }) => colors)
      .reduce((pV, cV) => pV.concat(cV)),
  };

  return (
    <PageLayout>
      <HeaderBar
        removePageLayoutDefault
        heading={`Edit #${id.padStart(5, 0)}`}
        subheading={`Added by ${user.name} on ${new Date(
          createdAt,
        ).toDateString()}`}
        status={status.id}
      />
      <Container>
        <AddNewFormContainer>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            uploadPercentage={uploadPercentage}
            uploadMaterialImage={uploadMaterialImage}
            setParentState={setUploadState}
            parentState={{ uploading, uploadProgress }}
            {...props}
            {...uploadState}
            submitButtonText={'Update / Save'}
          />
        </AddNewFormContainer>
      </Container>
    </PageLayout>
  );
};

export default MainLayout(
  compose(
    graphql(updateMaterialQuery, { name: 'updateMaterial' }),
    graphql(uploadMaterialImageQuery, { name: 'uploadMaterialImage' }),
    graphql(editMaterial, {
      options: ({
        match: {
          params: { id },
        },
      }) => ({
        variables: { id },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      }),
    }),
  )(Material),
);
