/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'reactstrap';

import { graphql } from 'react-apollo';
import { Update } from '../../views/settings/inventory/pricegroups/manage';
import { ModalConsumer } from '../../context/modal';

import { allPriceGroups } from '../../graphql/queries/allPriceGroups.gql';
import { removePriceGroup as removePriceGroupMutation } from '../../graphql/mutations/removePriceGroup.gql';

const PriceGroup = ({
  id,
  name,
  pricepersqfoot,
  toggleModal,
  removePriceGroup,
}) => {
  const onUpdateClick = async () => {
    toggleModal({
      options: { title: 'Update PriceGroup' },
      props: { id, name, pricepersqfoot },
    })(Update);
  };
  const onRemoveClick = async () => {
    removePriceGroup({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allPriceGroups,
        },
      ],
      // eslint-disable-next-line consistent-return
      update: (
        store,
        {
          data: {
            removePriceGroup: { ok },
          },
        },
      ) => {
        if (!ok) return false;
        try {
          const data = store.readQuery({ query: allPriceGroups });
          const newData = {
            allPriceGroups: data.allPriceGroups.filter(
              ({ id: typeId }) => typeId !== id,
            ),
          };
          store.writeQuery({ query: allPriceGroups, data: newData });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e, 'eee');
        }
      },
    });
  };
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{pricepersqfoot}</td>
      <td>
        <Button color="primary" onClick={onUpdateClick} size="sm">
          Update
        </Button>
      </td>
      <td>
        <Button color="danger" onClick={onRemoveClick} size="sm">
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default graphql(removePriceGroupMutation, { name: 'removePriceGroup' })(
  ModalConsumer(PriceGroup),
);
