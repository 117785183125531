import React, { useState, useEffect } from 'react';

import UserConsumer from '../../../../context/token';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { Card } from '../../../../atoms/Card';
import Spinner from '../../../../atoms/spinner';

import apolloClient from '../../../../apolloClient';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const userNotificationsQuery = gql`
  query userNotifications {
    userNotifications {
      id
      name
      subscribed
    }
  }
`;

const updateUserNotifications = gql`
  mutation updateNotifications($subscribedIds: [ID]!, $unsubscribedIds: [ID]!) {
    subscribeToEvents(eventIds: $subscribedIds) {
      ok
    }
    unsubscribeFromEvents(eventIds: $unsubscribedIds) {
      ok
    }
  }
`;

const pageTitle = 'Notifications';

const Notifications = (props) => {
  const {
    data: { userNotifications = [], refetch, ...theRest },
  } = props;
  // console.log('theRest', theRest)
  // console.log('props', props)
  const [userNotificationsNewValues, setUpdate] = useState(userNotifications);
  const [savingState, setSavingState] = useState({
    message: '',
    isSaving: false,
  });

  useEffect(() => {
    setUpdate(userNotifications);
  }, [userNotifications.length]);

  const handleCheck = (e) => {
    const { id } = e.target.dataset;
    // inverse the checkstate
    setUpdate(
      userNotificationsNewValues.map((event, i) => {
        const newEvent = { ...event };
        if (event.id === id) newEvent.subscribed = !!!event.subscribed;
        return newEvent;
      }),
    );
  };

  const handleSubmit = (e) => {
    const newEventsUserSubscribedTo = userNotificationsNewValues
      .filter(({ id, subscribed }, i) => {
        // Check whether the user has subscribed or unsubscribed after updating the value
        const userIsNotSubscribedToTheEvent = subscribed > 0;
        // check against initial value
        const userWasNotSubscribedToTheEvent =
          userNotifications[i].subscribed !== subscribed;
        const result =
          userIsNotSubscribedToTheEvent && userWasNotSubscribedToTheEvent;
        return result;
      })
      .map(({ id }) => id);
    const newEventsUserUnsubscribedFrom = userNotificationsNewValues
      .filter(({ id, subscribed }, i) => {
        const userUnsubscribedFromEvent = subscribed === false;
        const userWasSubscribedToEvent = userNotifications[i].subscribed > 0;
        return userUnsubscribedFromEvent && userWasSubscribedToEvent;
      })
      .map(({ id }) => id);
    const result = apolloClient
      .mutate({
        mutation: updateUserNotifications,
        variables: {
          unsubscribedIds: newEventsUserUnsubscribedFrom,
          subscribedIds: newEventsUserSubscribedTo,
        },
      })
      .then((result) => {
        if (
          (result && result.data && result.data.subscribeToEvents.ok) ||
          (result && result.data && result.data.unsubscribeFromEvents.ok)
        ) {
          setSavingState({
            message:
              'Saved ' +
              new Date(result.extensions.tracing.endTime).toLocaleTimeString(),
          });
          refetch();
        }
      });
  };

  return (
    <Card>
      <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Notifications
          <span className="text-gray-700 block text-base font-normal">
            Let us know which email notifications you'd like to receive.
          </span>
        </h2>
        {savingState.isSaving || !userNotifications.length ? (
          <div className="w-4 text-gray-700">
            <Spinner />
          </div>
        ) : (
          <span className="block text-xs text-gray-600">
            {savingState.message}
          </span>
        )}
      </div>
      <table className="table-auto w-full text-left  py-2 text-sm md:text-base text-left">
        <thead>
          <tr>
            <th className="py-2">Id</th>
            <th className="py-2">Name</th>
            <th className="py-2"></th>
          </tr>
        </thead>

        <tbody>
          {userNotificationsNewValues.map(({ id, name, subscribed }, i) => {
            return (
              <tr key={id + name}>
                <td className="border px-4 py-2">{id}</td>
                <td className="border px-4 py-2">{name}</td>
                <td className="border px-4 py-2">
                  <input
                    type="checkbox"
                    data-index={i}
                    data-id={id}
                    id={id + name}
                    checked={subscribed}
                    onChange={handleCheck}
                  />
                  <label htmlFor={id + name}>
                    {subscribed ? 'Unsubscribe' : 'Subscribe'}
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          onClick={handleSubmit}
          className="mt-4 rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm"
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    UserConsumer(
      graphql(userNotificationsQuery, {
        options: {
          fetchPolicy: 'network-only',
        },
      })(Notifications),
    ),
    `${pageTitle}`,
  ),
);
