import React from 'react';
import { graphql } from 'react-apollo';
import {
  Form, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Formik } from 'formik';
import { allThicknesses } from '../../../../../graphql/queries/allThicknesses.gql';
import { addThickness as addThicknessMutation } from '../../../../../graphql/mutations/addThickness.gql';

const AddThickness = ({ actions: { toggleModal }, addThickness }) => {
  const onSubmit = async ({ thickness }, { setSubmitting, setErrors }) => {
    await addThickness({
      variables: { thickness: parseFloat(thickness) },
      refreshQueries: [
        {
          query: allThicknesses,
        },
      ],
      update: (
        store,
        {
          data: {
            createThickness: { ok, errors, payload },
          },
        },
      ) => {
        if (ok) {
          const data = store.readQuery({ query: allThicknesses });
          data.allThicknesses.push(payload);
          store.writeQuery({ query: allThicknesses, data });

          toggleModal();
        } else {
          const err = {};
          errors.forEach(({ path, message }) => {
            err[path] = message;
          });
          setErrors(err);
        }
      },
    });
    setSubmitting(false);
  };
  const initialValues = {
    thickness: '',
  };
  const validate = ({ thickness }) => {
    const errors = {};
    if (!thickness) {
      errors.thickness = 'Required';
    }
    return errors;
  };
  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        values: { thickness },
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {/* <Alert color="danger" isOpen={errors.length > 0}>
                        { errors.map((error) => error) }
                    </Alert> */}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="thickness">Thickness</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={thickness}
                id="thickness"
                name="thickness"
              />
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              value="Create"
              disabled={isSubmitting}
              block
            >
              Add
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default graphql(addThicknessMutation, { name: 'addThickness' })(
  AddThickness,
);
