/* eslint-disable react/prop-types */
import React from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';

import { Formik } from 'formik';

import { graphql } from 'react-apollo';
import { allLocations } from '../../../../../graphql/queries/allLocations.gql';
import { addLocation as addLocationMutation } from '../../../../../graphql/mutations/addLocation.gql';

const Add = ({ addLocation, closeModal }) => {
  const initialValues = {
    location: '',
  };
  const onSubmit = async ({ location: name }, { setSubmitting, setErrors }) => {
    await addLocation({
      variables: {
        name,
      },
      refetchQueries: [
        {
          query: allLocations,
        },
      ],
      update: (
        store,
        {
          data: {
            createLocation: { payload },
          },
        },
      ) => {
        const data = store.readQuery({ query: allLocations });
        data.allLocations.push({
          ...payload,
        });
        store.writeQuery({ query: allLocations, data });
      },
    }).then(
      ({
        data: {
          createLocation: { ok },
        },
      }) => {
        if (!ok) {
          setErrors();
        } else {
          closeModal();
        }
      },
    );
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.location) {
      errors.location = 'Required';
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="location">Location</Label>
            <Input
              id="location"
              className={errors.location ? 'is-invalid' : ''}
              placeholder="Location"
              type="text"
              name="location"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location}
            />
          </FormGroup>
          <div className="invalid-feedback">
            {errors.location && touched.location && errors.location}
          </div>
          <Button type="submit" disabled={isSubmitting} color="primary">
            Submit
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(addLocationMutation, { name: 'addLocation' })(Add);
