import { ApolloLink } from 'apollo-link';
import { getToken } from './auth';

export default new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const headers = {};
  if (token) {
    headers['x-token'] = token;
  }
  if (refreshToken) {
    headers['x-refresh-token'] = refreshToken
  }
  operation.setContext({
    headers: headers,
  });
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const { response: { headers } } = context;

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) {
        localStorage.setItem('token', token);
      }
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
      getToken();
    }
    return response;
  });
});
