import React from 'react';
import { graphql } from 'react-apollo';

import { Alert, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { Formik } from 'formik';
import { addBrand as addBrandMutation } from '../../../../../graphql/mutations/addBrand.gql';
import { allBrands } from '../../../../../graphql/queries/allBrands.gql';

const AddBrand = (props) => {
  const { closeModal, addBrand } = props;
  const onSubmit = async ({ name }, { setSubmitting, setErrors }) => {
    await addBrand({
      variables: { name },
      refetchQueries: [
        {
          query: allBrands,
        },
      ],
      update: (
        store,
        {
          data: {
            createBrand: { ok, errors, payload },
          },
        },
      ) => {
        if (!ok) {
          const errs = {};
          errors.forEach(({ path, message }) => {
            errs[path] = message;
          });
          setErrors(errs);
          return false;
        }
        const data = store.readQuery({ query: allBrands });
        data.allBrands.push({
          ...payload,
        });
        store.writeQuery({ query: allBrands, data });
        closeModal();
        return true;
      },
    });
    setSubmitting(false);
  };
  const initialValues = {
    name: '',
  };
  const validate = ({ name }) => {
    const errors = {};
    if (!name) {
      errors.name = 'Required';
    }
    return errors;
  };
  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        values: { name },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <Alert color="danger" isOpen={!!touched.name && !!errors.name}>
            {errors.name}
          </Alert>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                id="name"
                name="name"
              />
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              value="Create"
              disabled={isSubmitting}
              block
            >
              Add
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default graphql(addBrandMutation, { name: 'addBrand' })(AddBrand);
