import styled from 'styled-components';

const AddNewFormContainer = styled.div`
  margin: 0 auto;
  padding: 2.5em;
  background: #fff;
  border-radius: 0.35em;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  max-width: 760px;
`;

export default AddNewFormContainer;
