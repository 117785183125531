import React from 'react';
import { Formik } from 'formik';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';

import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { allRoles as allRolesQuery } from '../../../../../graphql/queries/allRoles.gql';
import { allActions as allActionsQuery } from '../../../../../graphql/queries/allActions.gql';
import { createRole as createRoleMutation } from '../../../../../graphql/mutations/createRole.gql';

import { filterArrayForSelectedValues } from '../../../../../sharedFunctions';

const CreateRole = ({ data: { allActions = [] }, createRole, closeModal }) => {
  const validate = ({ name, actions }) => {
    const errors = {};
    if (!name) {
      errors.name = 'Required';
    }
    if (actions.length < 1) {
      errors.actions = 'Required';
    }
    return errors;
  };
  const onSubmit = async ({ name, actions }, { setSubmitting, setErrors }) => {
    // eslint-disable-next-line no-param-reassign
    const actionIds = actions.map(({ id }) => id);
    await createRole({
      variables: { name, actions: actionIds },
      refetchQueries: [
        {
          query: allRolesQuery,
        },
      ],
      update: (
        store,
        {
          data: {
            createRole: { ok, errors, payload },
          },
        },
      ) => {
        if (ok) {
          const data = store.readQuery({ query: allRolesQuery });
          data.allRoles.push(payload);

          closeModal();
        } else {
          const e = {};
          // eslint-disable-next-line no-return-assign
          errors.forEach(({ path, message }) => (e[path] = message));
          setErrors(e);
        }
      },
    });
    setSubmitting(false);
  };
  const initialValues = {
    name: '',
    actions: [],
  };
  // const actionsSelect = allActions.map(({ id: value, name: label }) => ({
  //   value,
  //   label,
  // }));

  return (
    <Formik
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        values: { name, actions },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-section">
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                className={errors.name && touched.name ? 'is-invalid' : ''}
                type="text"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={name}
                name="name"
              />
              <div className="invalid-feedback">{errors.name}</div>
            </FormGroup>
            <FormGroup>
              <Label for="role">Actions</Label>
              {allActions.map((action) => (
                <FormGroup
                  className="custom-control custom-checkbox"
                  key={action.id}
                  check
                >
                  <Input
                    id={`action_${action.id}`}
                    type="checkbox"
                    name="actions"
                    className="custom-control-input"
                    onChange={({ target: { checked } }) => {
                      const selectedValues = filterArrayForSelectedValues(
                        actions,
                        action,
                        checked,
                      );
                      setFieldValue('actions', selectedValues);
                    }}
                    onBlur={handleBlur}
                    checked={
                      actions.filter(
                        ({ id: hayStack }) => hayStack == action.id,
                      ).length > 0
                    }
                  />
                  <Label
                    className="custom-control-label"
                    for={`action_${action.id}`}
                  >
                    {action.name}
                  </Label>
                </FormGroup>
              ))}
              {/* <Select
                className={
                  errors.actions && touched.actions ? 'is-invalid' : ''
                }
                options={actionsSelect}
                type="text"
                id="actions"
                onChange={(values) => {
                  setFieldValue('actions', values);
                }}
                onBlur={() => setFieldTouched('actions', true)}
                name="actions"
                isMulti
                closeMenuOnSelect={false}
              /> */}
              <div className="invalid-feedback">{errors.actions}</div>
            </FormGroup>
          </div>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default compose(
  graphql(createRoleMutation, { name: 'createRole' }),
  graphql(allActionsQuery, { options: { fetchPolicy: 'network-only' } }),
)(CreateRole);
