function TokenExpired(message, fileName, lineNumber) {
  const instance = new Error(message, fileName, lineNumber);
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, TokenExpired);
  }
  return instance;
}

TokenExpired.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

Object.setPrototypeOf(TokenExpired, Error);

export default TokenExpired;
