import React from 'react';

import { SnackBar } from '../../atoms/Snackbar';

const DevServerNotification = () => {
  return process.env.REACT_DEV_SERVER ? (
    <SnackBar className={'bg-orange-400 text-white right'}>DEV Server</SnackBar>
  ) : null;
};

export default DevServerNotification;
