import React from 'react';
import UserConsumer from '../../context/token';

function Protected(props) {
  const {
    children,
    publicOnly,
    user: { id },
    actions,
    requiredAction,
    alternateComponent: AlternateComponent,
  } = props;
  const Private = !!id && !publicOnly;
  const GuestOnly = !id && publicOnly;
  const userHasAccess =
    !!actions.filter(({ id: actionId }) => requiredAction == actionId).length &&
    Private;
  return (requiredAction ? userHasAccess : Private) || GuestOnly ? (
    <>{children}</>
  ) : (
    (AlternateComponent && <AlternateComponent />) || null
  );
}
export default UserConsumer(Protected);
