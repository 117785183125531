/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Provider } from './context';

export default class ModalProvider extends Component {
  constructor() {
    super();
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  // acts like an if statement
  // returns a function, call the secondary function for the modal to be toggled on/off.
  toggleModal({ options, actions, props } = {}) {
    this.setState({
      isOpen: false,
    });
    return (ModalBody, ModalFooter, ModalHeader) => {
      this.setState({
        isOpen: true,
        options,
        actions,
        header: ModalHeader && <ModalHeader />,
        body: (
          <ModalBody
            actions={{ ...actions, toggleModal: this.toggleModal }}
            {...props}
          />
        ),
        footer: ModalFooter && <ModalFooter />,
      });
    };
  }

  render() {
    const {
      state,
      props: { children },
      toggleModal,
    } = this;
    return <Provider value={{ ...state, toggleModal }}>{children}</Provider>;
  }
}
