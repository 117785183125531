/* eslint-disable react/prop-types */
import React from 'react';

const ReservationDetails = (props) => {
  const { details } = props;
  const {
    details: {
      ponumber,
      comment,
      user: { name },
      status: { id: statusId },
      createdAt,
      expiresOn,
    } = {
      user: {},
      status: {},
    },
  } = props;

  return details && statusId == 2 ? (
    <span className="block mb-4 border-b border-gray-100">
      <span class="block uppercase tracking-wider text-gray-800 text-xs font-bold mb-2">
        Reservation Details
      </span>
      <span
        className={`block text-gray-700 text-sm ${
          new Date() > new Date(expiresOn) ? 'text-red-700' : ''
        }`}
      >
        {new Date() > new Date(expiresOn) ? 'Expired On' : 'Expires On'}
      </span>
      <span
        className={`block text-gray-900 mb-2 ${
          new Date() > new Date(expiresOn) ? 'text-red-700' : ''
        }`}
      >
        {new Date(expiresOn).toDateString()}
      </span>

      <span className="block text-gray-700 text-sm">Reserved By</span>
      <span className="block text-gray-900 mb-2">{name}</span>
      <span className="block text-gray-700 text-sm">Reserved On</span>
      <span className="block text-gray-900 mb-2">
        {new Date(createdAt).toDateString()}
      </span>

      <span className="block text-gray-700 text-sm">PO</span>
      <span className="block text-gray-900 mb-2">{ponumber}</span>

      {comment ? (
        <>
          <span className="block text-gray-700 text-sm">Comment</span>
          <span
            className="block text-gray-900 mb-2 whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: comment }}
          />
        </>
      ) : null}
    </span>
  ) : null;
};

export default ReservationDetails;
