/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { boxShadowCard } from '../../views/index.scss';


export const Panel = styled.div`
    padding: ${props => props.padding || '1em'}
    ${props => props.clear && `
        :after {
            content: '';
            display: block;
            clear: both;
        };`
}
`;

export const Card = props => (
  <div className="bg-white rounded-lg shadow-md p-8" {...props}>
    <div className="flex">{props.title ? <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">{props.title}</h2> : <span></span>}</div>
    
    <div className="py-2" {...props}>{props.children}</div>
  </div>
);
