/* eslint-disable react/prop-types */
import React from 'react';

import { Button } from 'reactstrap';

import { graphql } from 'react-apollo';
import { ModalConsumer } from '../../context/modal';
import { cancelMaterialReservation as cancelMaterialReservationMutation } from '../../graphql/mutations/cancelMaterialReservation.gql';
import { markMaterialReservationAsSold as markMaterialReservationAsSoldMutation } from '../../graphql/mutations/markMaterialReservationAsSold.gql';
import { ConfirmModal } from '..';
import Protected from '../../components/protected';

const StatusButtons = ModalConsumer((props) => {
  const {
    materialId,
    reservation: { id, ponumber } = {},
    refetch,
    toggleModal,
    history,
  } = props;
  const actionWrapper = async (action) => {
    const {
      data: { cancelMaterialReservation, markMaterialReservationAsSold },
    } = await action();
    refetch();
    toggleModal();
  };
  const CancelReservation = () => {
    const MarkAvailable = async () =>
      toggleModal({
        options: { title: 'Cancel Reservation' },
        props: {
          reservationId: id,
          text: `cancel reservation ${ponumber}`,
          actionWrapper,
          buttonLabel: 'Cancel Reservation',
        },
      })(
        graphql(cancelMaterialReservationMutation, {
          name: 'action',
          options: { notifyOnNetworkStatusChange: true },
        })(ConfirmModal),
      );
    return (
      <button
        type="button"
        onClick={MarkAvailable}
        className="block w-full mb-2 text-base focus:outline-none appearance-none bg-gray-200 hover:bg-gray-300 text-gray-900 font-medium text-sm py-2 px-4 rounded"
      >
        Cancel Reservation
      </button>
    );
  };
  const SoldButton = (props) => {
    const onClickMarkAsSold = async () =>
      toggleModal({
        options: { title: 'Mark Material as Sold' },
        props: {
          reservationId: id,
          text: `mark material #${materialId.padStart(5, 0)} as Sold`,
          buttonLabel: 'Mark',
          actionWrapper,
        },
      })(
        graphql(markMaterialReservationAsSoldMutation, {
          name: 'action',
          options: { notifyOnNetworkStatusChange: true },
        })(ConfirmModal),
      );
    return (
      <button
        type="button"
        onClick={onClickMarkAsSold}
        className="block w-full mb-2 text-base focus:outline-none appearance-none bg-green-100 text-green-500 font-medium text-sm py-2 px-4 rounded"
        {...props}
      >
        Mark as Sold
      </button>
    );
  };
  return (
    <>
      <CancelReservation />
      <Protected requiredAction={0}>
        <SoldButton />
      </Protected>
    </>
  );
});

export default StatusButtons;
