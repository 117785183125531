import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { Home, Trello, Layers, LogOut, Settings, Users } from 'react-feather';
import UserConsumer from '../../context/token';
import TabBar from '../tabbar';
import Logo from '../logo';
import Protected from '../protected';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleSecondary = this.toggleSecondary.bind(this);

    this.state = {
      //   query: '',
      isOpen: false,
      isOpenSecondary: false,
    };
  }

  toggle() {
    const {
      state: { isOpen },
    } = this;
    this.setState({
      isOpen: !isOpen,
    });
  }

  toggleSecondary() {
    const {
      state: { isOpenSecondary },
    } = this;
    this.setState({
      isOpenSecondary: !isOpenSecondary,
    });
  }

  render() {
    const {
      props: {
        isHidden,
        data: {
          getUser: { id, name, email, role: { name: roleName } } = {
            id: 0,
            name: '',
            role: {
              name: '',
            },
          },
        },
        user,
        logout,
      },
      state: {
        // query,
        isOpen,
        isOpenSecondary,
      },
    } = this;

    return isHidden ? null : (
      <div className="bg-white relative z-20">
        <nav className="container flex items-center justify-center md:justify-between h-16">
          <div className="flex h-full items-center">
            <div className="flex-shrink-0 flex items-center">
              
            <Link to="/">
              <div style={{ width: '160px' }}>
                <Logo />
              </div>
            </Link>
            </div>
            
            <div className="sm:ml-6  block lg:flex lg:items-center lg:w-auto h-full">
            <div className="hidden md:flex flex text-sm lg:text-base h-full" >
              <Protected>
                <NavLink
                  to="/dashboard"
                  activeClassName="border-blue-500 text-gray-900"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out">
              
                  Dashboard
                </NavLink>
              </Protected>
              <NavLink
                to="/remnants"
                activeClassName="border-blue-500 text-gray-900"
                className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >
                Remnants
              </NavLink>
              <NavLink
                to="/fullslabs"
                activeClassName="border-blue-500 text-gray-900"
                className="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >
                Full Slabs
              </NavLink>
            </div>
          </div>

          </div>
          <div className="hidden block lg:hidden">
            <button
              onClick={this.toggle}
              className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          
          <Protected publicOnly>
            <NavLink
              to="/login"
              className="signed-in-name pr-6 text-gray-800 block"
            >
              Login
            </NavLink>
          </Protected>
          <Protected>
            <button
              onClick={this.toggle}
              className="outline-none focus:outline-none  p-2 relative block rounded flex items-center"
            >
              <span className="hidden md:block">
                <span className="signed-in-name pr-6 text-gray-800  text-sm lg:text-base">
                  {name || user.name}
                </span>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-500">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                  </svg>
                </div>
              </span>
              <div
                style={{ top: '100%', minWidth: '250px' }}
                className={
                  'text-left right-0 w-full bg-white z-20 rounded absolute shadow-lg top-100 animate-in-dropdown' +
                  (this.state.isOpen ? ' show' : ' pointer-events-none')
                }
              >
                <div className="overflow-hidden rounded">
                  <span className="bg-white w-full px-4 block py-4 border-b border-gray-100">
                    <span className="text-sm text-gray-600 block bg-white">
                      Logged in as
                    </span>
                    <span className="text-sm text-gray-700 block">{email}</span>
                  </span>

                  <NavLink
                    to="/settings/general/account"
                    isActive={() => false}
                  >
                    <span className="bg-white w-full px-4 block py-4 hover:bg-gray-100 border-b border-gray-100 flex">
                      <Settings className="text-gray-600 pr-2" />
                      Account Settings
                    </span>
                  </NavLink>

                  <Protected requiredAction={5}>
                    <NavLink to="/settings/inventory">
                      <span className="bg-white w-full px-4 block py-4 hover:bg-gray-100 border-b border-gray-100 flex">
                        <Layers className="text-gray-600 pr-2" />
                        Inventory Settings
                      </span>
                    </NavLink>
                  </Protected>

                  <Protected requiredAction={8}>
                    <NavLink to="/settings/general/users">
                      <span className="bg-white w-full px-4 block py-4 hover:bg-gray-100 border-b border-gray-100 flex">
                        <Users className="text-gray-600 pr-2" />
                        Manage Users
                      </span>
                    </NavLink>
                  </Protected>

                  <NavLink to="/login" onClick={() => logout()}>
                    <span className="bg-white w-full px-4 hover:bg-red-100 hover:text-red-600 block py-4 flex">
                      <LogOut className="text-gray-600 hover:text-red-600 pr-2" />
                      Sign Out
                    </span>
                  </NavLink>
                </div>
              </div>
            </button>
          </Protected>
        </nav>
        <TabBar />
      </div>
    );
  }
}

export default UserConsumer(withRouter(Header));
