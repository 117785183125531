import React from 'react';
import { graphql } from 'react-apollo';

import { Table, Button } from 'reactstrap';
import PriceGroup from '../../../../components/pricegroup';
import { Add } from './manage';
import { Card } from '../../../../atoms/Card';

import { ModalConsumer } from '../../../../context/modal';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';

import { allPriceGroups as allPriceGroupsQuery } from '../../../../graphql/queries/allPriceGroups.gql';

const PriceGroups = ({ data: { allPriceGroups = [] }, toggleModal }) => (
  <Card title="Price Groups">
    <Button onClick={() => toggleModal({ options: { title: 'Add' } })(Add)}>
      Add
    </Button>
    <Table hover responsive>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>PricePerSqFoot</th>
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {allPriceGroups.map(pricegroup => (
          <PriceGroup key={pricegroup.id} {...pricegroup} />
        ))}
      </tbody>
    </Table>
  </Card>
);

export default MainLayout(
  SideBar(
    graphql(allPriceGroupsQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(PriceGroups)),
  ),
  'Inventory / Manage Price Groups',
);
