import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Brands from '../../views/settings/inventory/brands';
import Colors from '../../views/settings/inventory/colors';
import Pricing from '../../views/settings/inventory/pricing';
import PriceGroups from '../../views/settings/inventory/pricegroups';
import Locations from '../../views/settings/inventory/locations';
import Thicknesses from '../../views/settings/inventory/thicknesses';
import Types from '../../views/settings/inventory/types';
import Status from '../../views/settings/inventory/status';

import NoMatch from '../../views/pagenotfound';

export default ({ match: { path } }) => (
  <Switch>
    <Route exact path={`${path}/brands/`} component={Brands} />
    <Route exact path={`${path}/colors`} component={Colors} />
    <Route exact path={`${path}/pricing`} component={Pricing} />
    <Route exact path={`${path}/pricegroups`} component={PriceGroups} />
    <Route exact path={`${path}/locations`} component={Locations} />
    <Route exact path={`${path}/statuses`} component={Status} />
    <Route exact path={`${path}/thicknesses`} component={Thicknesses} />
    <Route exact path={`${path}/types`} component={Types} />
    <Route exact path={path} component={Brands} />
    <Route component={NoMatch} />
  </Switch>
);
