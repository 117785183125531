import React from 'react';
import styled from 'styled-components';

const UserIcon = styled.span`
  color: #444;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #f4f4f4;
`;
const Name = ({ name }) => {
  const nameSplit = name.split(/\s/);
  const firstNameLetter = name[0];
  const lastNameLetter = nameSplit[nameSplit.length - 1][0];
  return <span>{firstNameLetter + lastNameLetter}</span>;
};

export default ({ name }) => (
  <UserIcon>
    <Name name={name} />
  </UserIcon>
);
