/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';
import QRCode from 'qrcode.react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Alert from '../../../atoms/Alert';

import { Card } from '../../../atoms/Card';
import { ModalConsumer } from '../../../context/modal';
import User from '../../../context/token';

import { materialDetails as materialDetailsQuery } from '../../../graphql/queries/materialDetails.gql';

class MaterialLabel extends React.Component {
  render() {
    const {
      props: {
        data: { error, loading, getMaterial },
      },
    } = this;
    if (loading) return null;
    if (error) return <Alert color="danger">{error.toString()}</Alert>;

    if (!getMaterial) return <Alert color="danger">No such item exists!</Alert>;
    const {
      id,
      width,
      height,
      price,
      color,
      location,
      type,
      thickness: { thickness },
    } = getMaterial;

    function numberWithCommas(number) {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const { brand } = color;

    return (
      <div className="flex justify-center h-screen items-center">
        <div className="bg-white shadow py-8 px-4 w-full md:w-1/2">
          <div className="flex">

            <div className="w-1/4 text-left pr-3">
            <QRCode
            height={'100%'}
            width={'100%'}
            renderAs={'svg'}
            value={`https://gqls.materialtrackr.com/${id.padStart(5, 0)}`}
            level="H"
              />
            </div>

            <div className="w-3/4 pl-2">

            <span className="block text-5xl font-bold leading-none">{`#${id.padStart(5, 0)}`}</span>
            <span className="block text-4xl text-gray-800">{brand.name} {color.name}</span>
            <div className="flex">

              <div className="flex-1">
                <span className="text-gray-700 block text-base">Height</span>
                <span className="text-gray-900 block font-semibold text-base">{height}"</span>
              </div>
              <div className="flex-1">
                <span className="text-gray-700 block text-base">Width</span>
                <span className="text-gray-900 block font-semibold text-base">{width}"</span>
              </div>
              <div className="flex-1">
                <span className="text-gray-700 block text-base">Thickness</span>
                <span className="text-gray-900 block font-semibold text-base">{thickness}"</span>
              </div>
              <div className="flex-1">
                <span className="text-gray-700 block text-base">Sq.Ft</span>
                <span className="text-gray-900 block font-semibold text-base">{numberWithCommas(width * height)}</span>
              </div>
              <div className="flex-1">
                <span className="text-gray-700 block text-base">Location</span>
                <span className="text-gray-900 block font-semibold text-base">{location.name}</span>
              </div>
            </div>
            </div>
          </div>
          

         
        </div>

      </div>
    );
  }
}

export default graphql(materialDetailsQuery, {
  options: ({
    match: {
      params: { id },
    },
  }) => ({ variables: { id }, notifyOnNetworkStatusChange: true }),
})(User(ModalConsumer(MaterialLabel)));
