/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { Formik } from 'formik';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { Users } from 'react-feather';
import { Redirect } from 'react-router-dom';
import isAuthenticated, { storeTokens } from '../../auth';

import Logo from '../../components/logo';

import Alert from '../../atoms/Alert';
import { primary } from '../index.scss';
import IntroModal from '../../components/intro';

import { ModalConsumer } from '../../context/modal';

const CreateAccountMessageContainer = styled.div`
  text-align: center;
  width: 100%;
  svg {
    font-size: 4em;
    margin: 0.5em;
  }
  p {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { toggleCreateAccount: false };

    this.handleCreateAccountClick = this.handleCreateAccountClick.bind(this);
  }

  handleCreateAccountClick(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      toggleCreateAccount: !prevState.toggleCreateAccount,
    }));
  }

  render() {
    if (isAuthenticated()) {
      return <Redirect push to={{ pathname: '/dashboard' }} />;
    }

    const DontAllowCreateAccount = (props) => (
      <CreateAccountMessageContainer>
        <h1>Create an Account</h1>
        <Users color={primary} size="70" />
        <p>
          Accounts are managed by your organization administrator.
          <br />
          <br />
          <b>{props.orgName}</b>
        </p>
        <Button
          className="mt-4"
          color="primary"
          onClick={this.handleCreateAccountClick}
        >
          Return to Login
        </Button>
      </CreateAccountMessageContainer>
    );

    const initialValues = {
      email: '',
      password: '',
    };

    const onSubmit = async (
      { email, password },
      { setSubmitting, setErrors },
    ) => {
      const {
        props: { authenticateUser, history, toggleModal, location },
      } = this;
      authenticateUser({
        variables: { username: email, email, password },
      }).then(
        ({
          data: {
            authenticateUser: { ok, token, refreshToken, errors, payload },
          },
        }) => {
          if (ok) {
            storeTokens({ token, refreshToken }, payload, email);
            if (!location.state) {
              history.push('/dashboard');
            } else {
              history.push(location.state.redirect);
            }
          } else {
            const err = {};
            if (errors) {
              errors.forEach(({ path, message }) => {
                err[`${path}`] = message;
              });
            }

            setErrors(err);
          }
          setSubmitting(false);
        },
      );
    };
    // const validate = () => ({ email, password }) => {
    //   const errors = {};
    //   if(!email) {
    //       errors.email = 'Email is required'
    //   }
    //   if(!password) {
    //       errors.password = 'Password is required'
    //   }
    //   return errors;
    // };
    const {
      state: { toggleCreateAccount },
    } = this;
    return (
      <>
        <div className="min-h-screen flex flex-col justify-center bg-gray-100 py-8">
          <div className="-mt-8 w-full max-w-md mx-auto px-6">
            <div className="w-64 mb-4 text-center mx-auto">
              <Logo />
            </div>

            {toggleCreateAccount ? (
              <DontAllowCreateAccount orgName="America's Dream HomeWorks" />
            ) : (
              <>
                <Formik
                  initialValues={initialValues}
                  // validate={validate}
                  onSubmit={onSubmit}
                >
                  {({
                    values: { email, password },
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <>
                      {Object.keys(errors).map((key, i) => (
                        <Alert type="error" isOpen key={Math.random(i)}>
                          {errors[key]}
                        </Alert>
                      ))}
                      <Form
                        onSubmit={handleSubmit}
                        className="mt-8 sm:bg-white sm:rounded-lg sm:shadow-md sm:px-8 sm:py-8"
                      >
                        <h2 className="font-semibold text-2xl mb-4">Sign In</h2>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-900 leading-tight"
                          >
                            Email or username
                          </label>
                          <input
                            type="text"
                            autoComplete="username"
                            autoFocus
                            className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={email}
                            id="email"
                            name="email"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-gray-900 leading-tight"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white  px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                            onBlur={handleBlur}
                            value={password}
                            id="password"
                            name="password"
                          />
                          <a
                            href="#"
                            role="button"
                            className="mt-2 d-block sr-only"
                          >
                            Forgot Password?
                          </a>
                        </div>

                        <button
                          type="submit"
                          value="Login"
                          disabled={isSubmitting}
                          className="block w-full px-4 py-3 leading-tight rounded-lg bg-blue-500 hover:bg-blue-700 text-white font-semibold focus:outline-none"
                        >
                          {isSubmitting ? 'Logging you in...' : 'Login'}
                        </button>
                      </Form>
                    </>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const authenticateUser = gql`
  mutation authenticateUser(
    $username: String
    $email: String
    $password: String!
  ) {
    authenticateUser(username: $username, email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
      payload {
        id
        name
        firstTime
        active
        email
        locked
        role {
          id
          name
          actions {
            id
            name
          }
        }
      }
    }
  }
`;

export default graphql(authenticateUser, {
  name: 'authenticateUser',
  options: { notifyOnNetworkStatusChange: true },
})(ModalConsumer(Login));
