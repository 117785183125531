/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';

import { Formik } from 'formik';
import {
  FormGroup, Label, Input, Alert, Button,
} from 'reactstrap';
import { allStatuses as allStatusesQuery } from '../../../../../graphql/queries/allStatuses.gql';
import { addStatus as addStatusMutation } from '../../../../../graphql/mutations/addStatus.gql';


const AddStatus = ({ actions: { toggleModal }, addStatus }) => {
  const onSubmit = async ({ name }, { setSubmitting, setErrors }) => {
    await addStatus({
      variables: {
        name,
      },
      refetchQueries: [
        {
          query: allStatusesQuery,
        },
      ],
      update: (store, { data: { createStatus: { payload } } }) => {
        const data = store.readQuery({ query: allStatusesQuery });
        data.allStatuses.push({
          ...payload,
        });
        store.writeQuery({ query: allStatusesQuery, data });
      },
    }).then(({ data: { createStatus: { errors } } }) => {
      const errs = {};
      errors.map(
        ({ path, message }) => errs[path] = message,
      );
      setErrors(errs);
    }).catch(e => console.log('addStatus errors', e));
    setSubmitting(false);
    toggleModal();
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };
  const initialValues = {
    name: '',
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Name *</Label>
            <Input
              id="name"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Alert color="danger" isOpen={!!errors.name}>{errors.name && touched.name && errors.name}</Alert>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
                Add
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(addStatusMutation, { name: 'addStatus' })(AddStatus);
