import React from 'react';
import styled from 'styled-components';
import { AlertCircle, Info, Check, X } from 'react-feather';
import {
  success,
  error,
  warning,
  successLight,
  errorLight,
  warningLight,
} from '../../views/index.scss';

export default ({ id, filled, className }) => {
  // id = 1 == availiable
  // id = 2 == reserved
  // id = 3 == sold
  // id = 4 == deleted

  let color = '#333';
  let bgcolor = '#222';
  let text = 'Default';
  let icon = <Info />;

  if (id == 1) {
    color = 'text-green-500';
    bgcolor = 'bg-green-100';
    text = 'Available';
    icon = <Check className="p-1" />;
  }
  if (id == 2) {
    color = 'text-orange-500';
    bgcolor = 'bg-orange-100';

    text = 'On Hold';
    icon = <AlertCircle className="p-1" />;
  }
  if (id == 3) {
    color = 'text-red-500';
    bgcolor = 'bg-red-100';
    text = 'Sold';
    icon = <X className="p-1" />;
  }
  if (id == 4) {
    color = 'text-gray-800';
    bgcolor = 'bg-gray-300';
    text = 'Deleted';
    icon = <Info className="p-1" />;
  }

  if (filled) {
    bgcolor = color;
    color = '#fff';
  }

  const StatusText = styled.p`
    display: inline-block;
    font-weight: 500;
    margin: 0;

    font-size: 15px;
  `;

  return (
    <div
      className={`rounded inline-block pr-1 ${className}  ${bgcolor}  ${color}`}
    >
      <div className={`flex items-center p-1`}>
        {icon}

        <StatusText>{text}</StatusText>
      </div>
    </div>
  );
};
