/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { Button, Table } from 'reactstrap';
import Color from '../../../../components/color';
import { Add as AddColor } from './manage';
import { Card } from '../../../../atoms/Card';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { ModalConsumer } from '../../../../context/modal';

import { allColors as allColorsQuery } from '../../../../graphql/queries/allColors.gql';

const Colors = ({ data: { allColors = [] }, toggleModal }) => {
  const handleClickAdd = () => {
    toggleModal({ options: { title: 'Add Color' } })(AddColor);
  };


  allColors.sort((a, b) => (a.name > b.name) ? 1 : -1)

  return (

    <Card>
        <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
        Manage Colors
        </h2>
        <div>
        <button type="button" onClick={handleClickAdd} className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm">Add Color</button>
        </div>
      </div>


      <table className="table-auto w-full text-left  py-2 text-sm md:text-base text-left">
          <thead>
            <tr>
              <th className="py-2 w-4">#</th>
              <th className="py-2">Name</th>
              <th className="py-2">Brand</th>
              <th className="py-2"></th>
  
            </tr>
          </thead>
          <tbody>
          {allColors.map(color => (
            <Color key={color.id} {...color} />
          ))}
        </tbody>
      </table>

  </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allColorsQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(withRouter(Colors))),
    'Inventory Colors',
  ),
);
