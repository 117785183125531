import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { ChevronRight } from 'react-feather';
import Protected from '../../components/protected';
import MainLayout from '../../layout/main';
import UserConsumer from '../../context/token';
import UserIcon from '../../atoms/UserIcon';

const AdminSidebarUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;
const AdminSidebarLl = styled.li`
  a {
    padding: 0.35em 0 0.35em 0.35em;
    display: block;
    color: #444;
  }
`;
const LoggedInUserInfo = styled.div`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  margin-top: 0em;
  margin-bottom: 1em;
  top: 0;
  z-index: 2;

  .container {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1.5em 1em;
    display: flex;
    align-items: center;
    &:hover {
      background: #f6f6f6;
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
  }
`;

const UserDetails = styled.div`
  padding-left: 20px;
`;

const ChevronRightContainer = styled.div`
  flex: 1;
  color: #888;
  font-size: 1.5em;
  svg {
    float: right;
  }
`;
const UserName = styled.span`
  display: block;
  font-weight: 600;
  font-size: 1.25rem;
  color: #222;
  line-height: 1.25;
`;

const UserRole = styled.span`
  display: block;

  font-size: 0.95rem;
  color: #777;
  line-height: 1.15;
`;

const LinksSection = styled.div`
  h4 {
    font-weight: 600;
    font-size: 1em;
  }
  ul {
    margin-left: -15px;
    margin-right: -15px;
    background: white;
    padding: 0;

    li {
      a {
        padding: 0.75em 30px;
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

const generalLinks = [
  {
    id: 0,
    title: 'My Account',
    link: '/general/account',
  },
  {
    id: 1,
    title: 'Notifications',
    link: '/general/notifications',
  },
  {
    id: 2,
    title: 'Users',
    link: '/general/users',
    requiredAction: 7,
  },
  {
    id: 3,
    title: 'Roles',
    link: '/general/roles',
    requiredAction: 7,
  },
];

const inventoryLinks = [
  {
    id: 0,
    title: 'Brands',
    link: '/inventory/brands',
  },
  {
    id: 1,
    title: 'Colors',
    link: '/inventory/colors',
  },
  // {
  //     title: 'Price Groups',
  //     link: '/inventory/pricegroups'
  // },
  {
    title: 'Locations',
    link: '/inventory/locations',
  },
  // {
  //     title: 'Status',
  //     link: '/inventory/statuses'
  // },
  // {
  //     title: 'Thickness',
  //     link: '/inventory/thicknesses'
  // },
  // {
  //     title: 'Types',
  //     link: '/inventory/types'
  // }
];

const MobileMore = ({
  user: { name, role: { name: roleName } = { name: '' } },
}) => {
  const url = '/settings';

  return (
    <>
      <LoggedInUserInfo>
        <Link to="settings/general/account">
          <Container>
            <UserIcon name={name} />
            <UserDetails>
              <UserName>{name}</UserName>
              <UserRole>{roleName}</UserRole>
            </UserDetails>
            <ChevronRightContainer>
              <ChevronRight size={24} />
            </ChevronRightContainer>
          </Container>
        </Link>
      </LoggedInUserInfo>

      <Container>
        <LinksSection>
          <h4>General</h4>
          <AdminSidebarUl>
            {generalLinks.map(({ id, title, link, requiredAction }) => (
              <Protected requiredAction={requiredAction} key={id}>
                <AdminSidebarLl>
                  <Link to={`${url}${link}`}>{title}</Link>
                </AdminSidebarLl>
              </Protected>
            ))}
          </AdminSidebarUl>
        </LinksSection>
        <LinksSection>
          <h4>Inventory</h4>
          <AdminSidebarUl>
            {inventoryLinks.map(({ id, title, link }) => (
              <AdminSidebarLl key={id}>
                <Link to={`${url}${link}`}>{title}</Link>
              </AdminSidebarLl>
            ))}
          </AdminSidebarUl>
        </LinksSection>
      </Container>
    </>
  );
};

export default MainLayout(UserConsumer(MobileMore));
