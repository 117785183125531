/* eslint-disable import/prefer-default-export */
const filterArrayForSelectedValues = (
  previousSelections,
  checkedItem,
  checked,
) => {
  const inList = previousSelections.filter(
    ({ value, id }) => (value || id) === (checkedItem.value || checkedItem.id),
  ).length > 0;
  if (!inList && checked) {
    previousSelections.push(checkedItem);
    return previousSelections;
  }
  return previousSelections.filter(
    ({ value, id }) => (value || id) !== (checkedItem.value || checkedItem.id),
  );
};


export { filterArrayForSelectedValues };
