import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Protected from '../../../components/protected';
import HeaderBar from '../../../atoms/HeaderBar';
import { ChevronDown, X } from 'react-feather';

const generalLinks = [
  {
    id: 0,
    title: 'My Account',
    link: '/general/account',
  },
  {
    id: 1,
    title: 'Notifications',
    link: '/general/notifications',
  },
  {
    id: 2,
    title: 'Users',
    link: '/general/users',
    requiredAction: 7,
  },
  {
    id: 3,
    title: 'Roles',
    link: '/general/roles',
    requiredAction: 7,
  },
];

const inventoryLinks = [
  {
    id: 0,
    title: 'Brands',
    link: '/inventory/brands',
  },
  {
    id: 1,
    title: 'Colors',
    link: '/inventory/colors',
  },
  {
    id: 6,
    title: 'Pricing',
    link: '/inventory/pricing',
  },
  // {
  //     title: 'Price Groups',
  //     link: '/inventory/pricegroups'
  // },
  {
    id: 2,
    title: 'Locations',
    link: '/inventory/locations',
  },

  // {
  //     title: 'Status',
  //     link: '/inventory/statuses'
  // },
  // {
  //     title: 'Thickness',
  //     link: '/inventory/thicknesses'
  // },
  // {
  //     title: 'Types',
  //     link: '/inventory/types'
  // }
];

export default (WrappedComponent, PageTitle) => {
  const url = '/settings';
  return (props) => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);

    return (
      <>
        <div className="hidden md:block">
          <HeaderBar heading={PageTitle} />
        </div>

        <div
          className="block md:hidden bg-white shadow-soft top-0 z-10 sticky"
          onClick={() => setIsMenuExpanded(!isMenuExpanded)}
        >
          <div className="container border-t border-gray-100 py-3 px-3 md:px-0 md:py-4 ">
            <div className="flex justify-between">
              <span className="text-lg md:text-xl font-semibold mb-0 inherit block">
                {PageTitle}
                <span className="font-normal text-sm text-gray-600 block">
                  Tap to expand more settings
                </span>
              </span>
              <span>
                {' '}
                <ChevronDown
                  className={
                    isMenuExpanded
                      ? 'rotate-animate-svg chevron-open'
                      : 'rotate-animate-svg chevron-closed'
                  }
                />
              </span>
            </div>
          </div>

          {isMenuExpanded ? (
            <div className="p-4 border-t container border-gray-200">
              <ul>
                {generalLinks.map(({ id, title, link, requiredAction }) => (
                  <Protected requiredAction={requiredAction} key={id}>
                    <li>
                      <NavLink
                        className="py-2 block text-gray-800 hover:text-blue-600"
                        activeClassName="text-blue-600"
                        to={`${url}${link}`}
                      >
                        {title}
                      </NavLink>
                    </li>
                  </Protected>
                ))}
              </ul>

              <Protected requiredAction={5}>
                <span className="text-gray-600 block font-semibold uppercase leading-loose tracking-loose mt-4 text-xs">
                  Inventory
                </span>
                <ul>
                  {inventoryLinks.map(({ id, title, link }) => (
                    <li key={id}>
                      <NavLink
                        className="py-2 block text-gray-800 hover:text-blue-600"
                        activeClassName="text-blue-600"
                        to={`${url}${link}`}
                      >
                        {title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Protected>
            </div>
          ) : null}
        </div>

        <div className="container rounded mx-auto flex flex-wrap my-8">
          <div className="hidden md:block md:w-1/5 px-4">
            <ul>
              {generalLinks.map(({ id, title, link, requiredAction }) => (
                <Protected requiredAction={requiredAction} key={id}>
                  <li>
                    <NavLink
                      className="py-2 block text-gray-800 hover:text-blue-600"
                      activeClassName="text-blue-600"
                      to={`${url}${link}`}
                    >
                      {title}
                    </NavLink>
                  </li>
                </Protected>
              ))}
            </ul>

            <Protected requiredAction={5}>
              <span className="text-gray-600 block font-semibold uppercase leading-loose tracking-loose mt-4 text-xs">
                Inventory
              </span>
              <ul>
                {inventoryLinks.map(({ id, title, link }) => (
                  <li key={id}>
                    <NavLink
                      className="py-2 block text-gray-800 hover:text-blue-600"
                      activeClassName="text-blue-600"
                      to={`${url}${link}`}
                    >
                      {title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Protected>
          </div>
          <div className="w-full md:w-4/5 px-4">
            <WrappedComponent {...props} />
          </div>
        </div>
      </>
    );
  };
};
