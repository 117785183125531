import styled from 'styled-components';

const TotalPrice = styled.p`
  color: #666;
  margin-top: -10px;
  margin-bottom: 1rem;
  padding-left: 15px;
`;

export default TotalPrice;
