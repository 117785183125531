import React from 'react';

import { MessageCircle } from 'react-feather';
import { SnackBar } from '../../atoms/Snackbar';
import { ModalConsumer } from '../../context/modal';

const ReportBug = ({ client }) => false && (
<SnackBar color="#fff">
  <p style={{ margin: '0' }}>
    <MessageCircle />
    {' Report Bug'}
  </p>
</SnackBar>
);

export default ModalConsumer(ReportBug);
