import React from 'react';

import { CheckCircle, Check } from 'react-feather';
import { Link, NavLink, Redirect } from 'react-router-dom';
import QRCode from 'qrcode.react';
import {
  Container, Button, Row, Col,
} from 'reactstrap';
import styled from 'styled-components';
import MainLayout from '../../layout/main';

import { PageLayout } from '../../components/PageLayout';

const Success = ({
  history: {
    location: { state: { id } = {} },
  },
}) => (id && (
<PageLayout>
<div className="max-w-2xl shadow-soft bg-white ml-auto mr-auto rounded px-2 py-12 text-center" >
<div className="bg-green-500 text-white w-12 h-12 rounded-full ml-auto mr-auto my-4 flex items-center justify-center"><Check size={30}/></div>
{/* <h1 className="font-semibold text-2xl text-center">Material Added</h1> */}
<span className="block uppercase tracking-wider text-gray-800 text-sm font-bold mb-1 text-center mt-4">New Material ID</span>
<span className="font-semibold text-3xl text-center block">#{id.padStart(5, 0)}</span>

<Link to={`/${id.padStart(5, 0)}`} className="mt-6 inline-block border bg-white hover:bg-gray-100 text-gray-800 text-base text-decoration-none focus:outline-none appearance-none font-medium text-sm py-2 px-4 rounded">
View Item
            </Link>


<div className="bg-gray-100 rounded max-w-md mx-auto mt-6 p-4 flex items-center">
  <div className="w-1/4">
  <QRCode
              size={80}
              renderAs={'svg'}
              value={
                    `https://project-snow.netlify.com/${id.padStart(5, 0)}`
                  }
              level="H"
            />
  </div>
  <div className="w-3/4 text-left">
  <span className="block text-gray-700 text-base">Scan with your camera to quickly access remnant item.</span>
  </div>

</div>

  
</div>
</PageLayout>
)) || <Redirect push to={{ pathname: '/add' }} />;


export default MainLayout(Success);
