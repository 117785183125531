import React from 'react';
import styled from 'styled-components';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const OuterContainer = styled.div`
  text-align: center;
  padding: 3em 1em;
`;
const Heading = styled.h3`
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 0.15em;
`;
const Description = styled.p`
  color: #888;
`;
export default ({
  title, description, actionLocation, actionText,
}) => (
  <OuterContainer>
    <Heading>{title}</Heading>
    <Description>{description}</Description>
    {actionLocation ? (
      <Button tag={Link} to={actionLocation} color="default">
        {actionText}
      </Button>
    ) : null}
  </OuterContainer>
);
