/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, FormGroup, Label, Input, Button } from 'reactstrap';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';

import { allLocations } from '../../../../../graphql/queries/allLocations.gql';
import { updateLocation as updateLocationMutation } from '../../../../../graphql/mutations/updateLocation.gql';

const Update = ({ actions: { toggleModal }, id, name, update }) => {
  const initialValues = {
    name,
  };

  // eslint-disable-next-line no-shadow
  const onSubmit = async ({ name }, { setSubmitting, setErrors }) => {
    await update({
      variables: {
        id,
        name,
      },
      refetchQueries: [
        {
          query: allLocations,
        },
      ],
      update: (
        store,
        {
          data: {
            updateLocation: { payload },
          },
        },
      ) => {
        const data = store.readQuery({ query: allLocations });
        const newData = {
          allLocations: data.allLocations.map(
            // eslint-disable-next-line no-underscore-dangle
            (item) =>
              item.id === id
                ? { ...payload, __typename: item.__typename }
                : item,
          ),
        };
        store.writeQuery({ query: allLocations, data: newData });
      },
    })
      .then(
        ({
          data: {
            updateLocation: { ok, errors },
          },
        }) => {
          if (!ok) {
            const errs = {};
            // eslint-disable-next-line no-return-assign
            errors.map(({ path, message }) => (errs[path] = message));
            setErrors(errs);
          } else {
            toggleModal();
          }
        },
      )
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    } else if (values.name === name) {
      errors.name = 'Enter a new name';
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Alert color="danger" isOpen={!!errors.name}>
              {errors.name && touched.name && errors.name}
            </Alert>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
            Update
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(updateLocationMutation, { name: 'update' })(Update);
