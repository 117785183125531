import React from 'react';
import styled from 'styled-components';
import { AlertTriangle, Check } from 'react-feather';
import { error, success, warning } from '../../views/index.scss';

export default ({ type: alertStyle, children }) => {
  let color = '#333';
  let icon = <AlertTriangle />;

  if (alertStyle === 'error') {
    color = error;
    icon = <AlertTriangle />;
  }
  if (alertStyle === 'success') {
    color = success;
    icon = <Check />;
  }
  if (alertStyle === 'warning') {
    color = warning;
    icon = <AlertTriangle />;
  }

  const AlertContainer = styled.div`
    border: 1px solid ${color};
    color: ${color};
    font-weight: 400;
  `;
  const AlertIcon = styled.div`
    display: inline-block;
    margin-right: 5px;
  `;

  const AlertContent = styled.div`
    display: inline-block;
  `;

  return (
    <AlertContainer className="rounded-lg bg-white flex items-center p-4">
      <AlertIcon>{icon}</AlertIcon>
      <AlertContent>{children}</AlertContent>
    </AlertContainer>
  );
};
