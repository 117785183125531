/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';
import { Button } from 'reactstrap';
import Confirm from '../confirm';

import { ModalConsumer } from '../../context/modal';

import { Update } from '../../views/settings/inventory/colors/manage';
import { allColors } from '../../graphql/queries/allColors.gql';
import { removeColor as removeColorMutation } from '../../graphql/mutations/removeColor.gql';

const Color = ({
  id,
  name,
  brand: { id: brandId, name: brandName },
  toggleModal,
  removeColor,
}) => {
  const onUpdateClick = async () => {
    toggleModal({
      options: { title: 'Update Color' },
      props: { id, name, brand: { id: brandId, name: brandName } },
    })(Update);
  };
  const onRemoveClick = async () => {
    const remove = () => {
      removeColor({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: allColors,
          },
        ],
        update: (
          store,
          {
            data: {
              removeColor: { ok },
            },
          },
        ) => {
          if (!ok) return false;
          try {
            const data = store.readQuery({ query: allColors });
            const newData = {
              allColors: data.allColors.filter(
                ({ id: colorId }) => colorId !== id,
              ),
            };
            store.writeQuery({ query: allColors, data: newData });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e, 'eee');
          }
        },
      });
      toggleModal();
    };
    toggleModal({
      options: { title: 'Confirm' },
      actions: { action: remove },
      props: { text: `remove color: ${name}` },
    })(Confirm);
  };
  return (
    <tr key={id}>
      <td className="border px-4 py-2">{id}</td>
      <td className="border px-4 py-2">{name}</td>
      <td className="border px-4 py-2">{brandName}</td>

      <td className="border px-4 py-2">
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-gray-100 focus:outline-none border mr-2"
          onClick={onUpdateClick}
          size="sm"
        >
          Update
        </button>
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
          onClick={onRemoveClick}
          size="sm"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};
export default graphql(removeColorMutation, { name: 'removeColor' })(
  ModalConsumer(Color),
);
