import React from 'react';
import { Link, NavLink as NavLinkRR } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { Info as InfoIcon, CheckCircle as AvailableIcon } from 'react-feather';
import { Container, Row, Col, Button } from 'reactstrap';
import styled, { css } from 'styled-components';
import MainLayout from '../../layout/main';
import { success, warning } from '../index.scss';
import { Check, AlertCircle, ChevronDown } from 'react-feather';
import UserTokenInfo from '../../context/token';
import { Card } from '../../atoms/Card';
import Material from '../../components/material';
import { ActivityItem } from '../../components/activity';
import { PageLayout } from '../../components/PageLayout';
import HeaderBar from '../../atoms/HeaderBar';

import { dashboardInfo as dashboardInfoQuery } from '../../graphql/queries/dashboardInfo.gql';

const Section = styled.div`
  background-color: #fff;
  padding: 1em;
  border-radius: 0.3125em;
`;

const SectionLabel = styled.h1`
  color: ${(props) => props.color || '#242424'};
  font-size: 2em;
  font-weight: bold;
`;
const SectionDescriptor = styled.p`
  color: #868686;
  font-size: 1.125em;
`;

const MaterialLabel = styled.h4`
  color: #717171;
  font-size: 1.25em;
  margin-bottom: 0;
`;
const MaterialAmount = styled.div`
  font-size: 2.0625em;
  font-weight: bold;
  margin-bottom: 0.3em;
`;

const ActivityStatusList = styled.div``;
const StatusLabel = styled.p`
  font-size: 0.8125em;
  margin-bottom: 0.5em;
`;
const AvailableLabel = styled(StatusLabel)`
  svg {
    color: ${success};
  }
`;
const WarningLabel = styled(StatusLabel)`
  svg {
    color: ${warning};
  }
`;

const StatusBars = styled.div`
  align-self: stretch;
`;
const statusBarStyles = css`
  width: 25px;
`;
const ReservedBar = styled.div`
  ${statusBarStyles}
  background-color: #FF8A41;
`;
const AvailableBar = styled.div`
  ${statusBarStyles}
  background-color: #2BCB31;
`;

const Dashboard = ({
  id,
  data: {
    loading,
    error,
    dashboard: { remnants: remnantsCount, fullslabs: fullslabsCount } = {
      remnants: {
        availableCount: 0,
        reservedCount: 0,
      },
      fullslabs: {
        availableCount: 0,
        reservedCount: 0,
      },
    },
    getUser,
    allMaterials = [],
    allReservations = [],
    remnants,
    fullSlabs,
    getReservationsByUser = [],
  },
  user: { name },
}) => {
  if (error) return <h1>{error.toString()}</h1>;

  const firstName = name.split(' ')[0];
  const availableRemnants = remnantsCount.availableCount;
  const reservedRemnants = remnantsCount.reservedCount;
  const availableFullSlabs = fullslabsCount.availableCount;
  const reservedFullSlabs = fullslabsCount.reservedCount;

  const percentageBaseRemants = availableRemnants + reservedRemnants;
  const percentageBaseFullSlabs = availableFullSlabs + reservedFullSlabs;
  const uniqueReservation = [];

  return (
    <>
      <PageLayout>
        <HeaderBar
          removePageLayoutDefault
          sticky
          heading={`Welcome Back, ${firstName}`}
        >
          <div className="justify-end self-center flex">
            <Link
              to="/add"
              className="text-base text-decoration-none focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm py-2 px-4 rounded"
            >
              Add new
            </Link>
          </div>
        </HeaderBar>

        <Container>
          <div className="flex flex-wrap">
            <div className="w-full md:w-2/3">
              <div className="p-8 bg-white md:shadow-soft rounded">
                <span className="text-blue-500 font-semibold text-lg md:text-2xl block">
                  Inventory Overview
                </span>
                <span className="text-gray-700 text-base block">
                  Available &amp; Reserved Items
                </span>

                <div className="flex flex-wrap my-4">
                  <div className="w-full md:w-1/2 pr-2">
                    <div className="rounded-lg overflow-hidden">
                      <div className="px-4 py-4 bg-gray-200">
                        <h3 className="text-gray-800 uppercase tracking-wide text-base text-center font-semibold my-0">
                          Remnants ({remnantsCount.totalCount})
                        </h3>
                      </div>

                      <div className="bg-gray-100 rounded">
                        {/* <span className="text-4xl font-semibold ">{remnantsCount.totalCount}</span> */}
                        <div className="mb-4 p-4">
                          <div className="pr-4 text-center">
                            <div className="flex items-center justify-center my-4">
                              <div className="bg-green-500 text-white rounded-full p-1 mr-2  w-6 h-6 flex items-center border-2 border-white">
                                {' '}
                                <Check />
                              </div>
                              <span className="text-2xl font-semibold text-black">
                                {availableRemnants}
                                <span className="text-gray-800 text-lg ml-1">
                                  Available
                                </span>
                              </span>
                            </div>
                            <div className="flex items-center justify-center my-4">
                              <div className="bg-orange-500 text-white rounded-full mr-2 w-6 h-6 flex items-center">
                                {' '}
                                <AlertCircle />
                              </div>
                              <span className="text-2xl font-semibold text-black">
                                {reservedRemnants}
                                <span className="text-gray-800 text-lg ml-1">
                                  Reserved
                                </span>
                              </span>
                            </div>
                            <NavLinkRR
                              to={'/remnants'}
                              className="text-gray-800 font-medium block w-full bg-white rounded-lg text-center p-3 hover:bg-gray-200"
                            >
                              View Remnant Inventory
                            </NavLinkRR>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 pr-2">
                    <div className="rounded-lg overflow-hidden">
                      <div className="px-4 py-4 bg-gray-200">
                        <h3 className="text-gray-800 uppercase tracking-wide text-base text-center font-semibold my-0">
                          Full Slabs ({fullslabsCount.totalCount})
                        </h3>
                      </div>

                      <div className="bg-gray-100 rounded">
                        {/* <span className="text-4xl font-semibold ">{remnantsCount.totalCount}</span> */}
                        <div className="mb-4 p-4">
                          <div className="pr-4 text-center">
                            <div className="flex items-center justify-center my-4">
                              <div className="bg-green-500 text-white rounded-full p-1 mr-2  w-6 h-6 flex items-center border-2 border-white">
                                {' '}
                                <Check />
                              </div>
                              <span className="text-2xl font-semibold text-black">
                                {availableFullSlabs}
                                <span className="text-gray-800 text-lg ml-1">
                                  Available
                                </span>
                              </span>
                            </div>
                            <div className="flex items-center justify-center my-4">
                              <div className="bg-orange-500 text-white rounded-full mr-2 w-6 h-6 flex items-center">
                                {' '}
                                <AlertCircle />
                              </div>
                              <span className="text-2xl font-semibold text-black">
                                {reservedFullSlabs}
                                <span className="text-gray-800 text-lg ml-1">
                                  Reserved
                                </span>
                              </span>
                            </div>
                            <NavLinkRR
                              to={'/fullslabs'}
                              className="text-gray-800 font-medium block w-full bg-white rounded-lg text-center p-3 hover:bg-gray-200"
                            >
                              View Full Slab Inventory
                            </NavLinkRR>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 my-4 md:my-0 md:pl-3">
              <div className="p-8 bg-white md:shadow-soft rounded">
                <span className="font-semibold text-lg md:text-2xl block">
                  Activity
                </span>
                {allReservations.map((reservation) => (
                  <ActivityItem key={reservation.id} {...reservation} />
                ))}
              </div>
            </div>
          </div>

          <div className="py-2 px-4 md:px-0 mt-6">
            <h2 className="font-semibold text-2xl mb-2">My Reservations</h2>
            {getReservationsByUser.map(
              ({
                id,
                ponumber,
                createdAt,
                comment,
                status: { id: statusId },
                material,
              }) => {
                if (!uniqueReservation[material.id]) {
                  uniqueReservation[material.id] = true;
                  return (
                    <React.Fragment key={id}>
                      <Material
                        {...material}
                        hasReservationDetails={true}
                        poNumber={ponumber}
                        comment={comment}
                        createdAt={createdAt}
                      />
                    </React.Fragment>
                  );
                }
                return null;
              },
            )}
          </div>

          <div className="py-2 px-4 md:px-0 md:mt-0 mt-4">
            <h2 className="font-semibold text-2xl">My Reservations</h2>
            {getReservationsByUser.map(
              ({
                id,
                ponumber,
                createdAt,
                comment,
                status: { id: statusId },
                material,
              }) => {
                if (!uniqueReservation[material.id]) {
                  uniqueReservation[material.id] = true;
                  return (
                    <React.Fragment key={id}>
                      <Material
                        {...material}
                        hasReservationDetails={true}
                        poNumber={ponumber}
                        comment={comment}
                        createdAt={createdAt}
                      />
                    </React.Fragment>
                  );
                }
                return null;
              },
            )}
          </div>
        </Container>
      </PageLayout>
    </>
  );
};

export default UserTokenInfo(
  MainLayout(
    graphql(dashboardInfoQuery, {
      options: ({ user: { id } }) => ({
        variables: {
          id,
          first: 4,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      }),
    })(Dashboard),
  ),
);
