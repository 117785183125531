/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from 'reactstrap';
import { Card } from '../../atoms/Card';
import ActivityItem from './item';

const Activity = ({ activity }) => (
  <div>
      {activity.length ? (
        activity.map(item => <ActivityItem key={item.id} {...item} />)
      ) : (
        <p>No Activity</p>
      )}
   </div>
);

export default Activity;
export { ActivityItem };
