import React from 'react';

import UserTokenInfo from '../context/token';

import Header from '../components/header';
import Footer from '../components/footer';

function MainLayout(WrappedComponent) {
  const ComponentWrappedWithUserToken = UserTokenInfo(WrappedComponent);
  const Layout = (props) => (
    <>
      <Header />
      <ComponentWrappedWithUserToken {...props} />
      <Footer />
    </>
  );

  return UserTokenInfo(Layout);
}
export default MainLayout;
