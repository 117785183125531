/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Alert, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';

import { allThicknesses } from '../../../../../graphql/queries/allThicknesses.gql';
import { updateThickness as updateThicknessMutation } from '../../../../../graphql/mutations/updateThickness.gql';

const Update = ({
  actions: { toggleModal }, id, thickness, update,
}) => {
  const initialValues = {
    thickness,
  };

  const onSubmit = async ({ thickness }, { setSubmitting, setErrors }) => {
    await update({
      variables: {
        id,
        thickness: parseFloat(thickness),
      },
      refetchQueries: [
        {
          query: allThicknesses,
        },
      ],
      update: (store, { data: { updateThickness: { payload } } }) => {
        const data = store.readQuery({ query: allThicknesses });
        const newData = {
          allThicknesses: data.allThicknesses.map(
            // eslint-disable-next-line no-underscore-dangle
            item => (item.id === id ? { ...payload, __typename: item.__typename } : item),
          ),
        };
        store.writeQuery({ query: allThicknesses, data: newData });
      },
    }).then(({ data: { updateThickness: { ok, errors } } }) => {
      if (!ok) {
        const errs = {};
        errors.map(
          // eslint-disable-next-line no-return-assign
          ({ path, message }) => errs[path] = message,
        );
        setErrors(errs);
      } else {
        toggleModal();
      }
    }).catch(e => console.log(e));
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.thickness) {
      errors.thickness = 'Required';
    } else if (!/^[0-9]+$/i.test(values.thickness)) {
      errors.thickness = 'Invalid thickness. Numbers allowed only.';
    } else if (values.thickness === thickness) {
      errors.thickness = 'Enter a new thickness';
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="thickness">Name</Label>
            <Input
              id="thickness"
              placeholder="Name"
              type="text"
              thickness="thickness"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.thickness}
            />
            <Alert color="danger" isOpen={!!errors.thickness}>{errors.thickness && touched.thickness && errors.thickness}</Alert>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
                                Update
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(updateThicknessMutation, { name: 'update' })(Update);
