/* eslint-disable react/prop-types */
import React from 'react';
import {
  Alert, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';

import { allBrands } from '../../../../../graphql/queries/allBrands.gql';
import { updateBrand as updateBrandMutation } from '../../../../../graphql/mutations/updateBrand.gql';

const Update = ({
  actions: { toggleModal }, id, name, update,
}) => {
  const initialValues = {
    name,
  };

  // eslint-disable-next-line no-shadow
  const onSubmit = async ({ name }, { setSubmitting, setErrors }) => {
    await update({
      variables: {
        id,
        name,
      },
      refetchQueries: [
        {
          query: allBrands,
        },
      ],
      update: (
        store,
        {
          data: {
            updateBrand: { payload },
          },
        },
      ) => {
        const data = store.readQuery({ query: allBrands });
        const newData = {
          // eslint-disable-next-line no-underscore-dangle
          allBrands: data.allBrands.map(
            // eslint-disable-next-line no-underscore-dangle
            item => (item.id === id ? { ...payload, __typename: item.__typename } : item),
          ),
        };
        store.writeQuery({ query: allBrands, data: newData });
      },
    })
      .then(({ data: { updateBrand: { ok, errors } } }) => {
        if (!ok) {
          const errs = {};
          // eslint-disable-next-line no-return-assign
          errors.map(({ path, message }) => (errs[path] = message));
          setErrors(errs);
        } else {
          toggleModal();
        }
      })
      // eslint-disable-next-line no-console
      .catch(e => console.log(e));
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    } else if (!/^[A-Z\s]+$/i.test(values.name)) {
      errors.name = 'Invalid name. Letters allowed only.';
    } else if (values.name === name) {
      errors.name = 'Enter a new name';
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Alert color="danger" isOpen={!!errors.name}>
              {errors.name && touched.name && errors.name}
            </Alert>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
            Update
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default graphql(updateBrandMutation, { name: 'update' })(Update);
