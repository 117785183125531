import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isAuthenticated from '../auth';
import Protected from '../components/protected';

export default ({ component: Component, ...rest }) => (
  <Protected
    {...rest}
    alternateComponent={() => {
      if (isAuthenticated()) {
        return <Redirect push to={{ pathname: '/forbidden' }} />;
      }
      return (
        <Redirect
          push
          to={{
            pathname: '/login',
            search: `?redirect=${encodeURI(
              `${rest.location.pathname + rest.location.search}`,
            )}`,
            state: {
              redirect: encodeURI(
                `${rest.location.pathname + rest.location.search}`,
              ),
            },
          }}
        />
      );
    }}
    private
  >
    <Route {...rest} component={Component} />
  </Protected>
);
