/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ModalConsumer } from '../../../../context/modal';
import MainLayout from '../../../../layout/main';
import { Card } from '../../../../atoms/Card';
import SideBar from '../../../../layout/settings/sidebar';
import { allColors as allColorsQuery } from '../../../../graphql/queries/allColors.gql';
import apolloClient from '../../../../apolloClient';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Spinner from '../../../../atoms/spinner';

const withPricingMutation = gql`
  mutation updateColor(
    $id: ID!
    $name: String
    $brandId: ID
    $onecmprice: Float
    $twocmprice: Float
    $threecmprice: Float
  ) {
    updateColor(
      id: $id
      name: $name
      brandId: $brandId
      onecmprice: $onecmprice
      twocmprice: $twocmprice
      threecmprice: $threecmprice
    ) {
      ok
      errors {
        path
        message
      }
      payload {
        id
        name
        brand {
          id
          name
        }
      }
    }
  }
`;

const Pricing = ({ data: { allColors = [] } }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [savingMessage, setSavingMessage] = useState('');

  const handleOnClick = (e, i) => {
    setIsSaving(true);
    setSavingMessage('Saving...');
    const update = {};
    update[e.target.name] = parseFloat(e.target.value);
    const colorId = e.target.getAttribute('data-color-id');
    apolloClient
      .mutate({
        mutation: withPricingMutation,
        variables: {
          id: colorId,
          ...update,
        },
      })
      .then((result) => {
        if (result.data.updateColor.ok) {
          setSavingMessage(
            'Saved ' +
              new Date(result.extensions.tracing.endTime).toLocaleTimeString(),
          );
          setIsSaving(false);
        }
      });
  };

  return (
    <Card>
      <div className="flex justify-between items-center -my-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Pricing
        </h2>
        <div>
          {isSaving || !allColors ? (
            <div className="w-4 text-gray-700">
              <Spinner />
            </div>
          ) : (
            <span className="block text-xs text-gray-600">{savingMessage}</span>
          )}
        </div>
      </div>

      {allColors.length > 0 ? (
        <table className="table-auto text-left py-2 block text-sm md:text-base text-left">
          <thead>
            <tr>
              <th className="py-2">Brand</th>
              <th className="py-2">Color</th>
              <th className="py-2">1CM Price</th>
              <th className="py-2">2CM Price</th>
              <th className="py-2">3CM Price</th>
            </tr>
          </thead>
          <tbody>
            {allColors.map(
              (
                { id, name, brand, onecmprice, twocmprice, threecmprice },
                i,
              ) => (
                <tr className={i % 2 == 0 ? '' : 'bg-gray-100'} key={id}>
                  <td className="border px-4 py-2">{brand.name}</td>
                  <td className="border px-4 py-2">{name}</td>
                  <td className="border px-2 md:px-4 py-2">
                    <div className="w-auto flex relative">
                      <span className="hidden md:flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">
                        $
                      </span>
                      <input
                        name="onecmprice"
                        data-color-id={id}
                        onFocus={(e) => e.target.select()}
                        onBlur={handleOnClick}
                        type="number"
                        defaultValue={`${onecmprice ? onecmprice : 0}`}
                        className="w-full rounded md:rounded-l-none focus:outline-none focus:bg-gray-100  leading-normal border h-10 border-grey-light px-2 md:px-3 relative"
                      />
                    </div>
                  </td>
                  <td className="border px-2 md:px-4 py-2">
                    <div className="w-auto flex relative">
                      <span className="hidden md:flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">
                        $
                      </span>
                      <input
                        name="twocmprice"
                        data-color-id={id}
                        onFocus={(e) => e.target.select()}
                        onBlur={handleOnClick}
                        type="number"
                        defaultValue={`${twocmprice ? twocmprice : 0}`}
                        className="w-full rounded md:rounded-l-none focus:outline-none focus:bg-gray-100  leading-normal border h-10 border-grey-light px-2 md:px-3 relative"
                      />
                    </div>
                  </td>
                  <td className="border px-2 md:px-4 py-2">
                    <div className="w-auto flex relative">
                      <span className="hidden md:flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">
                        $
                      </span>
                      <input
                        name="threecmprice"
                        data-color-id={id}
                        onFocus={(e) => e.target.select()}
                        onBlur={handleOnClick}
                        type="number"
                        defaultValue={`${threecmprice ? threecmprice : 0}`}
                        className="w-full rounded md:rounded-l-none focus:outline-none focus:bg-gray-100  leading-normal border h-10 border-grey-light px-2 md:px-3 relative"
                      />
                    </div>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : null}
    </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allColorsQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(Pricing)),
    'Inventory Pricing',
  ),
);
