import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import { ApolloProvider as ProviderApollo } from 'react-apollo';
import {
  ApolloNetworkStatusProvider,
  useApolloNetworkStatus
} from 'react-apollo-network-status';

import client from '../apolloClient';

import ReportBug from '../components/reportbug';
import NetworkStatus from '../components/networkStatus';
import DevServer from '../components/devserver';
import Home, {
  Welcome,
  Material,
  EditMaterial,
  AddMaterial,
  MaterialLabel,
  PrintMaterial,
  Success,
  Deleted,
  Login,
  Dashboard,
  Search,
  Forbidden,
  NoMatch,
  MobileMore,
} from '../views';
import ProtectedRoute from './protectedroute';
import AuthenticationHandler from '../components/authenticationhandler';
import ScrollToTop from '../components/scrolltotop';
import Settings from './settings';

import { NProgress } from '../components/nprogress';

const history = createBrowserHistory();

const NetworkStatusWrapper = withRouter(({ render, history }) => {
  const status = useApolloNetworkStatus();
  return render(status)
})

export default () => {
  return (
    <Router history={history}>
      <ProviderApollo client={client}>
        <ApolloNetworkStatusProvider>
          <NetworkStatusWrapper render={status => (
            <ScrollToTop>
              {/* <GlobalLoadingIndicator /> */}
              <NProgress isAnimating={status.numPendingQueries > 0} />
              <Switch>
                <ProtectedRoute exact path="/" component={Home} requiredAction={6} />
                <ProtectedRoute exact path="/welcome" component={Welcome} />
                <ProtectedRoute
                  exact
                  sensitive
                  path="/:id(\d{5,})"
                  component={Material}
                />
                <ProtectedRoute
                  exact
                  sensitive
                  path="/:id(\d{5,})/edit"
                  component={EditMaterial}
                />
                <ProtectedRoute
                  exact
                  sensitive
                  path="/material-label/:id(\d{5,})"
                  component={MaterialLabel}
                />
                <ProtectedRoute
                  exact
                  sensitive
                  path="/print/:id(\d{5,})"
                  component={PrintMaterial}
                />
                <ProtectedRoute exact sensitive path="/add" component={AddMaterial} />
                <ProtectedRoute exact sensitive path="/success" component={Success} />
                <ProtectedRoute exact sensitive path="/deleted" component={Deleted} />
                <ProtectedRoute exact sensitive path="/remnants" component={Home} />
                <ProtectedRoute exact sensitive path="/fullslabs" component={Home} />
                <ProtectedRoute
                  exact
                  sensitive
                  path="/add-new"
                  component={AddMaterial}
                />
                <ProtectedRoute exact sensitive path="/search" component={Search} />
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute path="/settings" component={Settings} />
                <ProtectedRoute path="/settings-menu" component={MobileMore} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/forbidden" component={Forbidden} />
                <Route component={NoMatch} />
              </Switch>
              <AuthenticationHandler />
              <ReportBug client={client} />
              <NetworkStatus />
              <DevServer />
            </ScrollToTop>
          )} />
        </ApolloNetworkStatusProvider>
      </ProviderApollo>
    </Router>
  );
};

export { history };
