import React from 'react';
import { graphql } from 'react-apollo';
// import { Helmet } from 'react-helmet';

import { Button } from 'reactstrap';
import { getUser } from '../../../../graphql/queries/getUser.gql';

import { Update } from '../users/manage';
import UserConsumer from '../../../../context/token';
import { ModalConsumer } from '../../../../context/modal';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { Card } from '../../../../atoms/Card';

const MyAccount = ({
  data: {
    getUser: {
      id, name, username, email, locked, role,
    } = {
      name: '',
      username: '',
      email: '',
      firstTime: false,
      active: false,
      locked: true,
    },
  },
  toggleModal,
}) => {
  const onClickUpdate = async () => {
    toggleModal({
      options: { title: 'Update My Info' },
      props: {
        id,
        name,
        username,
        email,
        role,
        locked,
      },
    })(Update);
  };
  return (
    <Card title="My Account">
      <table className="table table-settings">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{name}</td>
          </tr>

          <tr>
            <td>Username</td>
            <td>{username}</td>
          </tr>

          <tr>
            <td>Email</td>
            <td>{email}</td>
          </tr>
          <tr>
            <td>Password</td>
            <td>***********</td>
          </tr>
          <tr>
            <td>
              <Button color="default" onClick={onClickUpdate}>
                Edit
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    UserConsumer(
      graphql(getUser, {
        options: ({ user: { id } }) => ({
          variables: { id },
          fetchPolicy: 'network-only',
        }),
      })(ModalConsumer(MyAccount)),
    ),
    'Account Settings',
  ),
);
