/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import Alert from '../../../atoms/Alert';
import Logo from '../../../components/logo';

import { Card } from '../../../atoms/Card';
import { ModalConsumer } from '../../../context/modal';
import User from '../../../context/token';

import { materialDetails as materialDetailsQuery } from '../../../graphql/queries/materialDetails.gql';

class PrintMaterial extends React.Component {
  // componentDidMount() {
  //   document.body.classList.add('bg-white');
  // }
  // componentDidUnmount() {
  //   document.body.classList.remove('bg-white');
  // }

  render() {
    const {
      props: {
        data: { error, loading, getMaterial },
      },
    } = this;
    if (loading) return null;
    if (error) return <Alert color="danger">{error.toString()}</Alert>;

    if (!getMaterial) return <Alert color="danger">No such item exists!</Alert>;
    const {
      id,
      width,
      height,
      price,
      color,
      location,
      type,
      reservations,
      status,
      thickness: { thickness },
    } = getMaterial;

    function numberWithCommas(number) {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const { brand } = color;
    console.log(reservations[0])
    return (
      <div role="main" className="container mx-auto max-w-3xl my-8" style={{ 'font-family': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
        <div className="flex justify-between mb-6">
          <div className="block w-40 mb-4">
            <Logo />
          </div>
          <div>
            <button onClick={() => window.history.back()} className="text-sm mr-2 text-decoration-none focus:outline-none appearance-none bg-gray-100 hover:bg-gray-300  text-gray-800 font-medium text-sm py-2 px-4 rounded print:hidden">
              Back to Material
          </button>
            <button onClick={() => window.print()} className="text-sm  text-decoration-none focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm py-2 px-4 rounded print:hidden">
              Print
          </button>
          </div>

        </div>
        <span className="block font-semibold text-sm mb-1 text-base">
          Inventory Item
        </span>
        <span className="text-xs text-gray-800 my-4 block">Do Not Pay</span>
        <div className="flex mb-4">
          <div className="w-1/2">
            <span className="block font-semibold text-sm mb-1">
              Item Number:
            </span>
            <span className="block text-sm text-gray-800">{`#${id.padStart(
              5,
              0,
            )}`}</span>
          </div>
          <div className="w-1/2">
            <span className="block font-semibold text-sm mb-1">
              Status
            </span>
            <span className="block text-sm text-gray-800">
              {status.id == 1 ? 'Available' : null}
              {status.id == 2 ? 'Reserved' : null}
              {status.id == 3 ? 'Sold' : null}
            </span>
          </div>
        </div>
        {status.id == 2 || status.id == 3 ? <>
          <div className="flex mb-4">
            <div className="w-1/2">
              <span className="block font-semibold text-sm mb-1">
                Reserved Date
            </span>
              <span className="block text-sm text-gray-800">


                {reservations[0] ? new Date(reservations[0].createdAt).toDateString() : 'none'}
              </span>
            </div>

            <div className="w-1/2">
              <span className="block font-semibold text-sm mb-1">
                Purchase Order
            </span>
              <span className="block text-sm text-gray-800">{reservations[0].ponumber}</span>
            </div>


          </div>
          <div className="w-1/2 mb-4 ">
            <span className="block font-semibold text-sm mb-1">Comments</span>
            <span className="block text-sm text-gray-800">
              <span className="italic text-gray-600">{reservations[0].comment ? reservations[0].comment : 'No comments'}</span>
            </span>
          </div></> : null}
        <span className="text-xs text-gray-800 m2-4 block">
          Inventory Type: {type.name}
        </span>
        <span className="block font-semibold text-sm mb-1 text-base my-4">
          Item Details
        </span>
        <table className="w-full my-6">
          <thead>
            <tr className="text-left text-sm border-b-2 border-gray-900">
              <th className="font-semibold">Brand</th>
              <th className="font-semibold">Color</th>
              <th className="font-semibold">Height</th>
              <th className="font-semibold">Width</th>
              <th className="font-semibold" width="80px">Thickness</th>
              <th className="font-semibold">Sq.Ft</th>
              <th className="font-semibold">Total Cost</th>
              <th className="font-semibold">Location</th>
            </tr>
          </thead>
          <tbody className="text-gray-800">
            <tr>
              <td>{brand.name}</td>
              <td>{color.name}</td>
              <td>{height}"</td>
              <td>{width}"</td>
              <td>{thickness}"</td>
              <td>{numberWithCommas((width / 12 * height / 12))}ft<sup>2</sup></td>
              <td>${(price * ((width / 12 * height / 12))).toFixed(2)}</td>
              <td>{location.name}</td>
            </tr>
          </tbody>
        </table>
        {/* <span className="block font-semibold text-sm mb-1 text-base my-4">View Item</span>
<QRCode
            height={'50px'}
            width={'50px'}
            renderAs={'svg'}
            value={`https://gqls.materialtrackr.com/${id.padStart(5, 0)}`}
            level="H"
              /> */}
      </div>
    );
  }
}

export default User(ModalConsumer(graphql(materialDetailsQuery, {
  options: ({
    match: {
      params: { id },
    },
  }) => ({ variables: { id }, notifyOnNetworkStatusChange: true }),
})(PrintMaterial)));
