/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import StatusBadge from '../../atoms/StatusBadge';
import testimg from '../../assets/123.jpg';

const {
  REACT_APP_PROTOCOL,
  REACT_APP_SERVER_ADDRESS,
  REACT_APP_SERVER_PORT,
} = process.env;
const uri = `${
  REACT_APP_SERVER_ADDRESS || REACT_APP_SERVER_PORT
    ? `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_ADDRESS}:${REACT_APP_SERVER_PORT}`
    : 'https://gqls.materialtrackr.com'
}/public/images/`;

const ListingItemOuterContainer = styled.div`
  background: white;
  padding: 1em;
  a {
    color: #333 !important;
    text-decoration: none;
  }
  a:hover {
    color: #222 !important;
    text-decoration: none;
  }
  margin-bottom: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
`;
const ListingItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(30px, 2.5fr) 4fr 5fr;
  grid-gap: 15px;
  @media (max-width: 767px) {
    grid-template-columns: 1.5fr 1fr;
  }
`;
const ListingItemImage = styled.img`
  border-radius: 0.25em;
  width: 97%;
  object-fit: cover;
  height: 130px;
  @media (max-width: 767px) {
    height: 110px;
  }
  @media print {
    height: 90px;
    border-radius: 0;
  }
`;
const ListingItemTitle = styled.h3`
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 0.25em;
`;
const ListingItemId = styled.p`
  color: #777;
  font-size: 1.05em;
`;
const IrregularBadge = styled.div``;
const ItemTags = styled.div``;
const ListingImageContainer = styled.div`
  order: 1;
  @media (max-width: 767px) {
    order: 2;
  }
`;
const ListingTitleContainer = styled.div`
  align-self: center;
  order: 2;
  @media (max-width: 767px) {
    order: 1;
  }
`;
const ListingDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  align-self: center;
  order: 3;
  @media (max-width: 767px) {
    order: 3;
    grid-column: span 2;
  }
`;

export default class Material extends Component {
  render() {
    const {
      props: {
        id,
        images: [{ filepath, filename }],
        width,
        height,
        color,
        type,
        irregular,
        thickness: { thickness },
        status,
        location,
        price,
        hasReservationDetails,
        poNumber,
        comment,
        createdAt
      },
    } = this;
    const { brand } = color;

    function numberWithCommas(number) {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
      <ListingItemOuterContainer className={type.name}>
        <Link to={id.padStart(5, '0')}>
          <ListingItemContainer>
            <ListingImageContainer>
              <ListingItemImage
                src={
                  filename && filename
                    ? `${uri + filepath}/th/${filename}`
                    : testimg
                }
                alt="Inventory Photo"
              />
            </ListingImageContainer>
            <ListingTitleContainer>
              <ListingItemTitle>
                {brand.name}
                {color.name}
              </ListingItemTitle>
              <ListingItemId>
                #
                {id.padStart(5, 0)}
              </ListingItemId>
              <ItemTags>
                <StatusBadge id={status.id} />
              </ItemTags>
            </ListingTitleContainer>
            {hasReservationDetails ? (
            <ListingDetailsContainer className="flex">
              <span className="block">
              <span className="block text-gray-700 text-sm">PO Number</span>
              <span className="block text-gray-900 mb-2">{poNumber}</span>
              <span className="block text-gray-700 text-sm">Reserved On</span>
              <span className="block text-gray-900 mb-2">{new Date(createdAt).toDateString()}</span>
              </span>
           
            </ListingDetailsContainer>) :  
            <ListingDetailsContainer>
              <table className="table-details">
                <tbody>
                  <tr>
                    <td>Width</td>
                    <td>
                      {width}
                      "
                    </td>
                  </tr>
                  <tr>
                    <td>Height</td>
                    <td>
                      {height}
                      "
                    </td>
                  </tr>
                  <tr>
                    <td>Thickness</td>
                    <td>
                      {thickness}
                      "
                    </td>
                  </tr>
                  {irregular ? (
                    <IrregularBadge>Irregular</IrregularBadge>
                  ) : null}
                </tbody>
              </table>
              <table className="table-details">
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td>
                      $
                      {numberWithCommas(price * width * height)}
                    </td>
                  </tr>
                  <tr>
                    <td>Sq.Ft</td>
                    <td>
                      {numberWithCommas(width * height)}
                    </td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{location.name}</td>
                  </tr>
                </tbody>
              </table>
            </ListingDetailsContainer>}
           
          </ListingItemContainer>
        </Link>
      </ListingItemOuterContainer>
    );
  }
}
