import React from 'react';
import { Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { Card } from '../../../../atoms/Card';
import Role from '../../../../components/role';
import { allRoles as allRolesQuery } from '../../../../graphql/queries/allRoles.gql';
import { ModalConsumer } from '../../../../context/modal';
import addRole from './manage/add';

const Roles = ({ toggleModal, data: { allRoles = [] } = {} }) => {
  const createRole = () => {
    toggleModal({ options: { title: 'Create Role' } })(addRole);
  };
  return (
    <Card>
      <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Roles
        </h2>
        <div>
          <button
            type="button"
            onClick={createRole}
            className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm"
          >
            Add New Role
          </button>
        </div>
      </div>
      <div className="flex">
        {allRoles.map((action) => (
          <Role key={action.id} {...action} toggleModal={toggleModal} />
        ))}
      </div>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allRolesQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(Roles)),
    'Manage User Roles',
  ),
);
