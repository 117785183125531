import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ManageUsers from '../../views/settings/general/users';
import Notifications from '../../views/settings/general/notifications';
import MyAccount from '../../views/settings/general/myaccount';
import Roles from '../../views/settings/general/roles';
import NoMatch from '../../views/pagenotfound';
import ProtectedRoute from '../protectedroute';

export default ({ match: { path } }) => (
  <Switch>
    <Route exact path={`${path}/account`} component={MyAccount} />
    <ProtectedRoute
      exact
      path={`${path}/users`}
      requiredAction={5}
      component={ManageUsers}
    />
    <ProtectedRoute
      exact
      path={`${path}/(roles)`}
      requiredAction={5}
      component={Roles}
    />
    <ProtectedRoute
      exact
      path={`${path}/notifications`}
      component={Notifications}
    />
    <Route exact path={path} component={MyAccount} />
    <Route component={NoMatch} />
  </Switch>
);
