/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';

import { Button } from 'reactstrap';
import { ModalConsumer } from '../../context/modal';

import { Update } from '../../views/settings/inventory/thicknesses/manage';
import { allThicknesses } from '../../graphql/queries/allThicknesses.gql';
import { removeThickness as removeThicknessMutation } from '../../graphql/mutations/removeThickness.gql';

const Thickness = ({
  id, thickness, toggleModal, removeThickness,
}) => {
  const onUpdateClick = async () => {
    console.log('typeof ', thickness);
    toggleModal({
      options: { title: 'Update Thickness' },
      props: { id, thickness },
    })(Update);
  };
  const onRemoveClick = async () => {
    removeThickness({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: allThicknesses,
        },
      ],
      update: (
        store,
        {
          data: {
            removeThickness: { ok },
          },
        },
      // eslint-disable-next-line consistent-return
      ) => {
        if (!ok) return false;
        try {
          const data = store.readQuery({ query: allThicknesses });
          const newData = {
            allThicknesses: data.allThicknesses.filter(
              ({ id: typeId }) => typeId !== id,
            ),
          };
          store.writeQuery({ query: allThicknesses, data: newData });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e, 'eee');
        }
      },
    });
  };
  return (
    <tr>
      <td>{id}</td>
      <td>{thickness}</td>
      <td>
        <Button color="primary" onClick={onUpdateClick} size="sm">
          Update
        </Button>
      </td>
      <td>
        <Button color="danger" onClick={onRemoveClick} size="sm">
          Remove
        </Button>
      </td>
    </tr>
  );
};
export default graphql(removeThicknessMutation, { name: 'removeThickness' })(
  ModalConsumer(Thickness),
);
