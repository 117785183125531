/* eslint-disable no-param-reassign */
import queryString from 'query-string';

const MIN_WIDTH = 'minWidth';
const MIN_HEIGHT = 'minHeight';
const THICKNESS_ID = 'thicknessId';
const BRAND_ID = 'brandId';
const COLOR_ID = 'colorId';
const STATUS_ID = 'statusId';
const ALL_THICKNESSES = 'allThicknesses';
const ALL_BRANDS = 'allBrands';
const ALL_COLORS = 'allColors';
const ALL_STATUSES = 'allStatuses';
const TYPE_ID = 'typeId';
const COLOR_SELECT = 'colorSelect';
const DIRECTION = 'direction';
const FIELD = 'field';
const PAGE = 'p';

const dataKey = (key) => {
  switch (key) {
    case THICKNESS_ID:
      return ALL_THICKNESSES;
    case BRAND_ID:
    case COLOR_ID:
      return ALL_BRANDS;
    case STATUS_ID:
      return ALL_STATUSES;
    default:
      return '';
  }
};

// TODO: For all build functions combine loop, and loop once--performance improvement
const buildQueryVariables = ({ url, location }) => {
  const params = queryString.parse(location.search);
  const fullslab = url.includes('fullslab');
  const remnants = url.includes('remnants');

  const filter = {};
  const sort = {};
  let page = 1;

  if (remnants || fullslab) {
    filter.typeId = fullslab ? 2 : 1;
  }

  // eslint-disable-next-line consistent-return
  Object.keys(params).forEach((key) => {
    const value = params[key];
    // continue loop when a parameter is not set
    if ((value === '' && value.length === 0) || key === COLOR_SELECT) {
      return false;
    }
    if (key === MIN_WIDTH || key === MIN_HEIGHT) {
      // eslint-disable-next-line radix
      filter[key] = parseInt(value);
    } else if (
      key === THICKNESS_ID ||
      key === BRAND_ID ||
      key === COLOR_ID ||
      key === STATUS_ID
    ) {
      // get all ids and make sure the id is not blank
      filter[key] = value.split(',').filter((id) => id.length !== 0);
    } else if (key === DIRECTION || key === FIELD) {
      sort[key] = value;
    } else if (key === PAGE) {
      page = parseInt(value, 10);
    }
  });

  return {
    filter,
    sort,
    page,
  };
};

const buildParams = ({ url, values = {}, sort = {}, page = 1 }) => {
  const {
    minWidth = 0,
    minHeight = 0,
    thicknessId = [],
    brandId = [],
    colorId = [],
    statusId = [],
  } = values;

  const remnant = url.includes('remnant');
  const fullslab = url.includes('fullslab');

  const filter = {};
  if (remnant || fullslab) {
    filter.typeId = remnant ? 1 : 2;
  }
  if (minWidth) {
    filter.minWidth = minWidth;
  }
  if (minHeight) {
    filter.minHeight = minHeight;
  }
  if (thicknessId.length > 0) {
    filter.thicknessId = thicknessId.map(({ value }) => value);
  }
  if (brandId.length > 0) {
    filter.brandId = brandId.map(({ value }) => value);
  }
  if (colorId.length > 0) {
    filter.colorId = colorId.map(({ value }) => value);
  }
  if (statusId.length > 0) {
    filter.statusId = statusId.map(({ value }) => value);
  }

  let params = [];

  // eslint-disable-next-line consistent-return
  Object.keys(filter).forEach((key) => {
    const property = filter[key];
    if (key === TYPE_ID) return false;
    if (typeof property !== 'object') {
      params.push(`${key}=${property}`);
    } else {
      params.push(`${key}=${property.join(',')}`);
    }
  });

  Object.keys(sort).forEach((key) => {
    if (key === FIELD || key === DIRECTION) params.push(`${key}=${sort[key]}`);
  });
  if (page > 0) {
    params.push(`p=${page}`);
  }

  params = params.join('&');
  return params;
};
const filterSelectableColors = (selectedBrands) => {
  const arrayOfColorArrays = selectedBrands.map(
    ({ id: brandId, colors = [] }) =>
      colors.map(({ id, name }) => ({ value: id, label: name, brandId })),
  );
  if (arrayOfColorArrays.length) {
    return arrayOfColorArrays.reduce((prevValue, curVal) =>
      prevValue.concat(curVal),
    );
  }
  return arrayOfColorArrays;
};

const sortOptions = [
  {
    id: 1,
    value: 0,
    label: 'Newest first',
    direction: 'd',
    field: 'createdAt',
  },
  {
    id: 2,
    value: 1,
    label: 'Oldest first',
    direction: 'a',
    field: 'createdAt',
  },
  {
    id: 3,
    value: 2,
    label: 'Price Lowest to Highest',
    direction: 'a',
    field: 'totalPrice',
  },
  {
    id: 4,
    value: 3,
    label: 'Price Highest to Lowest',
    direction: 'd',
    field: 'totalPrice',
  },
];

const buildSortOption = ({
  field: selectedField,
  direction: selectedDirection,
}) => {
  const sortOption = sortOptions.filter(
    ({ direction, field }) =>
      direction === selectedDirection && field === selectedField,
  );
  return sortOption.length ? sortOption[0] : sortOptions[0];
};

const buildSelectedOptions = ({ location, data }) => {
  const filters = {
    minWidth: '',
    minHeight: '',
    thicknessId: [],
    brandId: [],
    colorId: [],
    statusId: [],
    colorSelect: [],
  };

  let page = 1;
  const params = queryString.parse(location.search);
  if (params[PAGE] > 0 && params[PAGE] !== undefined) {
    page = parseInt(params[PAGE], 10);
  }

  // grab sort option
  const sortOption = buildSortOption({
    field: params[FIELD],
    direction: params[DIRECTION],
  });

  // get selected options from URL
  // eslint-disable-next-line consistent-return
  Object.keys(params).forEach((key) => {
    const property = params[key];
    if (property === null || property === '' || key === COLOR_ID) return false;

    if (key === MIN_WIDTH || key === MIN_HEIGHT) {
      // eslint-disable-next-line radix
      filters[key] = parseInt(property);
    } else if (
      key === THICKNESS_ID ||
      key === BRAND_ID ||
      key === STATUS_ID ||
      key === COLOR_ID
    ) {
      filters[key] = data[dataKey(key)] || [];
      // selected items(ids)
      const ids = property.split(',');
      if (key !== COLOR_ID) {
        // filter data with selected options
        filters[key] = filters[key].filter(
          ({ id: optionId }) => ids.filter((id) => id === optionId).length > 0,
        );
      }
      if (key === BRAND_ID) {
        filters.colorSelect = [];
        filters[COLOR_ID] = [];
        const selectedColors =
          params[COLOR_ID] !== undefined ? params[COLOR_ID].split(',') : [];
        // with one loop do a lot more
        filters[key] = filters[key].map(
          ({ id: brandId, name: brandName, colors }) => {
            filters.colorSelect.push(
              colors.map(({ id, name }) => ({
                value: id,
                label: name,
                brandId,
              })),
            );

            filters[COLOR_ID].push(
              colors
                .filter(
                  ({ id: optionId }) =>
                    selectedColors.filter((id) => id === optionId).length > 0,
                )
                .map(({ id, name }) => ({ value: id, label: name, brandId })),
            );

            return {
              value: brandId,
              label: brandName,
              colors,
            };
          },
        );

        if (filters.colorSelect.length) {
          filters.colorSelect = filters.colorSelect.reduce((pV, cV) =>
            pV.concat(cV),
          );
        }

        if (filters[COLOR_ID].length) {
          filters[COLOR_ID] = filters[COLOR_ID].reduce((pV, cV) =>
            pV.concat(cV),
          );
        }
      } else {
        filters[key] = filters[key].map(({ id, name, thickness }) => ({
          value: id,
          label: name || thickness,
        }));
      }
    }
  });

  return {
    filters,
    sortOption,
    page,
  };
};

export {
  sortOptions,
  buildQueryVariables,
  buildParams,
  buildSelectedOptions,
  dataKey,
  filterSelectableColors,
  MIN_WIDTH,
  MIN_HEIGHT,
  THICKNESS_ID,
  BRAND_ID,
  COLOR_ID,
  STATUS_ID,
  ALL_THICKNESSES,
  ALL_BRANDS,
  ALL_COLORS,
  ALL_STATUSES,
};
