import React from 'react';

import { graphql } from 'react-apollo';
import { Button } from 'reactstrap';
import { allRoles } from '../../../../../graphql/queries/allRoles.gql';
import { removeRole as removeRoleQuery } from '../../../../../graphql/mutations/removeRole.gql';

const DeleteConfirmation = ({ id, name, removeRole, closeModal }) => {
  const removeHandler = async () => {
    await removeRole({
      variables: { id },
      refetchQueries: [
        {
          query: allRoles,
        },
      ],
      update: async (
        store,
        {
          data: {
            removeRole: { ok, errors },
          },
        },
      ) => {
        if (!ok) {
          const errs = {};
          errors.forEach(({ path, message }) => {
            errs[path] = message;
          });
          return false;
        }
        const data = await store.readQuery({ query: allRoles });
        data.allRoles.filter(({ id: removedId }) => id !== removedId);
        store.writeQuery({ query: allRoles, data });
        closeModal();
        return true;
      },
    });
  };
  return (
    <>
      <p>{`Are you sure you want to delete role: ${name}?`}</p>
      <Button onClick={closeModal}>Cancel</Button>
      <Button onClick={removeHandler} color="danger">
        Delete
      </Button>
    </>
  );
};

export default graphql(removeRoleQuery, { name: 'removeRole' })(
  DeleteConfirmation,
);
