import decode, { InvalidTokenError } from 'jwt-decode';
import TokenExpired from './TokenExpired';

const storeTokens = ({ token, refreshToken }, user, email) => {
  const userStringified = JSON.stringify(user);
  localStorage.setItem('user', userStringified);
  localStorage.setItem('email', email);
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  return true;
};

const getUser = () => {
  try {
    const user = localStorage.getItem('user');
    const email = localStorage.getItem('email');
    return JSON.parse(user);
  } catch (e) {
    return false;
  }
};

const hasTokenExpired = (decodedToken) => {
  const { exp } = decodedToken;
  if (Date.now().valueOf() / 1000 > exp) {
    throw new TokenExpired('Token has expired.');
  }
  return decodedToken;
};

const deAuthenticate = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

const getToken = () => {
  // IOS Cookie Blocker
  try {
    const token = localStorage.getItem('token') || '';
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      const { user } = hasTokenExpired(decode(token));
      return user;
    } catch (e) {
      try {
        hasTokenExpired(decode(refreshToken));
      } catch (eRT) {
        if (e instanceof TokenExpired || e instanceof InvalidTokenError) {
          deAuthenticate();
        }
      }
      return {
        token,
        id: 0,
        active: false,
        locked: true,
      };
    }
  } catch (e) {
    return {
      token: {},
      id: 0,
      active: false,
      locked: true,
    };
  }
};

const isAuthenticated = () => {
  const { id } = getToken();
  return !!id;
};

export default isAuthenticated;

export {
  getUser,
  getToken,
  storeTokens,
  deAuthenticate,
  TokenExpired,
  hasTokenExpired,
};
