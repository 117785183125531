import PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { NProgress as NProgressReactWrapper } from '@tanem/react-nprogress';

import { withRouter } from 'react-router-dom';

const NProgress = withRouter(
  ({ location, isAnimating, isFinished, progress, animationDuration }) => (
    <NProgressReactWrapper isAnimating={isAnimating} key={location.key}>
      {({ isFinished, progress, animationDuration }) => (
        <Container
          isFinished={isFinished}
          animationDuration={animationDuration}
        >
          <Bar progress={progress} animationDuration={animationDuration} />
        </Container>
      )}
    </NProgressReactWrapper>
  ),
);

export { NProgress };

const Bar = ({ progress, animationDuration }) => (
  <div
    className="bg-blue-500"
    style={{
      height: 3,
      left: 0,
      marginLeft: `${(-1 + progress) * 100}%`,
      position: 'fixed',
      top: 0,
      transition: `margin-left ${animationDuration}ms linear`,
      width: '100%',
      zIndex: 1031,
    }}
  >
    <div
      style={{
        boxShadow: '0 0 10px #29d, 0 0 5px #29d',
        display: 'block',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        right: 0,
        transform: 'rotate(3deg) translate(0px, -4px)',
        width: 100,
      }}
    />
  </div>
);

Bar.propTypes = {
  animationDuration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};

const Container = ({ children, isFinished, animationDuration }) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      transition: `opacity ${animationDuration}ms linear`,
    }}
  >
    {children}
  </div>
);

Container.propTypes = {
  animationDuration: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  isFinished: PropTypes.bool.isRequired,
};
