import React from 'react';
import { Switch, Route } from 'react-router-dom';

import General from './general';
import Inventory from './inventory';
import NoMatch from '../../views/pagenotfound';

export default ({ match: { path, url } }) => (
  <Switch>
    <Route path={`${path}/inventory`} component={Inventory} />
    <Route path={`${path}/general`} component={General} />
    <Route exact path={`${path}`} component={General} />
    <Route component={NoMatch} />
  </Switch>
);
