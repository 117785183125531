/* eslint-disable no-return-await */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Table } from 'reactstrap';
import { graphql } from 'react-apollo';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';

import { Card } from '../../../../atoms/Card';
import Status from '../../../../components/status';
import { ModalConsumer } from '../../../../context/modal';

import { allStatuses as allStatusesQuery } from '../../../../graphql/queries/allStatuses.gql';
import { Add } from './manage';

const Statuses = ({ data: { allStatuses = [], refetch }, toggleModal }) => (
  <Card title="Manage Status">
    <Button onClick={async () => await toggleModal({ options: { title: 'Add Status' } })(Add)}>Add</Button>
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {allStatuses.map(status => <Status {...status} refetch={refetch} key={status.id} />)}
      </tbody>
    </Table>
  </Card>
);

export default MainLayout(
  SideBar(
    graphql(allStatusesQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(
      ModalConsumer(Statuses),
    ),
  ), 'Inventory / Manage Status',
);
