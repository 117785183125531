import React from 'react';
import { graphql } from 'react-apollo';

import { Button, Table } from 'reactstrap';
import { Add } from './manage';

import { Card } from '../../../../atoms/Card';
import Thickness from '../../../../components/thickness';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { ModalConsumer } from '../../../../context/modal';

import { allThicknesses as allThicknessesQuery } from '../../../../graphql/queries/allThicknesses.gql';

// eslint-disable-next-line react/prop-types
const Thicknesses = ({ toggleModal, data: { allThicknesses = [] } }) => (
  <Card title="Thicknesses">
    <Button onClick={() => toggleModal({ options: { title: 'Add Type' } })(Add)}>Add</Button>
    <Table hover responsive>
      <thead>
        <tr>
          <th scope="row">Id</th>
          <th>Thickness</th>
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {allThicknesses.map(thickness => <Thickness key={thickness.id} {...thickness} />)}
      </tbody>
    </Table>
  </Card>
);

export default MainLayout(
  SideBar(
    graphql(allThicknessesQuery,
      {
        options: {
          fetchPolicy: 'network-only',
        },
      })(
      ModalConsumer(Thicknesses),
    ),
  ), 'Inventory / Manage Thickness',
);
