import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import StatusBadge from '../StatusBadge';

// HeaderBar
export default (props) => {
  const {
    isSettingsPage,
    removePageLayoutDefault,
    sticky,
    heading,
    status,
    subheading,
    children,
  } = props;
  const OuterContainer = styled.div.attrs({className: 'bg-white shadow-soft top-0 z-10'})`
          margin-top: ${props && removePageLayoutDefault ? '-2em' : '0em'};
          margin-bottom: ${props && removePageLayoutDefault ? '2em' : '0em'};
          position: ${props && sticky ? 'sticky' : 'inherit'};
          
          .align-items-center {
            display: flex;
            align-items: center;
          }
  `;


  const HeaderHeading = styled.h2`
    font-size: 1.35em;
    font-weight: 600;
    margin-bottom: 0;
    flex-basis: 100%;
    line-height: 1.75;
    @media (max-width: 767px) {
      font-size: 1.1em;
      text-align: ${props && isSettingsPage ? 'center' : 'inherit'};
    }
  `;
  const HeaderSubheading = styled.p`
    color: #666;
    margin: 0;
  `;

  const SubHeadGroup = styled.div``;
  const StatusBadgeHeaderBarWrapper = styled.div``;

  return (
    <OuterContainer>
      <div className="container border-t border-gray-100 py-3 px-3 md:px-0 md:py-4">
        {props && isSettingsPage ? (
          <Link
            to="/settings-menu"
            className="d-md-none"
            style={{ position: 'absolute', color: '#333' }}
          >
            <ChevronLeft />
          </Link>
        ) : null}

        {props && heading ? (
          <div className="flex justify-between">
            <div className="align-items-center flex-wrap left-column-details">
              <div><HeaderHeading>{heading}</HeaderHeading>
              <SubHeadGroup>
                {props && status
                  ? (
                    <StatusBadgeHeaderBarWrapper>
                      <StatusBadge id={status} />
                    </StatusBadgeHeaderBarWrapper>
                  ) : null}
                {props && subheading ? (
                  <HeaderSubheading>{subheading}</HeaderSubheading>
                ) : (
                  ''
                )}
              </SubHeadGroup>
              </div>
            </div>
            {children ? <div className="flex items-center">{children}</div> : null}
          </div>
        ) : (
          children
        )}
      </div>
    </OuterContainer>
  );
};
