import React from 'react';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'react-moment';
import UserIcon from '../../../atoms/UserIcon';

const Activity = styled.div`
  margin: 0;
  padding: 0.5em 0;
  border-bottom: 1px solid #f5f5f5;
  :last-child {
    border-bottom: none;
  }
`;




export default ({
  user: { name: employee },
  status: { name: statusName },
  createdAt,
  material,
}) => {
  const referenceItem = material ? (
    <NavLink to={`/${material.id.padStart(5, 0)}`}>item</NavLink>
  ) : (
    'item'
  );
  return (
    <div className="flex items-center  py-2">
     <div className="pr-3">
        <UserIcon name={employee} />
        </div>
      <div>
        <span className="block text-gray-800">
          {employee}
          {' '}marked
          {' '}
          <span className="text-blue-600">{referenceItem}</span>
          {' '}
as
          {' '}
          {statusName}
        </span>
        <span className="block text-gray-600 text-sm">
          <Moment format="YYYY/MM/DD hh:mm a">{createdAt}</Moment>
        </span>
        </div>
    </div>
  );
};
