/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, FormGroup, Label, Input, Button } from 'reactstrap';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import Select from 'react-select';

import { allBrands } from '../../../../../graphql/queries/allBrands.gql';
import { allColors } from '../../../../../graphql/queries/allColors.gql';
import { updateColor as updateColorMutation } from '../../../../../graphql/mutations/updateColor.gql';

const UpdateColor = ({
  // eslint-disable-next-line max-len
  actions: { toggleModal },
  data: { allBrands = [] },
  id,
  name,
  brand: { id: brandId, name: brandName },
  updateColor,
}) => {
  const initialValues = {
    name,
    brandId,
  };

  const onSubmit = async ({ name, brandId }, { setSubmitting, setErrors }) => {
    await updateColor({
      variables: {
        id,
        name,
        brandId,
      },
      refetchQueries: [
        {
          query: allColors,
        },
      ],
      update: (
        store,
        {
          data: {
            updaeteColor: { payload },
          },
        },
      ) => {
        const data = store.readQuery({ query: allColors });
        data.allColors.push({
          ...payload,
        });
        store.writeQuery({ query: allBrands, data });
      },
    })
      .then(
        ({
          data: {
            updateColor: { ok, errors },
          },
        }) => {
          if (!ok) {
            const errs = {};
            errors.map(
              // eslint-disable-next-line no-return-assign
              ({ path, message }) => (errs[path] = message),
            );
            setErrors(errs);
          } else {
            toggleModal();
          }
        },
      )
      .catch((e) => console.log(e));
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    } else if (!/^[A-Z\s]+$/i.test(values.name)) {
      errors.name = 'Invalid name. Letters allowed only.';
    }
    return errors;
  };
  const brandsSelect = allBrands.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Alert color="danger" isOpen={!!errors.name}>
              {errors.name && touched.name && errors.name}
            </Alert>
          </FormGroup>
          <FormGroup>
            <Label for="brand">Brand</Label>
            <Select
              options={brandsSelect}
              id="brand"
              onChange={({ value }) => setFieldValue('brandId', value)}
              onBlur={() => setFieldTouched('brandId', true)}
              name="brandId"
              defaultValue={{ value: brandId, label: brandName }}
              isSearchable
            />
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} color="primary">
            Update
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default compose(
  graphql(updateColorMutation, { name: 'updateColor' }),
  graphql(allBrands),
)(UpdateColor);
