/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { Container } from 'reactstrap';
import { HeaderBar, AddNewFormContainer } from '../../../atoms';
import { PageLayout } from '../../../components/PageLayout';
import { addMaterial as addMaterialMutation } from '../../../graphql/mutations/addMaterial.gql';
import { uploadMaterialImage as uploadMaterialImageMutation } from '../../../graphql/mutations/uploadMaterialImage.gql';
import { allInputSuggestions as allInputSuggestionsQuery } from '../../../graphql/queries/allInputSuggestions.gql';
import MainLayout from '../../../layout/main';
import Form from './Form';

class AddMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      uploading: false,
      uploadProgress: 0,
      computedPricePerSqFtOnColorIDandThickness: 0,
    };
  }

  render() {
    const {
      props: {
        history,
        location: { state: { typeId } = {} },
      },
      state: { uploadProgress },
    } = this;
    const uploadPercentage = uploadProgress * 100;

    // map values to props
    const initialValues = {
      typeId: typeId || '',
      irregular: false,
      brandId: '',
      colorId: '',
      height: '',
      width: '',
      thicknessId: '',
      price: '',
      locationId: '',
      photo: '',
      colorSelect: [],
    };

    const validate = ({
      typeId,
      brandId,
      colorId,
      height,
      width,
      thicknessId,
      price,
      locationId,
      photo,
    }) => {
      const errors = {};
      if (!typeId) {
        errors.typeId = 'Select Material Type';
      }
      if (!brandId) {
        errors.brandId = 'Required';
      }
      if (!colorId) {
        errors.colorId = 'Required';
      }
      if (!height) {
        errors.height = 'Required';
      }
      if (!width) {
        errors.width = 'Required';
      }
      if (!thicknessId) {
        errors.thicknessId = 'Required';
      }
      if (!price) {
        errors.price = 'Required';
      }
      if (!locationId) {
        errors.locationId = 'Required';
      }
      if (!photo) {
        errors.photo = 'A photo of the material is required';
      }
      return errors;
    };

    const {
      props: { addMaterial, uploadMaterialImage },
    } = this;

    const onSubmit = async (
      {
        typeId,
        irregular,
        brandId,
        colorId,
        height,
        width,
        thicknessId,
        price,
        locationId,
        photo,
        comments,
      },
      { setSubmitting },
    ) => {
      try {
        const images = photo.map(({ filepath, filename }) => ({
          filepath,
          filename,
        }));
        const {
          data: {
            createMaterial: {
              ok,
              errors,
              payload: { id },
            },
          },
        } = await addMaterial({
          variables: {
            typeId,
            irregular,
            brandId,
            colorId,
            width,
            height,
            thicknessId,
            price,
            locationId,
            images,
            comments,
          },
        });
        console.log('ok', ok);
        if (ok) {
          this.setState({
            success: true,
          });
          // TODO: redirects to the success screen
          history.push('/success', { id });
        } else {
          // TODO: print errors to the user
          console.log('errors', errors);
        }
      } catch (e) {
        console.log('error', e);
        setSubmitting(false);
      }
    };

    return (
      <PageLayout>
        <HeaderBar
          removePageLayoutDefault
          heading="Add New Inventory"
          subheading="Start by filling out the fields below"
        />
        <Container>
          <AddNewFormContainer>
            <Form
              {...this.props}
              {...this.state}
              validate={validate}
              initialValues={initialValues}
              uploadPercentage={uploadPercentage}
              uploadMaterialImage={uploadMaterialImage}
              setParentState={this.setState.bind(this)}
              parentState={this.state}
              onSubmit={onSubmit}
            />
          </AddNewFormContainer>
        </Container>
      </PageLayout>
    );
  }
}

export default MainLayout(
  compose(
    graphql(addMaterialMutation, {
      name: 'addMaterial',
      options: { notifyOnNetworkStatusChange: true },
    }),
    graphql(uploadMaterialImageMutation, {
      name: 'uploadMaterialImage',
      options: { notifyOnNetworkStatusChange: true },
    }),
    graphql(allInputSuggestionsQuery, {
      options: { notifyOnNetworkStatusChange: true },
    }),
  )(AddMaterial),
);
