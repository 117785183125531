/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-apollo';
import QRCode from 'qrcode.react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import MainLayout from '../../layout/main';
import { Alert } from '../../atoms';
import ZoomImg from '../../components/ZoomImg';

import { RawNotFound as PageNotFound } from '../pagenotfound';

import Reserve from './reserve';
import numberWithCommas from '../../shared';
import HeaderBar from '../../atoms/HeaderBar';
import {
  Activity,
  ConfirmModal,
  Protected,
  ReservationDetails,
  StatusButtons,
} from '../../components';
import { Card } from '../../atoms/Card';
import { ModalConsumer } from '../../context/modal';
import User from '../../context/token';
import uri from '../../uri';
import StatusBadge from '../../atoms/StatusBadge';
import { materialDetails as materialDetailsQuery } from '../../graphql/queries/materialDetails.gql';
import { removeMaterial as removeMaterialMutation } from '../../graphql/mutations/removeMaterial.gql';

const DetailLabel = styled.h3`
  color: #666;
  font-size: 1em;
  margin-bottom: 0;
`;
const Detail = styled.h2`
  color: #333;
  font-size: 1em;
  font-weight: 600;
`;

const MaterialHeader = styled.div`
  margin-bottom: 1em;
`;
const MaterialBrandColor = styled.h2`
  font-weight: 600;
  font-size: 1.5em;
  span {
    display: block;
    font-size: 16px;
    color: #666;
    font-weight: 400;
  }
  @media (max-width: 992px) {
    padding: 0.25em;
    text-align: center;
  }
`;
const EnlargeImgText = styled.p`
  font-size: 0.8em;
  font-style: italic;
  color: #666;
  text-align: center;
  margin-top: 1em;
`;

const MaterialDetailsLayout = styled.div`
  padding: 1em;
`;
const DataDetails = styled.div`
  .table-details {
    line-height: 1.5;
    width: 100% !important;
  }
  tbody tr:nth-child(odd) {
    background: #f7f8f9;
  }
  tr td {
    padding: 5px;
  }
`;

const SimilarMaterialImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 120px;
`;

const MaterialWrapper = styled.div`
  .btn {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    .left-column-details {
      grid-column-start: 1;
      grid-column-end: 3;
      flex-wrap: nowrap !important;
      display: flex !important;
      justify-content: space-between !important;
    }
    .right-column-details {
      grid-column-start: 1;
      grid-column-end: 3;
      .btn {
        width: 100%;
      }
    }
  }
`;

const Material = (props) => {
  const {
    history,
    location,
    data: { refetch, error, loading, getMaterial },
    toggleModal,
    user: { id: userId },
  } = props;
  if (error) return <Alert color="danger">{error.toString()}</Alert>;
  if ((!getMaterial && !loading) || error) return <PageNotFound />;
  const {
    id = '0',
    images: [{ filepath, filename }] = [{ filepath: '', filename: '' }],
    width = 0,
    height = 0,
    price = 0,
    color = { id: 0, name: '', brand: { name: '' } },
    location: materialLocation = { name: '' },
    type = { id: 0, name: '' },
    thickness: { thickness } = {},
    status = { id: 1 },
    user = { name: '' },
    reservations = [],
    createdAt,
    similiarMaterials = [],
  } = getMaterial || {};

  const DeleteButton = () => {
    const actionWrapper = async (action) => {
      action();
      history.push('/deleted', { id });
    };
    const onClickHandler = () =>
      toggleModal({
        options: {
          title: `Delete #${id.padStart(5, 0)}`,
        },
        props: {
          id,
          text: `delete material #${id.padStart(5, 0)}`,
          actionWrapper,
        },
      })(
        graphql(removeMaterialMutation, {
          name: 'action',
        })(ConfirmModal),
      );
    return (
      <button
        type="button"
        onClick={onClickHandler}
        className="block w-full mb-2 focus:outline-none appearance-none hover:bg-gray-200 text-gray-700 text-sm font-medium  py-2 px-4 rounded"
      >
        Delete Item
      </button>
    );
  };

  const ReservationButtons = () => {
    const onClickReserve = async () => {
      toggleModal({
        options: { title: `Reserve #${id.padStart(5, 0)}` },
        props: { materialId: id.padStart(5, 0), user: { id: userId } },
      })(Reserve);
    };
    if (status.id == 1) {
      return (
        <button
          type="button"
          onClick={onClickReserve}
          className="block w-full mb-2 text-base focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm py-2 px-4 rounded"
        >
          Reserve
        </button>
      );
    }
    if (status.id == 2) {
      return (
        <StatusButtons
          history={history}
          materialId={id}
          refetch={refetch}
          reservation={reservations.length && reservations[0]}
        />
      );
    }
    return (
      <span className="italic text-gray-700 text-center block py-4">
        Item has been sold
      </span>
    );
  };

  const { brand } = color;

  String.random = function (length) {
    let radom13chars = function () {
      return Math.random().toString(16).substring(2, 15);
    };
    let loops = Math.ceil(length / 13);
    return new Array(loops)
      .fill(radom13chars)
      .reduce((string, func) => {
        return string + func();
      }, '')
      .substring(0, length);
  };

  return (
    <MaterialWrapper>
      <HeaderBar
        sticky
        heading={`${type.name} / #${id.padStart(5, 0)}`}
        subheading={`Added by ${user.name} on ${new Date(
          createdAt,
        ).toDateString()}`}
      >
        {/* <Link
          className="mr-2 focus:outline-none appearance-none border bg-white hover:bg-gray-100 text-gray-800 font-medium text-sm py-2 px-4 rounded"
          to={`/material-label/${id.padStart(5, 0)}`}
        >
          Print Material Label
        </Link> */}

        <Link
          className="mr-2 focus:outline-none appearance-none border bg-white hover:bg-gray-100 text-gray-800 font-medium text-sm py-2 px-4 rounded"
          to={`/print/${id.padStart(5, 0)}/?${String.random(25)}`}
        >
          Print
        </Link>
      </HeaderBar>

      <div className="container rounded mx-auto flex flex-wrap my-8">
        <div className="w-full md:w-3/4 pl-2 md:pl-0 pr-2">
          <div className="bg-white shadow-soft rounded p-4 p-8">
            <div className="flex justify-between mb-3">
              <div className="d-none d-lg-block text-2xl font-semibold">
                <span className="block text-gray-700 font-normal text-lg">{`${
                  type.name
                } / #${id.padStart(5, 0)}`}</span>
                {brand.name} {color.name}
              </div>
              <div>
                <NavLink
                  type="button"
                  className="border bg-white hover:bg-gray-100 py-1 rounded px-4 text-sm"
                  to={`${location.pathname}/edit`}
                >
                  Edit
                </NavLink>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 mt-6 pr-12 md:mt-0 items-center flex flex-col justify-center">
                <ZoomImg
                  className=" shadow-soft hover:shadow-md cursor-pointer"
                  imageWidth="100%"
                  imageHeight="auto"
                  src={`${uri + filepath}/th/p_${filename}`}
                  largerSrc={`${uri + filepath}/${filename}`}
                />
                <EnlargeImgText>
                  Click image to open expanded view
                </EnlargeImgText>
              </div>

              <div className="w-full md:w-1/2  md:order-1 items-center">
                <div>
                  {/* <StatusBadge id={status.id} /> */}
                  <DataDetails>
                    <table className="table-auto w-full text-left md:order-2  py-2 text-sm md:text-base text-left">
                      <tbody>
                        <tr>
                          <td className="border px-4 py-2">Brand</td>
                          <td className="border px-4 py-2">{brand.name}</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Color</td>
                          <td className="border px-4 py-2">{color.name}</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Width</td>
                          <td className="border px-4 py-2">{width}"</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Height</td>
                          <td className="border px-4 py-2">{height}"</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Thickness</td>
                          <td className="border px-4 py-2">{thickness}"</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Price</td>
                          <td className="border px-4 py-2">
                            $
                            {numberWithCommas(
                              (((price * width) / 12) * height) / 12,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Sq.Ft</td>
                          <td className="border px-4 py-2">
                            {numberWithCommas(((width / 12) * height) / 12)}ft
                            <sup>2</sup>
                          </td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Location</td>
                          <td className="border px-4 py-2">
                            {materialLocation.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </DataDetails>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <Activity activity={reservations} />
            {/* <Card
              title="Similiar Materials"
              marginBottom=".5em"
              innerPadding="1em 0.9375em"
            >
              <Row>
                {similiarMaterials &&
                  similiarMaterials.slice(0, 4).map((
                    {
                      // eslint-disable-next-line no-shadow
                      id,
                      color,
                      images: [{ filepath, filename }] = [
                        { filepath: '', filename: '' },
                      ],
                    }, // eslint-disable-next-line no-shadow // eslint-disable-next-line no-shadow
                  ) => (
                    <Col
                      lg={3}
                      key={id}
                      tag={NavLinkRR}
                      to={`/${id.padStart(5, 0)}`}
                    >
                      <Card>
                        <SimilarMaterialImg
                          width="100%"
                          src={`${uri + filepath}/th/${filename}`}
                          alt={`${color.brand.name} ${color.name}`}
                        />
                        {id.padStart(5, 0)}
                        {color.brand.name} {color.name}
                      </Card>
                    </Col>
                  ))}
                {!similiarMaterials.length && (
                  <p>No Similiar Materials Found</p>
                )}
              </Row>
            </Card> */}
          </div>
        </div>
        <div className="w-full mb-4 md:mb-0 order-first md:order-2  md:w-1/4 pr-2 md:pr-0 pl-2">
          <div className="bg-white shadow-soft rounded p-4 py-3 overflow-hidden">
            <StatusBadge
              className={'block w-full p-2 text-xl text-center my-1'}
              id={status.id}
            />

            <ReservationDetails details={reservations[0]} />

            <span className="block">
              <span class="block uppercase tracking-wider text-gray-800 text-xs font-bold mt-4">
                Material Information
              </span>
              <span className="block text-gray-700 text-sm">Created By</span>
              <span className="block text-gray-900 mb-2">{user.name}</span>
              <span className="block text-gray-700 text-sm">Created On</span>
              <span className="block text-gray-900 mb-2">
                {new Date(createdAt).toDateString()}
              </span>
              <span className="block mt-4">
                <ReservationButtons />
                <Protected requiredAction={1}>
                  <DeleteButton />
                </Protected>
              </span>
            </span>

            <span className="border-t border-color-100 -mx-4 -my-3 block mt-6 p-4 flex items-center">
              <div className="w-1/3">
                <QRCode
                  size={60}
                  renderAs={'svg'}
                  value={`${process.env.REACT_APP_URL}/${id.padStart(5, 0)}`}
                  level="H"
                />
              </div>
              <div className="w-2/3 text-left">
                <span className="block text-gray-700 text-xs">
                  Scan with your camera to quickly access remnant item.
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </MaterialWrapper>
  );
};

export default MainLayout(
  User(
    ModalConsumer(
      graphql(materialDetailsQuery, {
        options: ({
          match: {
            params: { id },
          },
        }) => ({ variables: { id }, notifyOnNetworkStatusChange: true }),
      })(Material),
    ),
  ),
);
