import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { filterSelectableColors, buildParams } from '../consts';
import { filterArrayForSelectedValues } from '../../../sharedFunctions';
import useAdvancedFilterValues from '../../../hooks/useAdvancedFilterValues';
// @todo: seperate formik props
const CustomInput = ({
  selectOptions,
  fieldName,
  fieldValues,
  reactSelectProps,
  setFieldValue,
  setFieldTouched,
  handleBlur,
}) => {
  if (selectOptions.length > 5) {
    return (
      <Select
        name={fieldName}
        options={selectOptions}
        value={selectOptions.filter(
          ({ value }) =>
            fieldValues.filter(({ value: valueId }) => value === valueId)
              .length > 0,
        )}
        delimeter=","
        onChange={(values) => setFieldValue(fieldName, values)}
        onBlur={() => setFieldTouched(fieldName, true)}
        isMulti
        closeMenuOnSelect={false}
        {...reactSelectProps}
      />
    );
  }
  return selectOptions.map((selectOption) => (
    <FormGroup
      className="custom-checkbox"
      check
      key={selectOption.value}
    >
      <Input
        name={fieldName}
        onChange={({ target: { checked } }) =>
          setFieldValue(
            fieldName,
            filterArrayForSelectedValues(fieldValues, selectOption, checked),
          )
        }
        onBlur={handleBlur}
        className="form-checkbox"
        type="checkbox"
        value={selectOption.value}
        id={fieldName + selectOption.value}
        checked={
          fieldValues.filter(({ value }) => value === selectOption.value)
            .length > 0
        }
      />
      <Label
        className="custom-control-label ml-2"
        htmlFor={fieldName + selectOption.value}
      >
        {selectOption.label}
      </Label>
    </FormGroup>
  ));
};

const AdvancedSearchForm = ({ location, match: { url }, data, history }) => {
  const {
    allThicknesses = [],
    allColors = [],
    allBrands = [],
    allStatuses = [],
  } = data;
  const thicknessesSelect = allThicknesses.map(({ id, thickness }) => ({
    value: id,
    label: thickness,
  }));
  const brandSelect = allBrands.map(({ id, name, colors }) => ({
    value: id,
    label: name,
    colors,
  }));
  const statusSelect = allStatuses.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const { filters, sortOption } = useAdvancedFilterValues({ location, data });

  return (
    <Formik
      initialValues={filters}
      validate={async ({ minWidth }) => {
        const errors = {};
        if (minWidth > 100000) {
          errors.minWidth = 'Too big';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const params = buildParams({ url, values, sort: sortOption });
        history.push({ pathname: location.pathname, search: params });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values: {
          minWidth,
          minHeight,
          thicknessId,
          brandId,
          colorId,
          statusId,
          colorSelect,
        },
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-wrap mb-4">
            <div className="w-1/2 pr-3">
            <label htmlFor="minwidth" className="text-sm mb-2 text-gray-800">Min Width</label>
                <input
                  type="number"
                  id="minWidth"
                  name="minWidth"
                  min="0"
                  className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={minWidth}
                />
            </div>
            <div className="w-1/2 pl-3">
            <label htmlFor="minheight" className="text-sm mb-2 text-gray-800">Min Height</label>
                <input
                  type="number"
                  id="minHeight"
                  name="minHeight"
                  min="0"
                  className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={minHeight}
                />
                 </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full">
            <label htmlFor="Thickness" className="text-sm mb-1 text-gray-800 block">Thickness</label>
                <CustomInput
                  selectOptions={thicknessesSelect}
                  fieldName="thicknessId"
                  fieldValues={thicknessId}
                  reactSelectProps={{ isMulti: true, closeMenuOnSelect: true }}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleBlur={handleBlur}
                />
              </div>
             </div>
          
             <div className="flex flex-wrap mb-4">
            <div className="w-full">

            <label htmlFor="brand" className="text-sm mb-1 text-gray-800 block">Brand</label>
                {brandSelect.length > 5 ? (
                  <Select
                    name="brand"
                    options={brandSelect}
                    delimeter=","
                    onChange={(selectedBrands) => {
                      setFieldValue('brandId', selectedBrands);
                      setFieldValue(
                        'colorSelect',
                        allColors
                          .filter(
                            ({ brand: { id: brandIdInColor } }) =>
                              selectedBrands.filter(
                                ({ value: selectedBrand }) =>
                                  selectedBrand === brandIdInColor,
                              ).length > 0,
                          )
                          .map(({ id, name }) => ({ value: id, label: name })),
                      );
                    }}
                    onBlur={() => setFieldTouched('brandId', true)}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                ) : (
                  brandSelect.map((brand) => (
                    <FormGroup
                      className="custom-checkbox "
                      check
                      key={brand.value}
                    >
                      <Input
                        name="brandId"
                        onChange={({ target: { checked } }) => {
                          const selectedBrands = filterArrayForSelectedValues(
                            brandId,
                            brand,
                            checked,
                          );
                          const selectableColors = filterSelectableColors(
                            selectedBrands,
                          );
                          const selectedColorsFiltered = colorId.filter(
                            ({ brandId: brandIdInColor }) => {
                              if (!checked && brandIdInColor === brand.value) {
                                return false;
                              }
                              return true;
                            },
                          );
                          setFieldValue('brandId', selectedBrands);
                          setFieldValue('colorSelect', selectableColors);
                          setFieldValue('colorId', selectedColorsFiltered);
                        }}
                        onBlur={handleBlur}
                        checked={
                          brandId.filter(({ value }) => value === brand.value)
                            .length !== 0
                        }
                        className="custom-control-input form-checkbox"
                        type="checkbox"
                        value={brand.value}
                        id={`brand${brand.value}`}
                      />
                       <label htmlFor={`brand${brand.value}`} className="ml-2 mb-1 text-gray-900"> {brand.label}</label>
                      
                    </FormGroup>
                  ))
                )}

</div></div>
<div className="flex flex-wrap mb-4">
            <div className="w-full">

                <div>
                <label htmlFor="color" className="text-sm mb-1 text-gray-800 block">Color</label>
                <CustomInput
                  selectOptions={colorSelect}
                  fieldName="colorId"
                  fieldValues={colorId}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleBlur={handleBlur}
                />
                {!colorSelect.length > 0 && !brandId.length && (
                  <p>Select a Brand First</p>
                )}
                {!colorSelect.length > 0 && brandId.length > 0 && (
                  <p>No Colors exist for this brand</p>
                )}
              </div>  </div>  </div>


         <div className="flex flex-wrap mb-4">
            <div className="w-full">
            <label htmlFor="status" className="text-sm mb-1 text-gray-800 block">Status</label>
                <CustomInput
                  selectOptions={statusSelect}
                  fieldName="statusId"
                  fieldValues={statusId}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleBlur={handleBlur}
                />

            </div>  </div>
         

<button type="submit" disabled={isSubmitting || data.loading} className="block  w-full  hover:bg-gray-200 text-gray-900 bg-gray-100 text-base text-decoration-none focus:outline-none appearance-none font-medium text-sm py-2 px-4 rounded">
Submit
            </button>

      
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(AdvancedSearchForm);
