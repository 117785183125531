import React, { Component } from 'react';
import { NavLink as NavLinkRR } from 'react-router-dom';
import styled from 'styled-components';
import {
  Home, Trello, Layers, Menu,
} from 'react-feather';
import { NavLink } from 'reactstrap';
import Protected from '../protected';

class TabBar extends Component {
  componentDidMount() {
    document.body.classList.add('tabbar-active');
  }

  render() {
    const TabBarOuter = styled.div`
      position: fixed;
      display: flex;
      justify-content: space-around;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 1;
      min-height: 3.75rem;
      padding-bottom: env(safe-area-inset-bottom) !important;
      padding-bottom: constant(safe-area-inset-bottom) !important;
      box-shadow: 0px -2px 7px 1px rgba(0, 0, 0, 0.06);
      background: #fff;

      .nav-link {
        color: #666;
        text-align: center;
        font-size: 11px;
        &.active {
          color: #0874d9;
        }
      }
      svg {
        width: 22px;
        display: block;
        margin: 0 auto;
      }
      @media (min-width: 767px) {
        display: none;
      }
    `;
    // const NavItem = styled.div``;

    return (
      <TabBarOuter>
        <Protected>
          <NavLink to="/dashboard" tag={NavLinkRR} className={"flex justify-center flex-col"}>
            <Home />
            {' '}
Dashboard
          </NavLink>
        </Protected>
        <NavLink to="/remnants" tag={NavLinkRR} className={"flex justify-center flex-col"}>
          <Trello />
          {' '}
Remnants
        </NavLink>

        <NavLink to="/fullslabs" tag={NavLinkRR} className={"flex justify-center flex-col"}>
          <Layers />
          {' '}
Full Slabs
        </NavLink>
        <Protected>
          <NavLink to="/settings-menu" tag={NavLinkRR} className={"flex justify-center flex-col"}>
            <Menu />
            {' '}
More
          </NavLink>
        </Protected>
      </TabBarOuter>
    );
  }
}
export default TabBar;
