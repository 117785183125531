import React, { useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import MainLayout from '../../layout/main';
import AdvancedSearchForm from './advancedform';
import { Check, AlertCircle, ChevronDown } from 'react-feather';
import Materials from './materials';
import { Card } from '../../atoms/Card';
import HeaderBar from '../../atoms/HeaderBar';
import { PageLayout } from '../../components/PageLayout';
import SelectedFilters from './selectedfilters';
import { home as homeQuery } from '../../graphql/queries/home.gql';
import {
  buildQueryVariables,
  buildSelectedOptions,
  buildParams,
} from './consts';

const Home = (props) => {
  const [isAdvancedSearchExpanded, setIsAdvancedSearchExpanded] = useState(
    false,
  );

  const { location, data, match, history } = props;
  const { allMaterials = { node: [], totalCount: 0 } } = data;
  const { filters, sortOption, page } = buildSelectedOptions({
    location,
    data,
  });

  const pageFullSlabs = match.url.includes('fullslab');
  const pageRemnants = match.url.includes('remnants');

  const handlePageChange = ({ selected }) => {
    const params = buildParams({
      url: match.url,
      values: filters,
      sort: sortOption,
      page: selected + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params,
    });
  };

  const pageTitle =
    pageFullSlabs || pageRemnants
      ? pageFullSlabs
        ? 'Full Slabs'
        : 'Remnants'
      : '';

  return (
    <>
      <HeaderBar
        heading={`${pageTitle} Inventory`}
        sticky
        subheading={`${allMaterials.totalCount} total items`}
      >
        <div className="justify-end self-center flex">
          <Link
            to={{
              pathname: '/add',
              state: {
                typeId:
                  pageFullSlabs || pageRemnants ? (pageFullSlabs ? 2 : 1) : 0,
              },
            }}
            className="text-base text-decoration-none focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm py-2 px-4 rounded"
          >
            Add new
          </Link>
        </div>
      </HeaderBar>

      <PageLayout>
        <div className="container mx-auto flex flex-wrap">
          <div className="w-full md:w-1/4 md:pr-2">
            <div
              className="bg-white shadow-soft rounded p-4 mb-4"
              onClick={() =>
                setIsAdvancedSearchExpanded(!isAdvancedSearchExpanded)
              }
            >
              <div className="items-center justify-between flex">
                <span className="block font-semibold">Advanced Search</span>
                <span className="md:hidden">
                  {' '}
                  <ChevronDown
                    className={
                      isAdvancedSearchExpanded
                        ? 'rotate-animate-svg chevron-open'
                        : 'rotate-animate-svg chevron-closed'
                    }
                  />
                </span>
              </div>
              <div
                className={`${
                  !isAdvancedSearchExpanded ? 'hidden md:block mt-4 ' : 'mt-4'
                }`}
              >
                <AdvancedSearchForm
                  {...props}
                  initialValues={filters}
                  sortOption={sortOption}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/4 pr-2 md:pr-0 pl-2">
            <SelectedFilters
              {...props}
              filters={filters}
              sortOption={sortOption}
            />
            {allMaterials.nodes && allMaterials.nodes.length > 0 ? (
              <Materials {...props} />
            ) : (
              <PageLayout>
                {' '}
                <div className="container mx-auto p-4 ">
                  <div className="p-4 border rounded text-center text-gray-800">
                    <p>Inventory will appear here.</p>
                  </div>
                </div>
              </PageLayout>
            )}

            <ReactPaginate
              forcePage={page - 1}
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={allMaterials.totalCount / 5}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default MainLayout(
  graphql(homeQuery, {
    options: (props) => {
      const {
        match: { url },
        location,
      } = props;
      const variables = buildQueryVariables({ url, location });
      variables.itemsPerPage = 5;
      return {
        variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      };
    },
  })(Home),
);
