import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Container, Row, Col } from 'reactstrap';
import MainLayout from '../layout/main';

const Heading = styled.h1`
  font-size: 8em;
  font-weight: 800;
`;
const SubHeading = styled.p`
  font-size: 2em;
  color: #777;
`;

const NotFound = () => (
  <Container>
    <Row>
      <Col>
        <Heading>Not Found</Heading>
        <SubHeading>
          Sorry, the page you are looking for could not be found.
        </SubHeading>
        <Link to="/">Go back to home</Link>
      </Col>
    </Row>
  </Container>
);

export const RawNotFound = NotFound;
export default MainLayout(NotFound);
