import { Formik } from 'formik';
import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { Link, NavLink as NavLinkRR } from 'react-router-dom';
import { AlertTriangle, PhoneOutgoing } from 'react-feather';
import Select from 'react-select';
import RadioGroup from '../../../atoms/Radio';
import { Form, FormGroup, Label, Progress } from 'reactstrap';
import { HeadingTitle } from '../../../atoms';
import uri from '../../../uri';
import ZoomImg from '../../../components/ZoomImg';

const ErrorText = styled.p``;

function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const MaterialForm = ({
  validate,
  onSubmit,
  initialValues,
  uploadPercentage,
  uploadMaterialImage,
  setParentState,
  parentState,
  uploading,
  data: {
    allTypes = [],
    allBrands = [],
    allThicknesses = [],
    allLocations = [],
  },
  submitButtonText,
}) => {
  const brandsSelect = allBrands;

  const materialTypes = [];

  allTypes.forEach(function (element) {
    materialTypes.push({ label: element.name, value: element.id });
  });

  const thicknessSelect = allThicknesses;
  const locationSelect = allLocations;

  return (
    <Formik
      validate={validate}
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        values: {
          typeId,
          irregular,
          brandId,
          manufacturerSerialNumber,
          colorId,
          width,
          height,
          price,
          image,
          colorSelect,
          thicknessId,
          locationId,
          photo,
          comments,
        },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mb-6">
            <fieldset>
              <HeadingTitle>Inventory Type</HeadingTitle>
              {/* <RadioGroup
                name="typeId"
                selectedItem={typeId}
                options={materialTypes}
                onChange={handleChange}
              /> */}

              <Select
                classNamePrefix="styledInputDreamTrackr"
                options={materialTypes}
                id="typeId"
                onChange={({ value }) => setFieldValue('typeId', value)}
                onBlur={() => setFieldTouched('colorId', true)}
                name="typeId"
                value={materialTypes.filter(({ value }) => value == typeId)}
                isSearchable
              />

              {errors.typeId && (
                <ErrorText className="errorMessageInline">
                  <AlertTriangle size={18} />
                  {errors.typeId}
                </ErrorText>
              )}

              {typeId == 1 ? (
                <div className="custom-checkbox my-2" check>
                  <input
                    value={irregular}
                    name="irregular"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={!!irregular}
                    className="form-checkbox"
                    type="checkbox"
                    id="defaultCheck1"
                  />
                  <label
                    htmlFor="defaultCheck1"
                    className="custom-control-label text-gray-800 ml-2"
                  >
                    Irregular Shape
                  </label>
                </div>
              ) : null}
            </fieldset>
          </div>

          <div className="mb-6 w-full md:w-1/2">
            <label
              htmlFor="manufacturerSerialNumber"
              className="cursor-default mb-2 text-gray-800 block"
            >
              Manufacturer Serial Number
            </label>
            <input
              type="text"
              id="manufacturerSerialNumber"
              name="manufacturerSerialNumber"
              className="mt-2 block w-full border border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
              onChange={handleChange}
              onBlur={handleBlur}
              value={manufacturerSerialNumber}
            ></input>
          </div>
          <div className="mb-6">
            <HeadingTitle>Material Brand and Color</HeadingTitle>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 md:pr-2">
                <div
                  className={
                    touched.brandId && errors.brandId ? 'hasError' : ''
                  }
                >
                  <label
                    htmlFor="brandId"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Brand
                  </label>
                  <Select
                    classNamePrefix="styledInputDreamTrackr"
                    options={brandsSelect}
                    id="brand"
                    onChange={({ value }) => {
                      setFieldValue('brandId', value);
                      // reset colorId
                      setFieldValue('colorId', []);
                      setFieldValue(
                        'colorSelect',
                        (
                          allBrands
                            .filter(({ value: id }) => id === value)
                            .map(({ colors }) => colors) || []
                        ).reduce((pV, cV) => pV.concat(cV)),
                      );
                    }}
                    onBlur={() => setFieldTouched('brandId', true)}
                    name="brandId"
                    value={allBrands.filter(({ value }) => value == brandId)}
                    isSearchable
                  />
                  {touched.brandId && errors.brandId && (
                    <ErrorText className="errorMessageInline">
                      <AlertTriangle size={18} />
                      {errors.brandId}
                    </ErrorText>
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <div
                  className={
                    touched.colorId && errors.colorId ? 'hasError' : ''
                  }
                >
                  <label
                    htmlFor="color"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Color
                  </label>
                  <Select
                    classNamePrefix="styledInputDreamTrackr"
                    options={colorSelect}
                    noOptionsMessage={() => 'Select a brand first.'}
                    id="color"
                    defaultValue={colorId}
                    onChange={({ value }) => setFieldValue('colorId', value)}
                    onBlur={() => setFieldTouched('colorId', true)}
                    name="colorId"
                    value={colorSelect.filter(({ value }) => value == colorId)}
                    isSearchable
                  />
                  {touched.colorId && errors.colorId && (
                    <ErrorText className="errorMessageInline">
                      <AlertTriangle size={18} />
                      {errors.colorId}
                    </ErrorText>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <HeadingTitle>Material Specifications</HeadingTitle>
            <div className="flex flex-wrap">
              <div className="w-1/2 md:w-1/4 pr-4">
                <div
                  className={touched.height && errors.height ? 'hasError' : ''}
                >
                  <label
                    htmlFor="height"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Height
                  </label>

                  <div className="hasAddon_after">
                    <span className="addOn">"</span>
                    <input
                      type="number"
                      id="height"
                      className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      step="0.01"
                      pattern="/^\?[0-9]+\.?[0-9]*$/"
                      value={height}
                      min="1"
                      name="height"
                    />
                    {touched.height && errors.height && (
                      <ErrorText className="errorMessageInline">
                        <AlertTriangle size={18} />
                        {errors.height}
                      </ErrorText>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 md:pr-6">
                <div
                  className={touched.width && errors.width ? 'hasError' : ''}
                >
                  <label
                    htmlFor="width"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Width
                  </label>

                  <div className="hasAddon_after">
                    <span className="addOn">"</span>
                    <input
                      type="number"
                      id="width"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                      step="0.01"
                      pattern="/^\?[0-9]+\.?[0-9]*$/"
                      value={width}
                      min="1"
                      name="width"
                    />
                  </div>
                  {touched.width && errors.width && (
                    <ErrorText className="errorMessageInline">
                      <AlertTriangle size={18} />
                      {errors.width}
                    </ErrorText>
                  )}
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 pr-4">
                {' '}
                <div
                  className={
                    touched.thicknessId && errors.thicknessId ? 'hasError' : ''
                  }
                >
                  <label
                    htmlFor="thickness"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Thickness
                  </label>

                  <Select
                    classNamePrefix="styledInputDreamTrackr"
                    options={colorId ? thicknessSelect : []}
                    id="thickness"
                    noOptionsMessage={() => 'Select a color first.'}
                    onChange={({ value }) => {
                      const sqFtPriceFromColor = colorSelect.filter(
                        ({ value }) => value === colorId,
                      );

                      let sqFtPriceFromColorandThickness = 0;

                      if (
                        value == 1 &&
                        sqFtPriceFromColor[0] &&
                        sqFtPriceFromColor[0].onecmprice
                      ) {
                        sqFtPriceFromColorandThickness =
                          sqFtPriceFromColor[0].onecmprice;
                      }
                      if (
                        value == 2 &&
                        sqFtPriceFromColor[0] &&
                        sqFtPriceFromColor[0].twocmprice
                      ) {
                        sqFtPriceFromColorandThickness =
                          sqFtPriceFromColor[0].twocmprice;
                      }
                      if (
                        value == 3 &&
                        sqFtPriceFromColor[0] &&
                        sqFtPriceFromColor[0].threecmprice
                      ) {
                        sqFtPriceFromColorandThickness =
                          sqFtPriceFromColor[0].threecmprice;
                      }
                      setFieldValue('price', sqFtPriceFromColorandThickness);
                      setParentState({
                        computedPricePerSqFtOnColorIDandThickness: sqFtPriceFromColorandThickness,
                      });
                      setFieldValue('thicknessId', value);
                    }}
                    onBlur={() => setFieldTouched('thicknessId', true)}
                    value={allThicknesses.filter(
                      ({ value }) => value === thicknessId,
                    )}
                    name="thicknessId"
                  />
                  {touched.thicknessId && errors.thicknessId && (
                    <ErrorText className="errorMessageInline">
                      <AlertTriangle size={18} />
                      {errors.thicknessId}
                    </ErrorText>
                  )}
                </div>
              </div>
              <div className="w-1/2 md:w-1/4">
                <div
                  className={touched.price && errors.price ? 'hasError' : ''}
                >
                  <label
                    htmlFor="price"
                    className="cursor-default mb-2 text-gray-800 block"
                  >
                    Price per Sq.ft
                  </label>

                  <div className="hasAddon_before">
                    <span className="addOn">$</span>
                    <input
                      type="number"
                      id="price"
                      className="mt-2 block w-full border-2 border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        parentState.computedPricePerSqFtOnColorIDandThickness
                      }
                      disabled
                      name="price"
                      min="0"
                      step="0.01"
                      pattern="/^\?[0-9]+\.?[0-9]*$/"
                      inputMode="numeric"
                    />
                  </div>

                  {touched.price && errors.price && (
                    <ErrorText className="errorMessageInline">
                      <AlertTriangle size={18} />
                      {errors.price}
                    </ErrorText>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                touched.locationId && errors.locationId
                  ? 'hasError w-full md:w-1/2'
                  : 'w-full md:w-1/2'
              }
            >
              <label
                htmlFor="location"
                className="cursor-default mb-2 text-gray-800 block"
              >
                Location
              </label>
              <Select
                classNamePrefix="styledInputDreamTrackr"
                options={locationSelect}
                id="location"
                onChange={({ value }) => setFieldValue('locationId', value)}
                onBlur={() => setFieldTouched('locationId', true)}
                name="locationId"
                value={allLocations.filter(({ value }) => value === locationId)}
                isSearchable
              />
              {touched.locationId && errors.locationId && (
                <ErrorText className="errorMessageInline">
                  <AlertTriangle size={18} />
                  {errors.locationId}
                </ErrorText>
              )}
            </div>
          </div>

          <FormGroup
            className={touched.photo && errors.photo ? 'hasError' : ''}
          >
            <Label
              className="cursor-default mb-2 text-gray-800 block"
              for="photo"
            >
              Photo
            </Label>
            {photo.length > 0 &&
              photo &&
              photo &&
              photo.map(({ filepath, filename }) => (
                <ZoomImg
                  className=" shadow-soft hover:shadow-md cursor-pointer"
                  imageWidth="100%"
                  imageHeight="auto"
                  src={`${uri + filepath}/th/p_${filename}`}
                  largerSrc={`${uri + filepath}/${filename}`}
                />
              ))}
            {uploading && (
              <>
                <div className="text-center">{uploadPercentage}</div>
                <Progress value={uploadPercentage} />
              </>
            )}
            <input
              type="file"
              id="photo"
              className="h-0 w-0 absolute m-0 p-0 opacity-0 pointer-events-none"
              onChange={({ target: { files: file } }) => {
                try {
                  uploadMaterialImage({
                    variables: {
                      file: file,
                    },
                    context: {
                      fetchOptions: {
                        onUploadProgress: ({ loaded, total }) => {
                          setParentState({
                            uploading: true,
                            uploadProgress: loaded / total,
                          });
                        },
                      },
                    },
                  }).then(({ data }) => {
                    setParentState({
                      uploading: false,
                      uploadProgress: 0,
                    });
                    setFieldValue('photo', data.uploadMaterialImage.images);
                    return data;
                  });
                } catch (e) {
                  setParentState({
                    uploading: false,
                    uploadProgress: 0,
                  });
                }
              }}
              onBlur={handleBlur}
              value={image}
              name="photo"
            />
            <label
              htmlFor="photo"
              className="inline-block text-base text-decoration-none focus:outline-none appearance-none bg-gray-200 hover:bg-gray-300 text-gray-800 text-sm px-4 py-2 cursor-pointer rounded"
            >
              Choose file to upload
            </label>

            {touched.photo && errors.photo && (
              <ErrorText className="errorMessageInline">
                <AlertTriangle size={18} />
                {errors.photo}
              </ErrorText>
            )}
          </FormGroup>
          <div className="mb-6">
            <label
              htmlFor="comments"
              className="cursor-default mb-2 text-gray-800 block"
            >
              Comments
            </label>
            <textarea
              rows="4"
              cols="50"
              id="comments"
              name="comments"
              className="mt-2 block w-full border border-gray-300 rounded-lg bg-white px-3 py-2 leading-tight focus:outline-none focus:border-blue-400"
              onChange={handleChange}
              onBlur={handleBlur}
              value={comments}
            ></textarea>
          </div>

          <button
            type="submit"
            className="mt-4 block w-full text-base text-decoration-none focus:outline-none appearance-none bg-blue-500 hover:bg-blue-700 text-white font-medium text-sm px-4 py-3 rounded-lg block"
            disabled={isSubmitting}
          >
            {submitButtonText ? submitButtonText : 'Add Material to Inventory'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default MaterialForm;
