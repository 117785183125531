import React from 'react';


import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import Location from '../../../../components/location';
import { Card } from '../../../../atoms/Card';

import { Add } from './manage';
import SideBar from '../../../../layout/settings/sidebar';
import MainLayout from '../../../../layout/main';
import { ModalConsumer } from '../../../../context/modal';
import { allLocations as allLocationsQuery } from '../../../../graphql/queries/allLocations.gql';

const Locations = ({
  toggleModal,
  data: { allLocations = [] },
}) => {
  const handleClick = async () => {
    toggleModal({ options: { title: 'Add Location' }, props: this })(Add);
  };
  return (
    <Card>
        <div className="flex justify-between items-center -mt-2">
        <h2 className="font-semibold text-xl md:text-2xl mt-font pb-2">
          Locations
        </h2>
        <div>
        <button type="button" onClick={handleClick} className="rounded px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white focus:outline-none text-sm">Add Location</button>
        </div>
      </div>


      <table className="table-auto w-full text-left  py-2 text-sm md:text-base text-left">
          <thead>
            <tr>
              <th className="py-2 w-4">#</th>
              <th className="py-2">Name</th>
              <th className="py-2"></th>
  
            </tr>
          </thead>
          <tbody>


      
          {allLocations.map(location => (
            <Location key={location.id} {...location} />
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default MainLayout(
  SideBar(
    graphql(allLocationsQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    })(ModalConsumer(withRouter(Locations))),
    'Inventory Locations',
  ),
);
