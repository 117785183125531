import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { ActionTypes, useApolloNetworkStatus } from 'react-apollo-network-status';
import { deAuthenticate as deAuthenticateUser } from '../../auth';
import { withRouter } from 'react-router-dom';

const AuthenticationHandler = ({ history, location }) => {
  const status = useApolloNetworkStatus()

  const { queryError: { graphQLErrors: gqlE = [], networkError: qNE } = {}, mutationError: { graphQLErrors: mgqlE = [], networkError: mNE } = {} } = status;
  const graphqlErrors = [...gqlE, ...mgqlE];
  useEffect(() => {
    for (let i = 0; i < graphqlErrors.length; i++) {
      const { code } = graphqlErrors[i];
      if (code === 'UNAUTHENTICATED') {
        deAuthenticateUser();
        return <Redirect push to={{
          pathname: '/login',
          search: `?redirect=${encodeURI(
            `${location.pathname + location.search}`,
          )}`,
          state: {
            redirect: encodeURI(
              `${location.pathname + location.search}`,
            ),
          },
        }} />
      }
      if (code === 'FORBIDDEN') {
        return <Redirect push to={{
          pathname: '/forbidden',
          search: `?from=${encodeURI(
            `${location.pathname + location.search}`,
          )}`,
          state: {
            redirect: encodeURI(
              `${location.pathname + location.search}`,
            ),
          },
        }} />
      }
    }
  },[location.key]);
  
  return null;
}

export default withRouter(AuthenticationHandler);
