
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"color"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Color"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"onecmprice"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"twocmprice"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"threecmprice"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"colorInput"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Color"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"value"},"name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"label"},"name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"onecmprice"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"twocmprice"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"threecmprice"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":182}};
    doc.loc.source = {"body":"fragment color on Color {\n  id\n  name\n  onecmprice\n  twocmprice\n  threecmprice\n}\n\nfragment colorInput on Color {\n  value: id\n  label: name\n  onecmprice\n  twocmprice\n  threecmprice\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
