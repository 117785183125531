/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'reactstrap';
import { graphql } from 'react-apollo';
import Confirm from '../confirm';

import { Update } from '../../views/settings/inventory/locations/manage';
import { ModalConsumer } from '../../context/modal';

import { allLocations } from '../../graphql/queries/allLocations.gql';
import { removeLocation as removeLocationMutation } from '../../graphql/mutations/removeLocation.gql';

const Location = ({ id, name, toggleModal, removeLocation }) => {
  const onUpdateClick = async () => {
    toggleModal({ options: { title: 'Update Location' }, props: { id, name } })(
      Update,
    );
  };
  const onRemoveClick = async () => {
    const remove = async () => {
      toggleModal();
      removeLocation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: allLocations,
          },
        ],
        update: (
          store,
          {
            data: {
              removeLocation: { ok },
            },
          },
        ) => {
          if (!ok) return false;
          try {
            const data = store.readQuery({ query: allLocations });
            const newData = {
              allLocations: data.allLocations.filter(
                ({ id: typeId }) => typeId !== id,
              ),
            };
            store.writeQuery({ query: allLocations, data: newData });
          } catch (e) {
            console.log(e, 'eee');
          }
        },
      });
    };
    toggleModal({
      options: { title: 'Confirm' },
      actions: { action: remove },
      props: { text: `remove location: ${name}` },
    })(Confirm);
  };
  return (
    <tr key={id}>
      <td className="border px-4 py-2">{id}</td>
      <td className="border px-4 py-2">{name}</td>
      <td className="border px-4 py-2">
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-gray-100 focus:outline-none border mr-2"
          onClick={onUpdateClick}
          size="sm"
        >
          Update
        </button>
        <button
          type="button"
          className="rounded py-1 px-2 text-sm bg-white hover:bg-red-100 hover:text-red-600 border hover:border-red-200 focus:outline-none "
          onClick={onRemoveClick}
          size="sm"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default graphql(removeLocationMutation, { name: 'removeLocation' })(
  ModalConsumer(Location),
);
