import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { X } from 'react-feather';

const GenericModal = (props) => {
  const {
    isOpen,
    header,
    body,
    footer,
    options: { title } = {},
    toggleModal,
  } = props;

  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-8"
      fill="#555"
    >
      <path
        class="secondary"
        fill-rule="evenodd"
        d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
      ></path>
    </svg>
  );
  const closeBtn = (
    <button
      type="button"
      className="focus:appearance-none appearance-none"
      onClick={toggleModal}
    >
      {closeIcon}
    </button>
  );

  return isOpen ? (
    <Modal
      isOpen={isOpen} 
      toggle={toggleModal}
      modalTransition={{ timeout: 0 }}
      backdropTransition={{ timeout: 0 }}
    >
      <div className="bg-white p-4 px-6 flex items-center justify-between">
        <span className="block font-semibold text-xl">{title || header}</span>
        <span className="block w-8 h-8 p-2 rounded-full hover:bg-gray-200 bg-gray-100 flex items-center justify-center">
          {closeBtn}
        </span>
      </div>
      <div className="p-6">{body}</div>

      {footer ? (
        <div className="bg-white p-4 px-6 flex items-center justify-between">
          {footer}
        </div>
      ) : null}
    </Modal>
  ) : null;
};

export default GenericModal;
